import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';



const HubSpotPage = () => {


    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>HubSpot Data</Card.Title>
                            {/* Render HubSpot data here */}
                            <Card.Text>
                                {/* Example: */}
                                Name: 
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default HubSpotPage;
