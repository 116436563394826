import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { selectTokens, selectUserInfo } from '../redux/userSlice';
import { FaEdit, FaTrash, FaPlus, FaSave, FaTimes } from 'react-icons/fa';

const Templates = ({ onSelectTemplate }) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');

    // Modal states
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [modalType, setModalType] = useState('create'); // 'create' or 'edit'
    const [currentTemplate, setCurrentTemplate] = useState({
        template_id: null,
        template_name: '',
        subject: '',
        body_html: '',
        body_text: '',
    });

    const tokens = useSelector(selectTokens);
    const userInfo = useSelector(selectUserInfo); // Get user info from Redux
    const apiKey = process.env.REACT_APP_SERVER_API_KEY;
    const baseUrl = process.env.REACT_APP_SERVER_URL;

    const fusionAuthUserId = userInfo?.user?.fusionauth_user_id; // Fetch FusionAuth user ID from Redux

    useEffect(() => {
        if (fusionAuthUserId) {
            console.log('FusionAuth User ID:', fusionAuthUserId); // Debugging
            fetchTemplates();
        }
    }, [fusionAuthUserId]);

    /**
     * Fetches all email templates for the authenticated user.
     */
    const fetchTemplates = async () => {
        if (!fusionAuthUserId) {
            console.error('FusionAuth User ID is not available.');
            setVariant('danger');
            setMessage('User authentication information is missing.');
            return;
        }

        setLoading(true);
        setMessage(null);
        try {
            const response = await axios.get(`${baseUrl}/app/templates`, {
                headers: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                    'x-api-key': apiKey,
                },
                params: { fusionauth_user_id: fusionAuthUserId }, // Pass FusionAuth user ID as a query parameter
            });

            if (response.data.success) {
                setTemplates(response.data.data);
            } else {
                setVariant('danger');
                setMessage('Failed to fetch email templates.');
            }
        } catch (error) {
            console.error('Error fetching templates:', error);
            setVariant('danger');
            setMessage('An error occurred while fetching templates.');
        }
        setLoading(false);
    };

    /**
     * Handles opening the modal for creating a new template.
     */
    const handleCreate = () => {
        setModalType('create');
        setCurrentTemplate({
            template_id: null,
            template_name: '',
            subject: '',
            body_html: '',
            body_text: '',
        });
        setShowTemplateModal(true);
    };

    /**
     * Handles opening the modal for editing an existing template.
     * @param {Object} template - The template to edit.
     */
    const handleEdit = (template) => {
        setModalType('edit');
        setCurrentTemplate(template);
        setShowTemplateModal(true);
    };

    /**
     * Handles deleting a template.
     * @param {number} templateId - The ID of the template to delete.
     */
    const handleDelete = async (templateId) => {
        if (!fusionAuthUserId) {
            setVariant('danger');
            setMessage('User authentication information is missing.');
            return;
        }

        if (!window.confirm('Are you sure you want to delete this template?')) return;

        setLoading(true);
        setMessage(null);
        try {
            const response = await axios.delete(`${baseUrl}/app/templates/${templateId}`, {
                headers: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                    'x-api-key': apiKey,
                },
                params: { fusionauth_user_id: fusionAuthUserId }, // Include FusionAuth user ID as a query parameter
            });

            if (response.data.success) {
                setVariant('success');
                setMessage('Template deleted successfully.');
                fetchTemplates();
            } else {
                setVariant('danger');
                setMessage('Failed to delete the template.');
            }
        } catch (error) {
            console.error('Error deleting template:', error);
            setVariant('danger');
            setMessage('An error occurred while deleting the template.');
        }
        setLoading(false);
    };

    /**
     * Handles saving a new or edited template.
     * @param {Event} e - The form submission event.
     */
    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage(null);

        const { template_id, template_name, subject, body_html, body_text } = currentTemplate;

        // Basic validation
        if (!template_name.trim() || !subject.trim() || !body_html.trim()) {
            setVariant('danger');
            setMessage('Please fill in all required fields.');
            setLoading(false);
            return;
        }

        if (!fusionAuthUserId) {
            setVariant('danger');
            setMessage('User authentication information is missing.');
            setLoading(false);
            return;
        }

        try {
            let response;
            const templateData = {
                template_name: template_name.trim(),
                subject: subject.trim(),
                body_html: body_html.trim(),
                body_text: body_text.trim(),
            };

            const params = { fusionauth_user_id: fusionAuthUserId }; // Add FusionAuth user ID as a query parameter

            if (modalType === 'create') {
                response = await axios.post(`${baseUrl}/app/templates`, templateData, {
                    headers: {
                        Authorization: `Bearer ${tokens.accessToken}`,
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                    },
                    params, // Include FusionAuth user ID
                });
            } else if (modalType === 'edit') {
                response = await axios.put(`${baseUrl}/app/templates/${template_id}`, templateData, {
                    headers: {
                        Authorization: `Bearer ${tokens.accessToken}`,
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                    },
                    params, // Include FusionAuth user ID
                });
            }

            if (response.data.success) {
                setVariant('success');
                setMessage(`Template ${modalType === 'create' ? 'created' : 'updated'} successfully.`);
                setShowTemplateModal(false);
                fetchTemplates();
            } else {
                setVariant('danger');
                setMessage(`Failed to ${modalType === 'create' ? 'create' : 'update'} the template.`);
            }
        } catch (error) {
            console.error(`Error ${modalType === 'create' ? 'creating' : 'updating'} template:`, error);
            if (error.response && error.response.status === 409) {
                setVariant('danger');
                setMessage('A template with the same name already exists.');
            } else {
                setVariant('danger');
                setMessage(`An error occurred while ${modalType === 'create' ? 'creating' : 'updating'} the template.`);
            }
        }

        setLoading(false);
    };

    /**
     * Handles input changes in the modal form.
     * @param {string} name - The name of the field.
     * @param {string} value - The value of the field.
     */
    const handleChange = (name, value) => {
        setCurrentTemplate((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    /**
     * Handles selecting a template to load into the EmailComponent.
     * @param {Object} template - The selected template.
     */
    const handleSelectTemplate = (template) => {
        if (onSelectTemplate) {
            onSelectTemplate(template);
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Email Templates</h3>
                <Button variant="success" onClick={handleCreate}>
                    <FaPlus /> Create Template
                </Button>
            </div>

            {message && <Alert variant={variant}>{message}</Alert>}

            {loading ? (
                <div className="text-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Template Name</th>
                            <th>Subject</th>
                            <th>Created At</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.length === 0 ? (
                            <tr>
                                <td colSpan="5" className="text-center">No templates found.</td>
                            </tr>
                        ) : (
                            templates.map((template, index) => (
                                <tr key={template.template_id}>
                                    <td>{index + 1}</td>
                                    <td>{template.template_name}</td>
                                    <td>{template.subject}</td>
                                    <td>{new Date(template.created_at).toLocaleString()}</td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            className="me-2"
                                            onClick={() => handleEdit(template)}
                                        >
                                            <FaEdit /> Edit
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDelete(template.template_id)}
                                        >
                                            <FaTrash /> Delete
                                        </Button>
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="ms-2"
                                            onClick={() => handleSelectTemplate(template)}
                                        >
                                            <FaSave /> Use
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            )}

            {/* Modal for Creating/Editing Templates */}
            <Modal show={showTemplateModal} onHide={() => setShowTemplateModal(false)} size="lg">
                <Form onSubmit={handleSave}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalType === 'create' ? 'Create New Template' : 'Edit Template'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="template_name" className="mb-3">
                            <Form.Label>Template Name<span style={{ color: 'red' }}> *</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="template_name"
                                value={currentTemplate.template_name}
                                onChange={(e) => handleChange('template_name', e.target.value)}
                                placeholder="Enter template name"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="subject" className="mb-3">
                            <Form.Label>Subject<span style={{ color: 'red' }}> *</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="subject"
                                value={currentTemplate.subject}
                                onChange={(e) => handleChange('subject', e.target.value)}
                                placeholder="Enter email subject"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="body_html" className="mb-3">
                            <Form.Label>
                                HTML Content<span style={{ color: 'red' }}> *</span>
                            </Form.Label>
                            <ReactQuill
                                value={currentTemplate.body_html}
                                onChange={(value) => handleChange('body_html', value)}
                                theme="snow"
                                style={{ height: '300px' }} // Adjust the height as needed
                            />
                        </Form.Group>


                        <Form.Group controlId="body_text" className="mb-3 d-none">
                            <Form.Label>Plain Text Content</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="body_text"
                                value={currentTemplate.body_text}
                                onChange={(e) => handleChange('body_text', e.target.value)}
                                placeholder="Enter plain text content (optional)"
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowTemplateModal(false)}>
                            <FaTimes /> Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave />} Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default Templates;
