import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const CopyToClipboardButton = ({ textToCopy }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setCopied(true);
            setTimeout(() => setCopied(false), 3000); // Reset copied status after 3 seconds
        } catch (err) {
            // Handle the error case
        }
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {copied ? 'Copied!' : `Copy ${textToCopy} to clipboard`}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
        >
            <FontAwesomeIcon icon={faClipboard} onClick={copyToClipboard} style={{ cursor: 'pointer', marginLeft: '5px' }} />
        </OverlayTrigger>
    );
};

export default CopyToClipboardButton;
