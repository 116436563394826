// src/components/UserNotes.js

import React, { useState, useEffect } from 'react';
import WinkTokenizer from 'wink-tokenizer';
import { stemmer } from 'stemmer';

import {
  Button,
  Alert,
  Table,
  InputGroup,
  FormControl,
  Spinner,
  Modal,
  Row,
  Col,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaDownload,
  FaSearch,
  FaEdit,
  FaTrash,
} from 'react-icons/fa';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Line, Pie, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UserNotes = () => {
  // Retrieve userId from Redux store (if needed)
  // Since we're fetching all notes, userId might not be necessary
  // However, keeping it in case you have authorization logic based on userId
  const userId = useSelector((state) => state.user.user?.fusionauth_user_id);
  console.log('Retrieved userId from Redux:', userId);

  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'updated_at', direction: 'desc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [messageSent, setMessageSent] = useState(false); // For success message

  const baseUrl = `${process.env.REACT_APP_SERVER_URL}/sales`;
  const apiKey = process.env.REACT_APP_SERVER_API_KEY;

  const tokenizer = new WinkTokenizer();
 


  // Helper function to format date to 'YYYY-MM'
  const formatDateToMonth = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  };

  // Function to fetch all notes for all properties and users
  const fetchAllNotes = () => {
    setIsLoading(true);
    setError(null);

    fetch(`${baseUrl}/notes/all`, { // Updated endpoint
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey, // Include API key if required
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch notes');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched notes:', data);
        if (Array.isArray(data.notes)) {
          setNotes(data.notes);
          setFilteredNotes(data.notes);
        } else {
          setNotes([]);
          setFilteredNotes([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching notes:', error);
        setError('Failed to fetch notes');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Fetch on component mount

  // Handle sorting
  const sortNotes = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let sortedNotes = [...notes];

    if (sortConfig.key) {
      sortedNotes.sort((a, b) => {
        // Handle date sorting
        if (sortConfig.key === 'created_at' || sortConfig.key === 'updated_at') {
          const dateA = new Date(a[sortConfig.key]);
          const dateB = new Date(b[sortConfig.key]);
          if (dateA < dateB) return sortConfig.direction === 'asc' ? -1 : 1;
          if (dateA > dateB) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        }

        // Handle string sorting
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    // Apply search filter
    if (searchTerm) {
      sortedNotes = sortedNotes.filter(
        (note) =>
          note.note_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (note.county && note.county.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (note.state && note.state.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (note.saleTypeDescription &&
            note.saleTypeDescription.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (note.address1 && note.address1.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (note.property_id && note.property_id.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (note.user_id && note.user_id.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredNotes(sortedNotes);
  }, [notes, sortConfig, searchTerm]);

  // Export to Excel
  const exportToExcel = () => {
    if (filteredNotes.length === 0) {
      setError('No notes available to export');
      return;
    }

    const exportData = filteredNotes.map((note) => ({
      'Note Text': note.note_text,
      'Created At': new Date(note.created_at).toLocaleString(undefined, { timeZoneName: 'short' }),
      'Updated At': new Date(note.updated_at).toLocaleString(undefined, { timeZoneName: 'short' }),
      'County': note.county,
      'State': note.state,
      'Sale Type Description': note.saleTypeDescription,
      'Address': note.address1,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Property Notes');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'PropertyNotes.xlsx');

    setMessageSent(true);
    setTimeout(() => {
      setMessageSent(false);
    }, 3000);
  };

  // Handle Delete Note
  const handleDeleteNote = (note) => {
    setNoteToDelete(note);
    setShowDeleteModal(true);
  };

  const confirmDeleteNote = () => {
    if (!noteToDelete) return;

    fetch(`${baseUrl}/notes?noteId=${encodeURIComponent(noteToDelete.note_id)}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to delete note');
        }
        return response.json();
      })
      .then(() => {
        fetchAllNotes();
        setShowDeleteModal(false);
        setNoteToDelete(null);
      })
      .catch((error) => {
        console.error('Error deleting note:', error);
        setError('Failed to delete note');
        setShowDeleteModal(false);
        setNoteToDelete(null);
      });
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setNoteToDelete(null);
  };

  // If the user is not logged in, don't render the component
  if (!userId) {
    return null;
  }

  // Data preparation functions
  const notesOverTimeData = () => {
    const counts = {};

    notes.forEach((note) => {
      const month = formatDateToMonth(note.created_at);
      counts[month] = (counts[month] || 0) + 1;
    });

    // Sort the months
    const sortedMonths = Object.keys(counts).sort();
    const data = sortedMonths.map((month) => counts[month]);

    return {
      labels: sortedMonths,
      datasets: [
        {
          label: 'Number of Notes',
          data: data,
          fill: false,
          backgroundColor: '#FF6D40',
          borderColor: '#FF6D40',
        },
      ],
    };
  };

  const saleTypeDistributionData = () => {
    const counts = {};

    notes.forEach((note) => {
      const saleType = note.saleTypeDescription || 'Unknown';
      counts[saleType] = (counts[saleType] || 0) + 1;
    });

    const labels = Object.keys(counts);
    const data = Object.values(counts);
    const backgroundColors = labels.map(
      (_, index) => `hsl(${(index * 360) / labels.length}, 70%, 50%)`
    );

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: backgroundColors,
        },
      ],
    };
  };

  const notesByCountyData = () => {
    const counts = {};

    notes.forEach((note) => {
      const county = note.county || 'Unknown';
      counts[county] = (counts[county] || 0) + 1;
    });

    // Sort counties by number of notes
    const sortedCounties = Object.keys(counts).sort((a, b) => counts[b] - counts[a]);
    const data = sortedCounties.map((county) => counts[county]);

    return {
      labels: sortedCounties,
      datasets: [
        {
          label: 'Number of Notes',
          data: data,
          backgroundColor: '#4BC0C0',
        },
      ],
    };
  };

  // Function to group similar notes
  const groupSimilarNotes = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
    const recentNotes = notes.filter((note) => new Date(note.updated_at) >= oneWeekAgo);
  
    const groups = [];
    const visited = new Set();
  
    // Configure a similarity threshold
    const similarityThreshold = 0.4;
  
    // Function to calculate similarity between two texts

    const exclusionaryStems = ['parcel', 'property', 'land', 'drive', 'at']; // Add words or stems to exclude

   
    const calculateSimilarity = (text1, text2) => {
        const tokens1 = tokenizer.tokenize(text1 || '').filter(token => token.tag === 'word')
          .map(token => stemmer(token.value.toLowerCase()))
          .filter(stem => !exclusionaryStems.includes(stem)); // Exclude unwanted stems
      
        const tokens2 = tokenizer.tokenize(text2 || '').filter(token => token.tag === 'word')
          .map(token => stemmer(token.value.toLowerCase()))
          .filter(stem => !exclusionaryStems.includes(stem)); // Exclude unwanted stems
      
        const set1 = new Set(tokens1);
        const set2 = new Set(tokens2);
      
        const intersection = new Set([...set1].filter(word => set2.has(word)));
      
        console.log('Tokens1:', tokens1);
        console.log('Tokens2:', tokens2);
        console.log('Intersection:', [...intersection]);
      
        return intersection.size / Math.max(set1.size, set2.size);
      };
      
      

  
    for (let i = 0; i < recentNotes.length; i++) {
      if (visited.has(i)) continue;
  
      const group = [recentNotes[i]];
      visited.add(i);
  
      for (let j = i + 1; j < recentNotes.length; j++) {
        if (visited.has(j)) continue;
  
        const similarity = calculateSimilarity(recentNotes[i].note_text, recentNotes[j].note_text);
        if (similarity >= similarityThreshold) {
          group.push(recentNotes[j]);
          visited.add(j);
        }
      }
  
      groups.push(group);
    }
  
    return groups;
  };
  
  
  
  const groupedNotes = groupSimilarNotes();
  

  return (
    <div
      className="container-fluid p-3"
      style={{
        border: '5px solid #FF6D40',
        borderRadius: '10px',
        boxSizing: 'border-box',
        position: 'relative',
      }}
    >
      {isLoading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            width: '100%',
            height: '100%',
            minHeight: '200px',
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {!isLoading && messageSent && (
        <div
          className="d-flex align-items-center justify-content-center mb-3 p-3 rounded"
          style={{
            backgroundColor: '#FF6D40',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Notes Successfully Exported
        </div>
      )}
      {!isLoading && !messageSent && (
        <>
          <div
            className="bg-warning text-white text-center py-2 mb-4 rounded"
            style={{
              backgroundColor: '#FF6D40',
            }}
          >
            <h4 className="mb-0">User Insights - Property Notes</h4>
          </div>

          {/* Charts */}
          <Row className="mb-4">
            <Col md={6} sm={12} className="mb-4">
              <div
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  padding: '20px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  height: '100%',
                }}
              >
                <h5 style={{ marginBottom: '20px' }}>Notes Over Time</h5>
                <Line data={notesOverTimeData()} />
              </div>
            </Col>
            <Col md={6} sm={12} className="mb-4">
              <div
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  padding: '20px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  height: '100%',
                }}
              >
                <h5 style={{ marginBottom: '20px' }}>Sale Type Distribution</h5>
                <Pie data={saleTypeDistributionData()} />
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12} sm={12} className="mb-4">
              <div
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  padding: '20px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  height: '100%',
                }}
              >
                <h5 style={{ marginBottom: '20px' }}>Notes by County</h5>
                <Bar data={notesByCountyData()} />
              </div>
            </Col>
          </Row>

          {/* Idea Board - Grouped Similar Notes */}
            <div
            style={{
                border: '1px solid #ddd',
                borderRadius: '5px',
                padding: '20px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                marginBottom: '20px',
            }}
            >
            <h5 style={{ marginBottom: '20px' }}>Idea Board - Grouped Similar Notes | Past Week</h5>
            {groupSimilarNotes().map((group, index) => (
                <div
                key={index}
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '15px',
                    marginBottom: '15px',
                    backgroundColor: '#f9f9f9',
                }}
                >
                <h6 style={{ marginBottom: '10px' }}>Group {index + 1} ({group.length} notes)</h6>
                <ul>
                    {group.map((note) => (
                    <li key={note.note_id}>
                        {note.note_text}
                        <br />
                        <small>
                        Updated At: {new Date(note.updated_at).toLocaleString()} | Address: {note.address1 || 'N/A'}, {note.county || 'N/A'}, {note.state || 'N/A'}
                        </small>
                    </li>
                    ))}
                </ul>
                </div>
            ))}
            </div>


          {/* Export and Search */}
          <div
            style={{
              border: '1px solid #ddd',
              borderRadius: '5px',
              padding: '20px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            }}
          >
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
              <InputGroup style={{ maxWidth: '300px' }} className="mb-2">
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
                <FormControl
                  placeholder="Search notes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <Button
                variant="success"
                onClick={exportToExcel}
                disabled={filteredNotes.length === 0}
                className="mb-2"
              >
                <FaDownload /> Export to Excel
              </Button>
            </div>
            <div className="table-responsive">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th onClick={() => sortNotes('note_text')} style={{ cursor: 'pointer' }}>
                      Note Text{' '}
                      {sortConfig.key === 'note_text' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'note_text' && <FaSort />}
                    </th>
                    {/* If you want to include 'Created At', uncomment the following */}
                    {/* <th onClick={() => sortNotes('created_at')} style={{ cursor: 'pointer' }}>
                      Created At{' '}
                      {sortConfig.key === 'created_at' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'created_at' && <FaSort />}
                    </th> */}
                    <th onClick={() => sortNotes('updated_at')} style={{ cursor: 'pointer' }}>
                      Updated At{' '}
                      {sortConfig.key === 'updated_at' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'updated_at' && <FaSort />}
                    </th>
                    <th onClick={() => sortNotes('county')} style={{ cursor: 'pointer' }}>
                      County{' '}
                      {sortConfig.key === 'county' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'county' && <FaSort />}
                    </th>
                    <th onClick={() => sortNotes('state')} style={{ cursor: 'pointer' }}>
                      State{' '}
                      {sortConfig.key === 'state' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'state' && <FaSort />}
                    </th>
                    <th onClick={() => sortNotes('saleTypeDescription')} style={{ cursor: 'pointer' }}>
                      Sale Type{' '}
                      {sortConfig.key === 'saleTypeDescription' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'saleTypeDescription' && <FaSort />}
                    </th>
                    <th onClick={() => sortNotes('address1')} style={{ cursor: 'pointer' }}>
                      Address{' '}
                      {sortConfig.key === 'address1' && (
                        sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />
                      )}
                      {sortConfig.key !== 'address1' && <FaSort />}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredNotes.length > 0 ? (
                    filteredNotes.map((note) => (
                      <tr key={note.note_id}>
                        <td>{note.note_text}</td>
                        {/* If you want to include 'Created At', uncomment the following */}
                        {/* <td>{new Date(note.created_at).toLocaleString(undefined, { timeZoneName: 'short' })}</td> */}
                        <td>{new Date(note.updated_at).toLocaleString(undefined, { timeZoneName: 'short' })}</td>
                        <td>{note.county || 'N/A'}</td>
                        <td>{note.state || 'N/A'}</td>
                        <td>{note.saleTypeDescription || 'N/A'}</td>
                        <td>{note.address1 || 'N/A'}</td>
                        <td>
                          {/* Placeholder for Edit functionality */}
                          <Button
                            variant="info"
                            size="sm"
                            className="mr-2 mb-1"
                            onClick={() => alert('Edit functionality not implemented')}
                          >
                            <FaEdit /> Edit
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            className="mb-1"
                            onClick={() => handleDeleteNote(note)}
                          >
                            <FaTrash /> Delete
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No notes found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={cancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the note with ID: <strong>{noteToDelete?.note_id}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteNote}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserNotes;
