// src/components/PropertiesCard.js

import React, { memo, useEffect, useState } from 'react';
import {
  Card,
  Button,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Toast,
  Spinner
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart as solidHeart,
  faHeart as regularHeart,
  faRegistered,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import CopyToClipboardButton from './CopyToClipboardButton';
import getCoordinates from './getCoordinates';
import PropertyNotes from './PropertyNotes';
import { processAuctionDate } from './utils';
// Google Maps imports
import { GoogleMap, Marker } from '@react-google-maps/api';

// Redux slice imports for favorites
import { useDispatch } from 'react-redux';
import { addFavorite, removeFavorite } from '../redux/favoritesSlice';

// Import PropTypes for type checking
import PropTypes from 'prop-types';

// API to fetch property details
import { propertiesAPI } from '../pages/PropertiesAPI';

const PropertiesCard = ({
  property,
  favoriteId, // Favorite ID if the property is favorited
  handleDetailsClick,
  handleRegisterOrUnregisterClick, // Expects an object with saleId and auction
  isPropertyFavorite,
  isRegistered, // Boolean indicating registration status
  getFavoriteButtonLabel,
  getRegisterButtonLabel,
  propIndex,
  originalSaleId, // Received from Properties.js
  isLoadingMap,
  userRegistrations
}) => {
  const dispatch = useDispatch();
  console.log('PropertiesCard => property:', property);

  // Local state to store propertyInfo details
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [isFetchingInfo, setIsFetchingInfo] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  // Toast states for errors
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');

  // Coordinates
  const [coordinates, setCoordinates] = useState(property.coordinates);

  // Use originalSaleId passed from Properties.js or fallback to saleId
  const standardizedSaleId = originalSaleId ?? property.saleId;

  // Fetch propertyInfo when the card mounts
  useEffect(() => {
    const fetchPropertyInfo = async () => {
      // Avoid fetching if already fetched
      if (propertyInfo || isFetchingInfo) return;

      // Validate saleId
      if (
        standardizedSaleId === null ||
        standardizedSaleId === undefined ||
        isNaN(Number(standardizedSaleId))
      ) {
        console.error('Invalid saleId for property:', property);
        setErrorToastMessage('Invalid sale ID for this property.');
        setShowErrorToast(true);
        return;
      }

      setIsFetchingInfo(true);
      try {
        // Fetch property details
        const details = await propertiesAPI.getPropertyDetail({
          id: property.id,
          propertyId: property.propertyId,
          saleId: Number(standardizedSaleId),
          saleType: property.saleType,
        });

        if (details && details.propertyInfo) {
          setPropertyInfo(details.propertyInfo);
        } else {
          console.warn('No propertyInfo found in API response for property:', property);
          setErrorToastMessage('Property details not found.');
          setShowErrorToast(true);
        }
      } catch (err) {
        console.error('Error fetching property details:', err);
        setErrorToastMessage('Failed to fetch property details.');
        setShowErrorToast(true);
      } finally {
        setIsFetchingInfo(false);
      }
    };

    fetchPropertyInfo();
  }, [
    property.id,
    property.propertyId,
    property.saleType,
    propertyInfo,
    isFetchingInfo,
    standardizedSaleId,
    property
  ]);

  // If coordinates are missing, attempt to fetch
  useEffect(() => {
    const fetchCoordinatesIfNeeded = async () => {
      if (!property.latitude || !property.longitude) {
        if (property.address1 && property.city && property.state && property.zip) {
          const address = `${property.address1}, ${property.city}, ${property.state} ${property.zip}`;
          const fetchedCoordinates = await getCoordinates(address);
          if (fetchedCoordinates && fetchedCoordinates.lat && fetchedCoordinates.lng) {
            setCoordinates(fetchedCoordinates);
            console.log(
              `Fetched coordinates for property ${property.propertyId}:`,
              fetchedCoordinates
            );
          }
        }
      } else {
        setCoordinates({ lat: property.latitude, lng: property.longitude });
      }
    };
    if (!coordinates || !coordinates.lat || !coordinates.lng) {
      fetchCoordinatesIfNeeded();
    }
  }, [property, coordinates]);

  // Additional fields for tax/foreclosure
  const isTaxSale = property.saleTypeDescription !== 'Foreclosure';


  const isForeclosureSale = property.saleTypeDescription === 'Foreclosure';
  const isOnlineAuction =
    property.type === 'Online' ||
    (property.auctionLocation && property.auctionLocation.includes('www.zeusauction.com'));

  // Parse and set auction times (now showing only the time portion)
  const [saleLocalTime, setSaleLocalTime] = useState('N/A');
  const [userLocalTime, setUserLocalTime] = useState('N/A');
  const [showSaleTime, setShowSaleTime] = useState(true);

  useEffect(() => {
    if (property.auctionDate && property.state && property.county) {
      // Get the time from auctionDetail first, or fall back to property.time if needed
      const actualTime =
        property?.auctionDetail?.time ||
        property.time ||
        ''; // if there's absolutely no time, pass an empty string

      if (actualTime) {
        const { saleLocalTime: sLocal, userLocalTime: uLocal } = processAuctionDate(
          `${property.auctionDate} ${actualTime}`,
          property.state,
          property.county
        );
        // Remove the date portion by splitting on the comma and taking only the time
        const timeOnlySLocal = sLocal.includes(',') ? sLocal.split(',')[1].trim() : sLocal;
        const timeOnlyULocal = uLocal.includes(',') ? uLocal.split(',')[1].trim() : uLocal;
        setSaleLocalTime(timeOnlySLocal);
        setUserLocalTime(timeOnlyULocal);
      } else {
        setSaleLocalTime('');
        setUserLocalTime('');
      }
    }
  }, [property.auctionDate, property.state, property.county, property.auctionDetail, property.time]);

  // Handle toggle between sale's local time and user's local time
  const toggleSaleTime = () => {
    setShowSaleTime(!showSaleTime);
  };

  // Error Toast Handler
  useEffect(() => {
    if (fetchError) {
      setErrorToastMessage(fetchError);
      setShowErrorToast(true);
    }
  }, [fetchError]);

  // -----------
  //  Favorites
  // -----------
  const handleFavoriteClick = () => {
    console.log('handleFavoriteClick => property:', property);
    if (!favoriteId && !property.propertyId) {
      console.error(
        'Missing property.propertyId or favoriteId for favorite/unfavorite logic.'
      );
      return;
    }

    if (isPropertyFavorite(property)) {
      // If it’s already a favorite, remove it using favoriteId
      if (favoriteId) {
        dispatch(removeFavorite(favoriteId));
        console.log(`Removed favorite with ID: ${favoriteId}`);
      } else {
        console.error('Favorite ID is undefined for property:', property);
      }
    } else {
      // If not a favorite, add it
      dispatch(addFavorite(property));
      console.log(`Added property to favorites: ${property.propertyId}`);
    }
  };

  // -----------
  //  Render
  // -----------
  return (
    <Card
      className="property-card"
      style={{ width: '21rem', minHeight: '400px', margin: '2rem' }}
    >
      <div className="map-container" style={{ height: '200px' }}>
        {isLoadingMap ? (
          <p>Loading map...</p>
        ) : coordinates && coordinates.lat && coordinates.lng ? (
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            center={{ lat: coordinates.lat, lng: coordinates.lng }}
            zoom={15}
          >
            <Marker position={{ lat: coordinates.lat, lng: coordinates.lng }} />
          </GoogleMap>
        ) : (
          <div
            style={{
              backgroundColor: '#4A4A6A',
              color: '#FF6d40',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Map Not Available
          </div>
        )}
      </div>

      <Card.Body>
        {/* Address */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-title-${propIndex}`}>{property.address1}</Tooltip>}
        >
          <Card.Title className="card-title">
            <span className="truncate">{property.address1}</span>
            <CopyToClipboardButton
              textToCopy={property.address1}
              className="copy-button"
            />
          </Card.Title>
        </OverlayTrigger>

        {/* City, State, Zip */}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-subtitle-${propIndex}`}>
              {`${property.city}, ${property.state} ${property.zip}`}
            </Tooltip>
          }
        >
          <Card.Subtitle className="mb-2 text-muted truncate">
            {`${property.city}, ${property.state} ${property.zip}`}{' '}
            <CopyToClipboardButton
              textToCopy={`${property.city}, ${property.state} ${property.zip}`}
            />
          </Card.Subtitle>
        </OverlayTrigger>

        {/* If Tax Sale, show saleId + parcel */}
        {isTaxSale && (
        <>
          <div className="card-text-row">
            <span className="card-text-label">Sale ID:</span>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-sale-id-${propIndex}`}>
                  {property.displaySaleId || 'N/A'}
                </Tooltip>
              }
            >
              <span className="card-text-value truncate">
                {property.displaySaleId || 'N/A'}{' '}
                <CopyToClipboardButton textToCopy={property.displaySaleId || 'N/A'} />
              </span>
            </OverlayTrigger>
          </div>

          <div className="card-text-row">
            <span className="card-text-label">Parcel #:</span>
            <div className="card-text-value">
              {property.propertyId ? (
                property.propertyId.split(' / ').map((parcel, idx) => (
                  <OverlayTrigger
                    key={idx}
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-parcel-${idx}-${propIndex}`}>
                        {parcel}
                      </Tooltip>
                    }
                  >
                    <div className="truncate">
                      {parcel}{' '}
                      <CopyToClipboardButton textToCopy={parcel} />
                    </div>
                  </OverlayTrigger>
                ))
              ) : (
                <span>N/A</span>
              )}
            </div>
          </div>
        </>
      )}

        {/* If Foreclosure, causeNumber + defendant */}
        {isForeclosureSale && propertyInfo && (
          <>
            <div className="card-text-row">
              <span className="card-text-label">Cause #:</span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-cause-${propIndex}`}>
                    {propertyInfo.causeNumber || 'N/A'}
                  </Tooltip>
                }
              >
                <span className="card-text-value truncate">
                  {propertyInfo.causeNumber || 'N/A'}{' '}
                  <CopyToClipboardButton
                    textToCopy={propertyInfo.causeNumber || 'N/A'}
                  />
                </span>
              </OverlayTrigger>
            </div>

            <div className="card-text-row">
              <span className="card-text-label">Defendant:</span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-defendant-${propIndex}`}>
                    {propertyInfo.defendant || 'N/A'}
                  </Tooltip>
                }
              >
                <span className="card-text-value truncate">
                  {propertyInfo.defendant || 'N/A'}{' '}
                  <CopyToClipboardButton
                    textToCopy={propertyInfo.defendant || 'N/A'}
                  />
                </span>
              </OverlayTrigger>
            </div>
          </>
        )}

        {/* Sale Date */}
        <div className="card-text-row">
          <span className="card-text-label">Sale Date:</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-sale-date-${propIndex}`}>
                {property.auctionDate || 'N/A'}
              </Tooltip>
            }
          >
            <span className="card-text-value truncate">
              {property.auctionDate || 'N/A'}
            </span>
          </OverlayTrigger>
        </div>

        {/* Sale Time (time only) */}
        <div className="card-text-row">
          <span className="card-text-label">Sale Time:</span>
          <span className="card-text-value truncate">
            {showSaleTime ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-sale-time-state-${propIndex}`}>
                    {saleLocalTime}
                  </Tooltip>
                }
              >
                <span>{saleLocalTime}</span>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-sale-time-user-${propIndex}`}>
                    {userLocalTime}
                  </Tooltip>
                }
              >
                <span>{userLocalTime}</span>
              </OverlayTrigger>
            )}
          </span>
        </div>

        <div style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
          <label htmlFor={`time-toggle-${propIndex}`} style={{ marginRight: '0.5rem' }}>
            Show {showSaleTime ? 'Sale’s Local Time' : 'User’s Local Time'}
          </label>
          <input
            type="checkbox"
            id={`time-toggle-${propIndex}`}
            checked={showSaleTime}
            onChange={toggleSaleTime}
          />
        </div>

        {/* Status */}
        <div className="card-text-row">
          <span className="card-text-label">Status:</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-status-${propIndex}`}>
                {property.saleStatusDescription || 'N/A'}
              </Tooltip>
            }
          >
            <span className="card-text-value truncate">
              {property.saleStatusDescription || 'N/A'}
            </span>
          </OverlayTrigger>
        </div>

        {/* Sale type + method */}
        <div className="card-text-row">
          <span className="card-text-label">Sale Type:</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-sale-type-${propIndex}`}>
                {property.saleTypeDescription || 'N/A'}
              </Tooltip>
            }
          >
            <span className="card-text-value truncate">
              {property.saleTypeDescription || 'N/A'}
            </span>
          </OverlayTrigger>
        </div>

        <div className="card-text-row">
          <span className="card-text-label">Method:</span>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-method-${propIndex}`}>
                {property.type || 'N/A'}
              </Tooltip>
            }
          >
            <span className="card-text-value truncate">
              {property.type || 'N/A'}
            </span>
          </OverlayTrigger>
        </div>

        {/* Notes */}
        <PropertyNotes
          propertyId={property.propertyId}
          state={property.state}
          county={property.county}
          address={property.address1}
          saleTypeDescription={property.saleTypeDescription}
          address1={property.address1}
        />

        {/* Bottom Buttons: Register / Favorite / Details */}
        <div className="button-group-container">
          <ButtonGroup size="sm">
            {/* Register using registrationSlice */}
            {isOnlineAuction ? (
              <Button
                variant="outline-primary"
                href="http://www.zeusauction.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Register on Zeus Auctions
              </Button>
            ) : (
              <Button
                variant={
                  isRegistered
                    ? 'outline-danger'
                    : 'outline-primary'
                }
                onClick={() => {
                  if (
                    standardizedSaleId === null ||
                    standardizedSaleId === undefined ||
                    isNaN(Number(standardizedSaleId))
                  ) {
                    console.error(
                      'Cannot register/unregister without a valid saleId for property:',
                      property
                    );
                    setErrorToastMessage(
                      'Cannot process your request due to an invalid sale ID.'
                    );
                    setShowErrorToast(true);
                    return;
                  }
                  handleRegisterOrUnregisterClick({
                    saleId: standardizedSaleId,
                    auction: property
                  });
                }}
                disabled={isFetchingInfo || !propertyInfo}
              >
                <FontAwesomeIcon icon={faRegistered} />{' '}
                {isRegistered ? 'Unregister' : 'Register'}
              </Button>
            )}

            {/* Favorite: dispatch addFavorite/removeFavorite */}
            <Button
              variant={isPropertyFavorite(property) ? 'success' : 'outline-success'}
              onClick={handleFavoriteClick}
            >
              <FontAwesomeIcon
                icon={isPropertyFavorite(property) ? solidHeart : regularHeart}
              />{' '}
              {isPropertyFavorite(property) ? 'Unfavorite' : 'Favorite'}
            </Button>

            {/* Details */}
            <Button
              variant="outline-primary"
              onClick={(e) => handleDetailsClick(property, propertyInfo, e)}
              disabled={isFetchingInfo || !propertyInfo}
            >
              {isFetchingInfo ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  Loading...
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faInfoCircle} /> Details
                </>
              )}
            </Button>
          </ButtonGroup>
        </div>
      </Card.Body>

      {/* Error Toast */}
      <Toast
        onClose={() => setShowErrorToast(false)}
        show={showErrorToast}
        delay={5000}
        autohide
        bg="danger"
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          minWidth: '200px'
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>{errorToastMessage}</Toast.Body>
      </Toast>
    </Card>
  );
};

PropertiesCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    propertyId: PropTypes.string.isRequired,
    saleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    address1: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    saleTypeDescription: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.string,
    time: PropTypes.string,
    county: PropTypes.string,
    auctionDate: PropTypes.string,
    causeNumber: PropTypes.string,
    defendant: PropTypes.string,
    property_id: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    auctionDetail: PropTypes.object,
    displaySaleId: PropTypes.string,
    saleStatusDescription: PropTypes.string,
    // ...other fields
  }).isRequired,
  favoriteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleDetailsClick: PropTypes.func.isRequired,
  handleRegisterOrUnregisterClick: PropTypes.func.isRequired,
  isPropertyFavorite: PropTypes.func.isRequired,
  isRegistered: PropTypes.bool.isRequired,
  getFavoriteButtonLabel: PropTypes.func.isRequired,
  getRegisterButtonLabel: PropTypes.func.isRequired,
  propIndex: PropTypes.number.isRequired,
  originalSaleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoadingMap: PropTypes.bool,
  userRegistrations: PropTypes.array,
};

export default memo(PropertiesCard);
