const COUNTIES = {
    IN: [
      { name: 'Adams', code: 'Adams' },
      { name: 'Allen', code: 'Allen' },
      { name: 'Bartholomew', code: 'Bartholomew' },
      { name: 'Benton', code: 'Benton' },
      { name: 'Blackford', code: 'Blackford' },
      { name: 'Boone', code: 'Boone' },
      { name: 'Brown', code: 'Brown' },
      { name: 'Carroll', code: 'Carroll' },
      { name: 'Cass', code: 'Cass' },
      { name: 'Clark', code: 'Clark' },
      { name: 'Clay', code: 'Clay' },
      { name: 'Clinton', code: 'Clinton' },
      { name: 'Crawford', code: 'Crawford' },
      { name: 'Daviess', code: 'Daviess' },
      { name: 'Dearborn', code: 'Dearborn' },
      { name: 'Decatur', code: 'Decatur' },
      { name: 'DeKalb', code: 'DeKalb' },
      { name: 'Delaware', code: 'Delaware' },
      { name: 'Dubois', code: 'Dubois' },
      { name: 'Elkhart', code: 'Elkhart' },
      { name: 'Fayette', code: 'Fayette' },
      { name: 'Floyd', code: 'Floyd' },
      { name: 'Fountain', code: 'Fountain' },
      { name: 'Franklin', code: 'Franklin' },
      { name: 'Fulton', code: 'Fulton' },
      { name: 'Gibson', code: 'Gibson' },
      { name: 'Grant', code: 'Grant' },
      { name: 'Greene', code: 'Greene' },
      { name: 'Hamilton', code: 'Hamilton' },
      { name: 'Hancock', code: 'Hancock' },
      { name: 'Harrison', code: 'Harrison' },
      { name: 'Hendricks', code: 'Hendricks' },
      { name: 'Henry', code: 'Henry' },
      { name: 'Howard', code: 'Howard' },
      { name: 'Huntington', code: 'Huntington' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jasper', code: 'Jasper' },
      { name: 'Jay', code: 'Jay' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Jennings', code: 'Jennings' },
      { name: 'Johnson', code: 'Johnson' },
      { name: 'Knox', code: 'Knox' },
      { name: 'Kosciusko', code: 'Kosciusko' },
      { name: 'LaGrange', code: 'LaGrange' },
      { name: 'Lake', code: 'Lake' },
      { name: 'LaPorte', code: 'LaPorte' },
      { name: 'Lawrence', code: 'Lawrence' },
      { name: 'Madison', code: 'Madison' },
      { name: 'Marion', code: 'Marion' },
      { name: 'Marshall', code: 'Marshall' },
      { name: 'Martin', code: 'Martin' },
      { name: 'Miami', code: 'Miami' },
      { name: 'Monroe', code: 'Monroe' },
      { name: 'Montgomery', code: 'Montgomery' },
      { name: 'Morgan', code: 'Morgan' },
      { name: 'Newton', code: 'Newton' },
      { name: 'Noble', code: 'Noble' },
      { name: 'Ohio', code: 'Ohio' },
      { name: 'Orange', code: 'Orange' },
      { name: 'Owen', code: 'Owen' },
      { name: 'Parke', code: 'Parke' },
      { name: 'Perry', code: 'Perry' },
      { name: 'Pike', code: 'Pike' },
      { name: 'Porter', code: 'Porter' },
      { name: 'Posey', code: 'Posey' },
      { name: 'Pulaski', code: 'Pulaski' },
      { name: 'Putnam', code: 'Putnam' },
      { name: 'Randolph', code: 'Randolph' },
      { name: 'Ripley', code: 'Ripley' },
      { name: 'Rush', code: 'Rush' },
      { name: 'St. Joseph', code: 'St. Joseph' },
      { name: 'Scott', code: 'Scott' },
      { name: 'Shelby', code: 'Shelby' },
      { name: 'Spencer', code: 'Spencer' },
      { name: 'Starke', code: 'Starke' },
      { name: 'Steuben', code: 'Steuben' },
      { name: 'Sullivan', code: 'Sullivan' },
      { name: 'Switzerland', code: 'Switzerland' },
      { name: 'Tippecanoe', code: 'Tippecanoe' },
      { name: 'Tipton', code: 'Tipton' },
      { name: 'Union', code: 'Union' },
      { name: 'Vanderburgh', code: 'Vanderburgh' },
      { name: 'Vermillion', code: 'Vermillion' },
      { name: 'Vigo', code: 'Vigo' },
      { name: 'Wabash', code: 'Wabash' },
      { name: 'Warren', code: 'Warren' },
      { name: 'Warrick', code: 'Warrick' },
      { name: 'Washington', code: 'Washington' },
      { name: 'Wayne', code: 'Wayne' },
      { name: 'Wells', code: 'Wells' },
      { name: 'White', code: 'White' },
      { name: 'Whitley', code: 'Whitley' }
    ],
    
    FL: [
      { name: 'Alachua', code: 'Alachua' },
      { name: 'Baker', code: 'Baker' },
      { name: 'Bay', code: 'Bay' },
      { name: 'Bradford', code: 'Bradford' },
      { name: 'Brevard', code: 'Brevard' },
      { name: 'Broward', code: 'Broward' },
      { name: 'Calhoun', code: 'Calhoun' },
      { name: 'Charlotte', code: 'Charlotte' },
      { name: 'Citrus', code: 'Citrus' },
      { name: 'Clay', code: 'Clay' },
      { name: 'Collier', code: 'Collier' },
      { name: 'Columbia', code: 'Columbia' },
      { name: 'DeSoto', code: 'DeSoto' },
      { name: 'Dixie', code: 'Dixie' },
      { name: 'Duval', code: 'Duval' },
      { name: 'Escambia', code: 'Escambia' },
      { name: 'Flagler', code: 'Flagler' },
      { name: 'Franklin', code: 'Franklin' },
      { name: 'Gadsden', code: 'Gadsden' },
      { name: 'Gilchrist', code: 'Gilchrist' },
      { name: 'Glades', code: 'Glades' },
      { name: 'Gulf', code: 'Gulf' },
      { name: 'Hamilton', code: 'Hamilton' },
      { name: 'Hardee', code: 'Hardee' },
      { name: 'Hendry', code: 'Hendry' },
      { name: 'Hernando', code: 'Hernando' },
      { name: 'Highlands', code: 'Highlands' },
      { name: 'Hillsborough', code: 'Hillsborough' },
      { name: 'Holmes', code: 'Holmes' },
      { name: 'Indian River', code: 'Indian River' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Lafayette', code: 'Lafayette' },
      { name: 'Lake', code: 'Lake' },
      { name: 'Lee', code: 'Lee' },
      { name: 'Leon', code: 'Leon' },
      { name: 'Levy', code: 'Levy' },
      { name: 'Liberty', code: 'Liberty' },
      { name: 'Madison', code: 'Madison' },
      { name: 'Manatee', code: 'Manatee' },
      { name: 'Marion', code: 'Marion' },
      { name: 'Martin', code: 'Martin' },
      { name: 'Miami-Dade', code: 'Miami-Dade' },
      { name: 'Monroe', code: 'Monroe' },
      { name: 'Nassau', code: 'Nassau' },
      { name: 'Okaloosa', code: 'Okaloosa' },
      { name: 'Okeechobee', code: 'Okeechobee' },
      { name: 'Orange', code: 'Orange' },
      { name: 'Osceola', code: 'Osceola' },
      { name: 'Palm Beach', code: 'Palm Beach' },
      { name: 'Pasco', code: 'Pasco' },
      { name: 'Pinellas', code: 'Pinellas' },
      { name: 'Polk', code: 'Polk' },
      { name: 'Putnam', code: 'Putnam' },
      { name: 'Santa Rosa', code: 'Santa Rosa' },
      { name: 'Sarasota', code: 'Sarasota' },
      { name: 'Seminole', code: 'Seminole' },
      { name: 'St. Johns', code: 'St. Johns' },
      { name: 'St. Lucie', code: 'St. Lucie' },
      { name: 'Sumter', code: 'Sumter' },
      { name: 'Suwannee', code: 'Suwannee' },
      { name: 'Taylor', code: 'Taylor' },
      { name: 'Union', code: 'Union' },
      { name: 'Volusia', code: 'Volusia' },
      { name: 'Wakulla', code: 'Wakulla' },
      { name: 'Walton', code: 'Walton' },
      { name: 'Washington', code: 'Washington' }
    ],
    
    LA: [
      { name: 'Acadia', code: 'Acadia' },
      { name: 'Allen', code: 'Allen' },
      { name: 'Ascension', code: 'Ascension' },
      { name: 'Assumption', code: 'Assumption' },
      { name: 'Avoyelles', code: 'Avoyelles' },
      { name: 'Beauregard', code: 'Beauregard' },
      { name: 'Bienville', code: 'Bienville' },
      { name: 'Bossier', code: 'Bossier' },
      { name: 'Caddo', code: 'Caddo' },
      { name: 'Calcasieu', code: 'Calcasieu' },
      { name: 'Caldwell', code: 'Caldwell' },
      { name: 'Cameron', code: 'Cameron' },
      { name: 'Catahoula', code: 'Catahoula' },
      { name: 'Claiborne', code: 'Claiborne' },
      { name: 'Concordia', code: 'Concordia' },
      { name: 'De Soto', code: 'De Soto' },
      { name: 'East Baton Rouge', code: 'East Baton Rouge' },
      { name: 'East Carroll', code: 'East Carroll' },
      { name: 'East Feliciana', code: 'East Feliciana' },
      { name: 'Evangeline', code: 'Evangeline' },
      { name: 'Franklin', code: 'Franklin' },
      { name: 'Grant', code: 'Grant' },
      { name: 'Iberia', code: 'Iberia' },
      { name: 'Iberville', code: 'Iberville' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Jefferson Davis', code: 'Jefferson Davis' },
      { name: 'Lafayette', code: 'Lafayette' },
      { name: 'Lafourche', code: 'Lafourche' },
      { name: 'LaSalle', code: 'LaSalle' },
      { name: 'Lincoln', code: 'Lincoln' },
      { name: 'Livingston', code: 'Livingston' },
      { name: 'Madison', code: 'Madison' },
      { name: 'Morehouse', code: 'Morehouse' },
      { name: 'Natchitoches', code: 'Natchitoches' },
      { name: 'Orleans', code: 'Orleans' },
      { name: 'Ouachita', code: 'Ouachita' },
      { name: 'Plaquemines', code: 'Plaquemines' },
      { name: 'Pointe Coupee', code: 'Pointe Coupee' },
      { name: 'Rapides', code: 'Rapides' },
      { name: 'Red River', code: 'Red River' },
      { name: 'Richland', code: 'Richland' },
      { name: 'Sabine', code: 'Sabine' },
      { name: 'St. Bernard', code: 'St. Bernard' },
      { name: 'St. Charles', code: 'St. Charles' },
      { name: 'St. Helena', code: 'St. Helena' },
      { name: 'St. James', code: 'St. James' },
      { name: 'St. John the Baptist', code: 'St. John the Baptist' },
      { name: 'St. Landry', code: 'St. Landry' },
      { name: 'St. Martin', code: 'St. Martin' },
      { name: 'St. Mary', code: 'St. Mary' },
      { name: 'St. Tammany', code: 'St. Tammany' },
      { name: 'Tangipahoa', code: 'Tangipahoa' },
      { name: 'Tensas', code: 'Tensas' },
      { name: 'Terrebonne', code: 'Terrebonne' },
      { name: 'Union', code: 'Union' },
      { name: 'Vermilion', code: 'Vermilion' },
      { name: 'Vernon', code: 'Vernon' },
      { name: 'Washington', code: 'Washington' },
      { name: 'Webster', code: 'Webster' },
      { name: 'West Baton Rouge', code: 'West Baton Rouge' },
      { name: 'West Carroll', code: 'West Carroll' },
      { name: 'West Feliciana', code: 'West Feliciana' },
      { name: 'Winn', code: 'Winn' }
    ],
    
    CO: [
      { name: 'Adams', code: 'Adams' },
      { name: 'Alamosa', code: 'Alamosa' },
      { name: 'Arapahoe', code: 'Arapahoe' },
      { name: 'Archuleta', code: 'Archuleta' },
      { name: 'Baca', code: 'Baca' },
      { name: 'Bent', code: 'Bent' },
      { name: 'Boulder', code: 'Boulder' },
      { name: 'Broomfield', code: 'Broomfield' },
      { name: 'Chaffee', code: 'Chaffee' },
      { name: 'Cheyenne', code: 'Cheyenne' },
      { name: 'Clear Creek', code: 'Clear Creek' },
      { name: 'Conejos', code: 'Conejos' },
      { name: 'Costilla', code: 'Costilla' },
      { name: 'Crowley', code: 'Crowley' },
      { name: 'Custer', code: 'Custer' },
      { name: 'Delta', code: 'Delta' },
      { name: 'Denver', code: 'Denver' },
      { name: 'Dolores', code: 'Dolores' },
      { name: 'Douglas', code: 'Douglas' },
      { name: 'Eagle', code: 'Eagle' },
      { name: 'Elbert', code: 'Elbert' },
      { name: 'El Paso', code: 'El Paso' },
      { name: 'Fremont', code: 'Fremont' },
      { name: 'Garfield', code: 'Garfield' },
      { name: 'Gilpin', code: 'Gilpin' },
      { name: 'Grand', code: 'Grand' },
      { name: 'Gunnison', code: 'Gunnison' },
      { name: 'Hinsdale', code: 'Hinsdale' },
      { name: 'Huerfano', code: 'Huerfano' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Kiowa', code: 'Kiowa' },
      { name: 'Kit Carson', code: 'Kit Carson' },
      { name: 'La Plata', code: 'La Plata' },
      { name: 'Lake', code: 'Lake' },
      { name: 'Larimer', code: 'Larimer' },
      { name: 'Las Animas', code: 'Las Animas' },
      { name: 'Lincoln', code: 'Lincoln' },
      { name: 'Logan', code: 'Logan' },
      { name: 'Mesa', code: 'Mesa' },
      { name: 'Mineral', code: 'Mineral' },
      { name: 'Moffat', code: 'Moffat' },
      { name: 'Montezuma', code: 'Montezuma' },
      { name: 'Montrose', code: 'Montrose' },
      { name: 'Morgan', code: 'Morgan' },
      { name: 'Otero', code: 'Otero' },
      { name: 'Ouray', code: 'Ouray' },
      { name: 'Park', code: 'Park' },
      { name: 'Phillips', code: 'Phillips' },
      { name: 'Pitkin', code: 'Pitkin' },
      { name: 'Prowers', code: 'Prowers' },
      { name: 'Pueblo', code: 'Pueblo' },
      { name: 'Rio Blanco', code: 'Rio Blanco' },
      { name: 'Rio Grande', code: 'Rio Grande' },
      { name: 'Routt', code: 'Routt' },
      { name: 'Saguache', code: 'Saguache' },
      { name: 'San Juan', code: 'San Juan' },
      { name: 'San Miguel', code: 'San Miguel' },
      { name: 'Sedgwick', code: 'Sedgwick' },
      { name: 'Summit', code: 'Summit' },
      { name: 'Teller', code: 'Teller' },
      { name: 'Washington', code: 'Washington' },
      { name: 'Weld', code: 'Weld' },
      { name: 'Yuma', code: 'Yuma' }
    ],

    IA: [
      { name: 'Adair', code: 'Adair' },
      { name: 'Adams', code: 'Adams' },
      { name: 'Allamakee', code: 'Allamakee' },
      { name: 'Appanoose', code: 'Appanoose' },
      { name: 'Audubon', code: 'Audubon' },
      { name: 'Benton', code: 'Benton' },
      { name: 'Black Hawk', code: 'Black Hawk' },
      { name: 'Boone', code: 'Boone' },
      { name: 'Bremer', code: 'Bremer' },
      { name: 'Buchanan', code: 'Buchanan' },
      { name: 'Buena Vista', code: 'Buena Vista' },
      { name: 'Butler', code: 'Butler' },
      { name: 'Calhoun', code: 'Calhoun' },
      { name: 'Carroll', code: 'Carroll' },
      { name: 'Cass', code: 'Cass' },
      { name: 'Cedar', code: 'Cedar' },
      { name: 'Cerro Gordo', code: 'Cerro Gordo' },
      { name: 'Cherokee', code: 'Cherokee' },
      { name: 'Chickasaw', code: 'Chickasaw' },
      { name: 'Clarke', code: 'Clarke' },
      { name: 'Clay', code: 'Clay' },
      { name: 'Clayton', code: 'Clayton' },
      { name: 'Clinton', code: 'Clinton' },
      { name: 'Crawford', code: 'Crawford' },
      { name: 'Dallas', code: 'Dallas' },
      { name: 'Davis', code: 'Davis' },
      { name: 'Decatur', code: 'Decatur' },
      { name: 'Delaware', code: 'Delaware' },
      { name: 'Des Moines', code: 'Des Moines' },
      { name: 'Dickinson', code: 'Dickinson' },
      { name: 'Dubuque', code: 'Dubuque' },
      { name: 'Emmet', code: 'Emmet' },
      { name: 'Fayette', code: 'Fayette' },
      { name: 'Floyd', code: 'Floyd' },
      { name: 'Franklin', code: 'Franklin' },
      { name: 'Fremont', code: 'Fremont' },
      { name: 'Greene', code: 'Greene' },
      { name: 'Grundy', code: 'Grundy' },
      { name: 'Guthrie', code: 'Guthrie' },
      { name: 'Hamilton', code: 'Hamilton' },
      { name: 'Hancock', code: 'Hancock' },
      { name: 'Hardin', code: 'Hardin' },
      { name: 'Harrison', code: 'Harrison' },
      { name: 'Henry', code: 'Henry' },
      { name: 'Howard', code: 'Howard' },
      { name: 'Humboldt', code: 'Humboldt' },
      { name: 'Ida', code: 'Ida' },
      { name: 'Iowa', code: 'Iowa' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jasper', code: 'Jasper' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Johnson', code: 'Johnson' },
      { name: 'Jones', code: 'Jones' },
      { name: 'Keokuk', code: 'Keokuk' },
      { name: 'Kossuth', code: 'Kossuth' },
      { name: 'Lee', code: 'Lee' },
      { name: 'Linn', code: 'Linn' },
      { name: 'Louisa', code: 'Louisa' },
      { name: 'Lucas', code: 'Lucas' },
      { name: 'Lyon', code: 'Lyon' },
      { name: 'Madison', code: 'Madison' },
      { name: 'Mahaska', code: 'Mahaska' },
      { name: 'Marion', code: 'Marion' },
      { name: 'Marshall', code: 'Marshall' },
      { name: 'Mills', code: 'Mills' },
      { name: 'Mitchell', code: 'Mitchell' },
      { name: 'Monona', code: 'Monona' },
      { name: 'Monroe', code: 'Monroe' },
      { name: 'Montgomery', code: 'Montgomery' },
      { name: 'Muscatine', code: 'Muscatine' },
      { name: `O'Brien`, code: "O'Brien" },
      { name: 'Osceola', code: 'Osceola' },
      { name: 'Page', code: 'Page' },
      { name: 'Palo Alto', code: 'Palo Alto' },
      { name: 'Plymouth', code: 'Plymouth' },
      { name: 'Pocahontas', code: 'Pocahontas' },
      { name: 'Polk', code: 'Polk' },
      { name: 'Pottawattamie', code: 'Pottawattamie' },
      { name: 'Poweshiek', code: 'Poweshiek' },
      { name: 'Ringgold', code: 'Ringgold' },
      { name: 'Sac', code: 'Sac' },
      { name: 'Scott', code: 'Scott' },
      { name: 'Shelby', code: 'Shelby' },
      { name: 'Sioux', code: 'Sioux' },
      { name: 'Story', code: 'Story' },
      { name: 'Tama', code: 'Tama' },
      { name: 'Taylor', code: 'Taylor' },
      { name: 'Union', code: 'Union' },
      { name: 'Van Buren', code: 'Van Buren' },
      { name: 'Wapello', code: 'Wapello' },
      { name: 'Warren', code: 'Warren' },
      { name: 'Washington', code: 'Washington' },
      { name: 'Wayne', code: 'Wayne' },
      { name: 'Webster', code: 'Webster' },
      { name: 'Winnebago', code: 'Winnebago' },
      { name: 'Winneshiek', code: 'Winneshiek' },
      { name: 'Woodbury', code: 'Woodbury' },
      { name: 'Worth', code: 'Worth' },
      { name: 'Wright', code: 'Wright' }
    ],

    AL: [
      { name: 'Autauga', code: 'Autauga' },
      { name: 'Baldwin', code: 'Baldwin' },
      { name: 'Barbour', code: 'Barbour' },
      { name: 'Bibb', code: 'Bibb' },
      { name: 'Blount', code: 'Blount' },
      { name: 'Bullock', code: 'Bullock' },
      { name: 'Butler', code: 'Butler' },
      { name: 'Calhoun', code: 'Calhoun' },
      { name: 'Chambers', code: 'Chambers' },
      { name: 'Cherokee', code: 'Cherokee' },
      { name: 'Chilton', code: 'Chilton' },
      { name: 'Choctaw', code: 'Choctaw' },
      { name: 'Clarke', code: 'Clarke' },
      { name: 'Clay', code: 'Clay' },
      { name: 'Cleburne', code: 'Cleburne' },
      { name: 'Coffee', code: 'Coffee' },
      { name: 'Colbert', code: 'Colbert' },
      { name: 'Conecuh', code: 'Conecuh' },
      { name: 'Coosa', code: 'Coosa' },
      { name: 'Covington', code: 'Covington' },
      { name: 'Crenshaw', code: 'Crenshaw' },
      { name: 'Cullman', code: 'Cullman' },
      { name: 'Dale', code: 'Dale' },
      { name: 'Dallas', code: 'Dallas' },
      { name: 'DeKalb', code: 'DeKalb' },
      { name: 'Elmore', code: 'Elmore' },
      { name: 'Escambia', code: 'Escambia' },
      { name: 'Etowah', code: 'Etowah' },
      { name: 'Fayette', code: 'Fayette' },
      { name: 'Franklin', code: 'Franklin' },
      { name: 'Geneva', code: 'Geneva' },
      { name: 'Greene', code: 'Greene' },
      { name: 'Hale', code: 'Hale' },
      { name: 'Henry', code: 'Henry' },
      { name: 'Houston', code: 'Houston' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Lamar', code: 'Lamar' },
      { name: 'Lauderdale', code: 'Lauderdale' },
      { name: 'Lawrence', code: 'Lawrence' },
      { name: 'Lee', code: 'Lee' },
      { name: 'Limestone', code: 'Limestone' },
      { name: 'Lowndes', code: 'Lowndes' },
      { name: 'Macon', code: 'Macon' },
      { name: 'Madison', code: 'Madison' },
      { name: 'Marengo', code: 'Marengo' },
      { name: 'Marion', code: 'Marion' },
      { name: 'Marshall', code: 'Marshall' },
      { name: 'Mobile', code: 'Mobile' },
      { name: 'Monroe', code: 'Monroe' },
      { name: 'Montgomery', code: 'Montgomery' },
      { name: 'Morgan', code: 'Morgan' },
      { name: 'Perry', code: 'Perry' },
      { name: 'Pickens', code: 'Pickens' },
      { name: 'Pike', code: 'Pike' },
      { name: 'Randolph', code: 'Randolph' },
      { name: 'Russell', code: 'Russell' },
      { name: 'St. Clair', code: 'St. Clair' },
      { name: 'Shelby', code: 'Shelby' },
      { name: 'Sumter', code: 'Sumter' },
      { name: 'Talladega', code: 'Talladega' },
      { name: 'Tallapoosa', code: 'Tallapoosa' },
      { name: 'Tuscaloosa', code: 'Tuscaloosa' },
      { name: 'Walker', code: 'Walker' },
      { name: 'Washington', code: 'Washington' },
      { name: 'Wilcox', code: 'Wilcox' },
      { name: 'Winston', code: 'Winston' }
    ],

    TN: [
      { name: 'Anderson', code: 'Anderson' },
      { name: 'Bedford', code: 'Bedford' },
      { name: 'Benton', code: 'Benton' },
      { name: 'Bledsoe', code: 'Bledsoe' },
      { name: 'Blount', code: 'Blount' },
      { name: 'Bradley', code: 'Bradley' },
      { name: 'Campbell', code: 'Campbell' },
      { name: 'Cannon', code: 'Cannon' },
      { name: 'Carroll', code: 'Carroll' },
      { name: 'Carter', code: 'Carter' },
      { name: 'Cheatham', code: 'Cheatham' },
      { name: 'Chester', code: 'Chester' },
      { name: 'Claiborne', code: 'Claiborne' },
      { name: 'Clay', code: 'Clay' },
      { name: 'Cocke', code: 'Cocke' },
      { name: 'Coffee', code: 'Coffee' },
      { name: 'Crockett', code: 'Crockett' },
      { name: 'Cumberland', code: 'Cumberland' },
      { name: 'Davidson', code: 'Davidson' },
      { name: 'Decatur', code: 'Decatur' },
      { name: 'DeKalb', code: 'DeKalb' },
      { name: 'Dickson', code: 'Dickson' },
      { name: 'Dyer', code: 'Dyer' },
      { name: 'Fayette', code: 'Fayette' },
      { name: 'Fentress', code: 'Fentress' },
      { name: 'Franklin', code: 'Franklin' },
      { name: 'Gibson', code: 'Gibson' },
      { name: 'Giles', code: 'Giles' },
      { name: 'Grainger', code: 'Grainger' },
      { name: 'Greene', code: 'Greene' },
      { name: 'Grundy', code: 'Grundy' },
      { name: 'Hamblen', code: 'Hamblen' },
      { name: 'Hamilton', code: 'Hamilton' },
      { name: 'Hancock', code: 'Hancock' },
      { name: 'Hardeman', code: 'Hardeman' },
      { name: 'Hardin', code: 'Hardin' },
      { name: 'Hawkins', code: 'Hawkins' },
      { name: 'Haywood', code: 'Haywood' },
      { name: 'Henderson', code: 'Henderson' },
      { name: 'Henry', code: 'Henry' },
      { name: 'Hickman', code: 'Hickman' },
      { name: 'Houston', code: 'Houston' },
      { name: 'Humphreys', code: 'Humphreys' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Jefferson', code: 'Jefferson' },
      { name: 'Johnson', code: 'Johnson' },
      { name: 'Knox', code: 'Knox' },
      { name: 'Lake', code: 'Lake' },
      { name: 'Lauderdale', code: 'Lauderdale' },
      { name: 'Lawrence', code: 'Lawrence' },
      { name: 'Lewis', code: 'Lewis' },
      { name: 'Lincoln', code: 'Lincoln' },
      { name: 'Loudon', code: 'Loudon' },
      { name: 'McMinn', code: 'McMinn' },
      { name: 'McNairy', code: 'McNairy' },
      { name: 'Macon', code: 'Macon' },
      { name: 'Madison', code: 'Madison' },
      { name: 'Marion', code: 'Marion' },
      { name: 'Marshall', code: 'Marshall' },
      { name: 'Maury', code: 'Maury' },
      { name: 'Meigs', code: 'Meigs' },
      { name: 'Monroe', code: 'Monroe' },
      { name: 'Montgomery', code: 'Montgomery' },
      { name: 'Moore', code: 'Moore' },
      { name: 'Morgan', code: 'Morgan' },
      { name: 'Obion', code: 'Obion' },
      { name: 'Overton', code: 'Overton' },
      { name: 'Perry', code: 'Perry' },
      { name: 'Pickett', code: 'Pickett' },
      { name: 'Polk', code: 'Polk' },
      { name: 'Putnam', code: 'Putnam' },
      { name: 'Rhea', code: 'Rhea' },
      { name: 'Roane', code: 'Roane' },
      { name: 'Robertson', code: 'Robertson' },
      { name: 'Rutherford', code: 'Rutherford' },
      { name: 'Scott', code: 'Scott' },
      { name: 'Sequatchie', code: 'Sequatchie' },
      { name: 'Sevier', code: 'Sevier' },
      { name: 'Shelby', code: 'Shelby' },
      { name: 'Smith', code: 'Smith' },
      { name: 'Stewart', code: 'Stewart' },
      { name: 'Sullivan', code: 'Sullivan' },
      { name: 'Sumner', code: 'Sumner' },
      { name: 'Tipton', code: 'Tipton' },
      { name: 'Trousdale', code: 'Trousdale' },
      { name: 'Unicoi', code: 'Unicoi' },
      { name: 'Union', code: 'Union' },
      { name: 'Van Buren', code: 'Van Buren' },
      { name: 'Warren', code: 'Warren' },
      { name: 'Washington', code: 'Washington' },
      { name: 'Wayne', code: 'Wayne' },
      { name: 'Weakley', code: 'Weakley' },
      { name: 'White', code: 'White' },
      { name: 'Williamson', code: 'Williamson' },
      { name: 'Wilson', code: 'Wilson' }
    ],
    MI: [
      { name: 'Alcona', code: 'Alcona' },
      { name: 'Alger', code: 'Alger' },
      { name: 'Allegan', code: 'Allegan' },
      { name: 'Alpena', code: 'Alpena' },
      { name: 'Antrim', code: 'Antrim' },
      { name: 'Arenac', code: 'Arenac' },
      { name: 'Baraga', code: 'Baraga' },
      { name: 'Barry', code: 'Barry' },
      { name: 'Bay', code: 'Bay' },
      { name: 'Benzie', code: 'Benzie' },
      { name: 'Berrien', code: 'Berrien' },
      { name: 'Branch', code: 'Branch' },
      { name: 'Calhoun', code: 'Calhoun' },
      { name: 'Cass', code: 'Cass' },
      { name: 'Charlevoix', code: 'Charlevoix' },
      { name: 'Cheboygan', code: 'Cheboygan' },
      { name: 'Chippewa', code: 'Chippewa' },
      { name: 'Clare', code: 'Clare' },
      { name: 'Clinton', code: 'Clinton' },
      { name: 'Crawford', code: 'Crawford' },
      { name: 'Delta', code: 'Delta' },
      { name: 'Dickinson', code: 'Dickinson' },
      { name: 'Eaton', code: 'Eaton' },
      { name: 'Emmet', code: 'Emmet' },
      { name: 'Genesee', code: 'Genesee' },
      { name: 'Gladwin', code: 'Gladwin' },
      { name: 'Gogebic', code: 'Gogebic' },
      { name: 'Grand Traverse', code: 'Grand Traverse' },
      { name: 'Gratiot', code: 'Gratiot' },
      { name: 'Hillsdale', code: 'Hillsdale' },
      { name: 'Houghton', code: 'Houghton' },
      { name: 'Huron', code: 'Huron' },
      { name: 'Ingham', code: 'Ingham' },
      { name: 'Ionia', code: 'Ionia' },
      { name: 'Iosco', code: 'Iosco' },
      { name: 'Iron', code: 'Iron' },
      { name: 'Isabella', code: 'Isabella' },
      { name: 'Jackson', code: 'Jackson' },
      { name: 'Kalamazoo', code: 'Kalamazoo' },
      { name: 'Kalkaska', code: 'Kalkaska' },
      { name: 'Kent', code: 'Kent' },
      { name: 'Keweenaw', code: 'Keweenaw' },
      { name: 'Lake', code: 'Lake' },
      { name: 'Lapeer', code: 'Lapeer' },
      { name: 'Leelanau', code: 'Leelanau' },
      { name: 'Lenawee', code: 'Lenawee' },
      { name: 'Livingston', code: 'Livingston' },
      { name: 'Luce', code: 'Luce' },
      { name: 'Mackinac', code: 'Mackinac' },
      { name: 'Macomb', code: 'Macomb' },
      { name: 'Manistee', code: 'Manistee' },
      { name: 'Marquette', code: 'Marquette' },
      { name: 'Mason', code: 'Mason' },
      { name: 'Mecosta', code: 'Mecosta' },
      { name: 'Menominee', code: 'Menominee' },
      { name: 'Midland', code: 'Midland' },
      { name: 'Missaukee', code: 'Missaukee' },
      { name: 'Monroe', code: 'Monroe' },
      { name: 'Montcalm', code: 'Montcalm' },
      { name: 'Montmorency', code: 'Montmorency' },
      { name: 'Muskegon', code: 'Muskegon' },
      { name: 'Newaygo', code: 'Newaygo' },
      { name: 'Oakland', code: 'Oakland' },
      { name: 'Oceana', code: 'Oceana' },
      { name: 'Ogemaw', code: 'Ogemaw' },
      { name: 'Ontonagon', code: 'Ontonagon' },
      { name: 'Osceola', code: 'Osceola' },
      { name: 'Oscoda', code: 'Oscoda' },
      { name: 'Otsego', code: 'Otsego' },
      { name: 'Ottawa', code: 'Ottawa' },
      { name: 'Presque Isle', code: 'Presque Isle' },
      { name: 'Roscommon', code: 'Roscommon' },
      { name: 'Saginaw', code: 'Saginaw' },
      { name: 'St. Clair', code: 'St. Clair' },
      { name: 'St. Joseph', code: 'St. Joseph' },
      { name: 'Sanilac', code: 'Sanilac' },
      { name: 'Schoolcraft', code: 'Schoolcraft' },
      { name: 'Shiawassee', code: 'Shiawassee' },
      { name: 'Tuscola', code: 'Tuscola' },
      { name: 'Van Buren', code: 'Van Buren' },
      { name: 'Washtenaw', code: 'Washtenaw' },
      { name: 'Wayne', code: 'Wayne' },
      { name: 'Wexford', code: 'Wexford' }
    ],
    
   
    
    
    
    
    
    
  };
  
  export default COUNTIES;
  