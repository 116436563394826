// src/components/UserInsightsModal.js

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons'; // Icon representing insights
import UserNotes from './UserNotes';

const UserInsightsModal = () => {
  // State for managing the modal visibility
  const [isUserInsightsModalVisible, setIsUserInsightsModalVisible] = useState(false);

  // Handlers for opening and closing the modal
  const closeUserInsightsModal = () => setIsUserInsightsModalVisible(false);
  const openUserInsightsModal = () => setIsUserInsightsModalVisible(true);

  return (
    <>
      {/* Trigger Element */}
      <div className="manager-square" onClick={openUserInsightsModal}>
        <FontAwesomeIcon icon={faChartLine} className="manager-icon" />
        <div className="manager-text">View Insights</div>
      </div>

      {/* Modal */}
      <Modal
        show={isUserInsightsModalVisible}
        onHide={closeUserInsightsModal}
        size="xl"
        aria-labelledby="user-insights-modal"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="user-insights-modal">User Insights - Property Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
         <UserNotes />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeUserInsightsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserInsightsModal;
