// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import registrationReducer from './registrationSlice';
import favoritesReducer from './favoritesSlice';
import filterReducer from './filterSlice';


const store = configureStore({
  reducer: {
    user: userReducer,
    registration: registrationReducer,
    favorites: favoritesReducer,
    filter: filterReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable if dealing with non-serializable data
    }),
});

export default store;
