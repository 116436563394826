// src/components/PropertyDetailsModal.js

import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button, Dropdown, Spinner, Form } from 'react-bootstrap';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRegistered, faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';

import getCoordinates from './getCoordinates';
import { propertiesAPI } from './PropertiesAPI';
import PropertyNotes from './PropertyNotes';
import './PropertyDetailsModal.css'; // Import the CSS file

// Helper Functions and Mappings

const saleTypeMapping = {
  "foreclosure": "F",
  "deed": "D",
  "deed sale": "D",
  "tax sale": "A",
  "tax": "A",
  "certificate": "C",
  "blighted": "B",
  "tax lien sale": "O",
  "tax lien": "O",
  "adjudicated sale": "J",
  "adjudicated": "J",
  "redemption": "R"
};

const getSaleTypeDescription = (saleType) => {
  if (!saleType) return 'Unknown';
  const normalized = saleType.toLowerCase().trim();
  return saleTypeMapping[normalized] || 'Unknown';
};

const stateNameToAbbreviation = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY",
};

const timeZoneToIana = {
  AL: "America/Chicago",
  AK: "America/Anchorage",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  FL: "America/New_York",
  GA: "America/New_York",
  HI: "Pacific/Honolulu",
  ID: "America/Boise",
  IL: "America/Chicago",
  IN: "America/Indiana/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/New_York",
  LA: "America/Chicago",
  ME: "America/New_York",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/Detroit",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PA: "America/New_York",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
};

const indianaCountyToTimeZone = {
  "Central": [
    "Gibson", "Jasper", "Lake", "LaPorte", "Newton", "Porter",
    "Posey", "Spencer", "Vanderburgh", "Warrick"
  ],
  "Eastern": [
    "Adams", "Allen", "Bartholomew", "Benton", "Blackford", "Boone",
    "Brown", "Carroll", "Cass", "Clark", "Clay", "Clinton",
    "Crawford", "Daviess", "Dearborn", "Decatur", "DeKalb",
    "Delaware", "Dubois", "Elkhart", "Fayette", "Floyd", "Fountain",
    "Franklin", "Fulton", "Grant", "Greene", "Hamilton", "Hancock",
    "Harrison", "Hendricks", "Henry", "Howard", "Huntington",
    "Jackson", "Jay", "Jefferson", "Jennings", "Johnson", "Knox",
    "Kosciusko", "Lagrange", "Lawrence", "Madison", "Marion",
    "Marshall", "Martin", "Miami", "Monroe", "Montgomery", "Morgan",
    "Noble", "Ohio", "Orange", "Owen", "Parke", "Perry", "Pike",
    "Pulaski", "Putnam", "Randolph", "Ripley", "Rush", "Scott",
    "Shelby", "St. Joseph", "Starke", "Steuben", "Sullivan",
    "Switzerland", "Tippecanoe", "Tipton", "Union", "Vermillion",
    "Vigo", "Wabash", "Warren", "Washington", "Wayne", "Wells",
    "White", "Whitley"
  ]
};

/**
 * Interprets `auctionDate` as if it’s local time in the county,
 * then returns:
 *  - saleLocalTime (the county’s local time in mm/dd/yyyy, hh:mm AM/PM)
 *  - userLocalTime (the user’s local machine time in mm/dd/yyyy, hh:mm AM/PM)
 */
const processAuctionDate = (auctionDate, state, county) => {
  if (!auctionDate || !state) {
    console.warn("Missing auctionDate or state:", auctionDate, state);
    return {
      saleLocalTime: 'Invalid Date/Time',
      userLocalTime: 'Invalid Date/Time'
    };
  }

  const stateAbbr = stateNameToAbbreviation[state] || state.toUpperCase();
  let ianaTimeZone = "UTC";

  if (stateAbbr === "IN") {
    if (county) {
      const isCentral = indianaCountyToTimeZone.Central.some(
        c => c.toLowerCase() === county.toLowerCase()
      );
      ianaTimeZone = isCentral ? "America/Chicago" : "America/New_York";
    } else {
      ianaTimeZone = "America/New_York";
    }
  } else {
    ianaTimeZone = timeZoneToIana[stateAbbr] || "UTC";
  }

  let dateObj;
  try {
    dateObj = new Date(auctionDate);
    if (isNaN(dateObj)) throw new Error("Invalid Date");
  } catch (err) {
    console.error("Error parsing auctionDate:", err);
    return {
      saleLocalTime: 'Invalid Date/Time',
      userLocalTime: 'Invalid Date/Time'
    };
  }

  let saleLocalTime;
  try {
    saleLocalTime = dateObj.toLocaleString('en-US', {
      timeZone: ianaTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  } catch (err) {
    console.error("Error formatting saleLocalTime:", err);
    saleLocalTime = 'Invalid Date/Time';
  }

  let userLocalTime;
  try {
    userLocalTime = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  } catch (err) {
    console.error("Error formatting userLocalTime:", err);
    userLocalTime = 'Invalid Date/Time';
  }

  return {
    saleLocalTime,
    userLocalTime
  };
};

const handleDocumentDownload = async (documentId, documentName, storedFileName) => {
  try {
    await propertiesAPI.downloadDocument(documentId, documentName, storedFileName);
  } catch (error) {
    console.error(`Error downloading document with ID ${documentId}:`, error);
    alert('Failed to download the document. Please try again later.');
  }
};

/**
 * Modal component displaying detailed information about a property.
 */
const PropertyDetailsModal = ({
  showModal,
  setShowModal,
  selectedProperty,
  propertyDetails,
  detailsResponse,
  propertyDocuments = [],
  setPropertyDocuments,
  showDirections,
  directions,
  handleGetDirections,
  isPropertyRegistered,
  getRegisterButtonLabel,
  handleRegisterOrUnregisterClick,
  isPropertyFavorite,
  getFavoriteButtonLabel,
  handleFavoriteClick
}) => {
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [minimumBid, setMinimumBid] = useState('N/A');
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(true);
  const [saleLocalTime, setSaleLocalTime] = useState('Invalid Date/Time');
  const [userLocalTime, setUserLocalTime] = useState('Invalid Date/Time');
  const [showSaleTimeToggle, setShowSaleTimeToggle] = useState(true); // true = sale local time, false = user local time

  console.log('PropertyDetailsModal:', selectedProperty, propertyDetails, detailsResponse);

  /**
   * Fetches or geocodes the property coordinates.
   */
  const fetchCoordinates = useCallback(async () => {
    if (!selectedProperty) return;

    let coords = {
      lat: selectedProperty.latitude || null,
      lng: selectedProperty.longitude || null
    };

    if (!selectedProperty.latitude || !selectedProperty.longitude) {
      const address = `${selectedProperty.address1}, ${selectedProperty.city}, ${selectedProperty.state} ${selectedProperty.zip}`;
      coords = await getCoordinates(address);
    }

    setCoordinates(coords);
    setIsLoadingMap(false);
    setShowMap(true);
  }, [selectedProperty]);

  /**
   * Effect to fetch data when the modal is shown and a property is selected.
   */
  useEffect(() => {
    if (showModal && selectedProperty) {
      setIsFetchingDocuments(true);
      fetchCoordinates();
      setMinimumBid(propertyDetails?.minimumBid || 'N/A');

      // Process auction date and time.
      // We assume selectedProperty.date is used for the separate date field,
      // and selectedProperty.time is combined with date to form an ISO string.
      if (selectedProperty.date && selectedProperty.time && selectedProperty.state && selectedProperty.county) {
        const [monthStr, dayStr, yearStr] = selectedProperty.date.split('/');
        if (monthStr && dayStr && yearStr) {
          const month = monthStr.padStart(2, '0');
          const day = dayStr.padStart(2, '0');
          const year = yearStr;
          const [timePart, meridian] = selectedProperty.time.split(' ');
          if (timePart && meridian) {
            let [hours, minutes] = timePart.split(':');
            hours = parseInt(hours, 10);
            minutes = parseInt(minutes, 10) || 0;
            if (meridian.toUpperCase() === 'PM' && hours !== 12) {
              hours += 12;
            } else if (meridian.toUpperCase() === 'AM' && hours === 12) {
              hours = 0;
            }
            const isoDateTime = `${year}-${month}-${day}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;

            const { saleLocalTime: sLocal, userLocalTime: uLocal } = processAuctionDate(
              isoDateTime,
              selectedProperty.state,
              selectedProperty.county
            );

            // Remove the date portion (assumes format "mm/dd/yyyy, hh:mm AM/PM") and use only the time.
            const timeOnlySLocal = sLocal.includes(',') ? sLocal.split(',')[1].trim() : sLocal;
            const timeOnlyULocal = uLocal.includes(',') ? uLocal.split(',')[1].trim() : uLocal;

            setSaleLocalTime(timeOnlySLocal);
            setUserLocalTime(timeOnlyULocal);
          } else {
            setSaleLocalTime('Invalid Date/Time');
            setUserLocalTime('Invalid Date/Time');
          }
        } else {
          setSaleLocalTime('Invalid Date/Time');
          setUserLocalTime('Invalid Date/Time');
        }
      } else {
        setSaleLocalTime('Invalid Date/Time');
        setUserLocalTime('Invalid Date/Time');
      }

      // Fetch documents
      const fetchDocs = async () => {
        try {
          const documents = await propertiesAPI.getDocumentPropertyInfoList({
            id: selectedProperty.id,
            propertyId: selectedProperty.propertyId,
            saleId: selectedProperty.saleId,
            saleType: selectedProperty.saleType
          });
          setPropertyDocuments(documents);
        } catch (error) {
          console.error('Error fetching property documents:', error);
        } finally {
          setIsFetchingDocuments(false);
        }
      };
      fetchDocs();
    }
  }, [showModal, selectedProperty, fetchCoordinates, propertyDetails, setPropertyDocuments]);

  const isTaxSale = selectedProperty?.saleTypeDescription === "Tax Sale" ||
                    selectedProperty?.saleTypeDescription === "Adjudicated Sale" ||
                    selectedProperty?.saleTypeDescription === "Tax Lien Sale";

  const isForeclosure = selectedProperty?.saleTypeDescription === "Foreclosure";
  const isOnlineAuction = selectedProperty?.type === "Online";

  const saleId = selectedProperty?.saleId || 'N/A';
  const parcelNumber = selectedProperty?.propertyId || 'N/A';
  const causeNumber = propertyDetails?.causeNumber || 'N/A';
  const defendant = propertyDetails?.defendant || 'N/A';
  const plaintiff = propertyDetails?.plantiff || 'N/A';
  const newspaper = detailsResponse?.countyDetail?.newspaper || 'N/A';

  // --- NEW: Determine the sale location based on auction details ---
  // We assume that the auction detail is available in detailsResponse.auctionDetail
  // and that the property’s date (selectedProperty.date) should match auctionDetail.date.
  const auctionDetail = detailsResponse?.auctionDetail;
  const saleLocation =
    auctionDetail &&
    selectedProperty?.date &&
    auctionDetail.date &&
    selectedProperty.date.trim() === auctionDetail.date.trim() &&
    auctionDetail.location &&
    auctionDetail.location.trim() !== ''
      ? auctionDetail.location
      : (selectedProperty?.location || 'N/A');
  // ------------------------------------------------------------------

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Property Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedProperty && (
          <>
            {/* Property Address and Status */}
            <h4 className="property-address">{selectedProperty.address1}</h4>
            {selectedProperty.saleStatusDescription && (
              <div className="property-field">
                <span className="property-label">Status:</span>
                <span className="property-value">{selectedProperty.saleStatusDescription}</span>
              </div>
            )}

            {/* General Information */}
            <div className="property-details-container">
              {isTaxSale ? (
                <>
                  <div className="property-field">
                    <span className="property-label">Sale ID:</span>
                    <span className="property-value">{saleId}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Parcel #:</span>
                    <span className="property-value">{parcelNumber}</span>
                  </div>
                </>
              ) : (
                <div className="property-field">
                  <span className="property-label">Cause #:</span>
                  <span className="property-value">{causeNumber}</span>
                </div>
              )}

              {/* Date */}
              <div className="property-field">
                <span className="property-label">Date:</span>
                <span className="property-value">{selectedProperty.date || 'N/A'}</span>
              </div>

              {/* Sale Time */}
              <div className="property-field">
                <span className="property-label">Sale Time:</span>
                <span className="property-value">
                  {showSaleTimeToggle ? saleLocalTime : userLocalTime}
                </span>
              </div>

              {/* Toggle Switch */}
              <Form.Check
                type="switch"
                id="local-time-switch"
                label={`Show ${showSaleTimeToggle ? 'User Local Time' : 'Sale Local Time (County)'}`}
                checked={showSaleTimeToggle}
                onChange={() => setShowSaleTimeToggle(!showSaleTimeToggle)}
                className="time-toggle-switch"
              />

              {/* Additional Information */}
              {isTaxSale ? (
                <>
                  <div className="property-field">
                    <span className="property-label">Property ID:</span>
                    <span className="property-value">{selectedProperty.propertyId || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Owner Name:</span>
                    <span className="property-value">{detailsResponse?.propertyCardDetail?.ownerName1 || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Brief Legal:</span>
                    <span className="property-value">{selectedProperty.briefLegal || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Address:</span>
                    <span className="property-value">
                      {`${selectedProperty.address1}, ${selectedProperty.city}, ${selectedProperty.state} ${selectedProperty.zip}`}
                    </span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Min. Bid:</span>
                    <span className="property-value">{minimumBid}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Type:</span>
                    <span className="property-value">{selectedProperty.type || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Location:</span>
                    {/* Use the derived saleLocation here */}
                    <span className="property-value">{saleLocation}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="property-field">
                    <span className="property-label">Defendant:</span>
                    <span className="property-value">{defendant}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Plaintiff:</span>
                    <span className="property-value">{plaintiff}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Attorney:</span>
                    <span className="property-value">{propertyDetails?.attorney || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Judgment Amount:</span>
                    <span className="property-value">{propertyDetails?.judgementAmount || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Min. Bid:</span>
                    <span className="property-value">{minimumBid}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Type:</span>
                    <span className="property-value">{selectedProperty.type || 'N/A'}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Sale Location Address:</span>
                    {/* Use the derived saleLocation here as well */}
                    <span className="property-value">{saleLocation}</span>
                  </div>
                  <div className="property-field">
                    <span className="property-label">Property Address:</span>
                    <span className="property-value">
                      {`${selectedProperty.address1}, ${selectedProperty.city}, ${selectedProperty.state} ${selectedProperty.zip}`}
                    </span>
                  </div>
                </>
              )}
            </div>

            {/* Property Notes */}
            <div className="property-notes-section">
              {selectedProperty.propertyId && (
                <PropertyNotes propertyId={selectedProperty.propertyId} />
              )}
            </div>

            {/* County Information */}
            <div className="county-section">
              <h4>{detailsResponse?.countyDetail?.countyName} County</h4>
              <div className="property-field">
                <span className="property-label">County Address:</span>
                <span className="property-value">{detailsResponse?.countyDetail?.address || 'N/A'}</span>
              </div>
              <div className="property-field">
                <span className="property-label">Newspaper of Record:</span>
                <span className="property-value">{newspaper || 'N/A'}</span>
              </div>
              <div className="property-field">
                <span className="property-label">SRI Contact:</span>
                <span className="property-value">1-800-800-9588</span>
              </div>
              <div className="property-field">
                <span className="property-label">Tax Sale Support:</span>
                <span className="property-value">support@sriservices.com</span>
              </div>
              <div className="property-field">
                <span className="property-label">Sheriff Sale Support:</span>
                <span className="property-value">sheriffsalesupport@sriservices.com</span>
              </div>
            </div>

            {/* Property Documents */}
            <div className="documents-section">
              {isFetchingDocuments ? (
                <div className="documents-loading">
                  <Spinner animation="border" role="status" className="documents-spinner">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <strong>Documents are being fetched...</strong>
                </div>
              ) : (
                ['General', 'Sale', 'State', 'County', 'Property'].map(category => {
                  const docs = Array.isArray(propertyDocuments)
                    ? propertyDocuments.filter(doc =>
                        doc.uploadedFileName &&
                        doc.category.includes(category) &&
                        (!doc.expDate || new Date(doc.expDate) > new Date())
                      )
                    : [];
                  return docs.length > 0 && (
                    <div key={category} className="document-category">
                      <Dropdown bsPrefix="dropdown" container={document.body}>
                        <Dropdown.Toggle 
                          variant="success" 
                          id={`dropdown-${category}`} 
                          className="btn btn-sm custom-dropdown-download-button"
                        >
                          {`${category} Documents`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu 
                          style={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                          }}
                        >
                          {docs.map((doc, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleDocumentDownload(doc.documentId, doc.uploadedFileName, doc.storedFileName)}
                            >
                              {doc.uploadedFileName}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  );
                })
              )}
            </div>

            {/* Map Section */}
            {showMap && (
              <div className="modal-map-container">
                {isLoadingMap ? (
                  <p>Loading map...</p>
                ) : coordinates.lat && coordinates.lng ? (
                  <GoogleMap
                    mapContainerClassName="modal-map-google-map"
                    center={{ lat: coordinates.lat, lng: coordinates.lng }}
                    zoom={15}
                  >
                    <Marker position={{ lat: coordinates.lat, lng: coordinates.lng }} />
                    {showDirections && <DirectionsRenderer directions={directions} />}
                  </GoogleMap>
                ) : (
                  <p>Coordinates not available</p>
                )}
                <Button
                  className="get-directions-button"
                  variant="primary"
                  size="sm"
                  onClick={() => handleGetDirections(selectedProperty)}
                >
                  Get Directions
                </Button>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        
        {!isOnlineAuction && (
          <Button
            variant={isPropertyRegistered(selectedProperty) ? 'secondary' : 'outline-secondary'}
            onClick={() => handleRegisterOrUnregisterClick({ saleId: selectedProperty.saleId, auction: selectedProperty })}
          >
            <FontAwesomeIcon icon={faRegistered} /> {getRegisterButtonLabel(selectedProperty)}
          </Button>
        )}

        <Button
          variant={isPropertyFavorite(selectedProperty) ? 'success' : 'outline-success'}
          onClick={() => handleFavoriteClick(selectedProperty)}
        >
          <FontAwesomeIcon icon={isPropertyFavorite(selectedProperty) ? solidHeart : regularHeart} /> {getFavoriteButtonLabel(selectedProperty)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PropertyDetailsModal;
