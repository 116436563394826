// src/components/SaleRegistrationsComponent.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  InputGroup,
  Dropdown,
  Table,
  Modal,
  Pagination,
  Spinner,
  Alert,
} from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectTokens } from '../redux/userSlice';
import LoadingAnimation from './LoadingAnimation'; // Ensure this component exists
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faPlus,
  faTrashAlt,
  faUserPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import SaleRegistrationModal from './SaleRegistrationModal'; // Updated to use the modal component
import './SaleRegistrationsComponent.css'; // Import the new CSS file

const SaleRegistrationsComponent = () => {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [saleId, setSaleId] = useState('');
  const [saleDate, setSaleDate] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [salesPerPage] = useState(5);
  const tokens = useSelector(selectTokens);
  const baseUrl = process.env.REACT_APP_SERVER_URL;
  const apiKey = process.env.REACT_APP_SERVER_API_KEY;

  // Fetch all sales on component mount
  useEffect(() => {
    fetchAllSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch users based on searchText
  useEffect(() => {
    if (searchText.length >= 2) {
      const fetchUsers = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(
            `${baseUrl}/app/messages/users?search=${searchText}`,
            {
              headers: {
                Authorization: `Bearer ${tokens.accessToken}`,
                'x-api-key': apiKey,
              },
            }
          );
          setUsers(response.data || []);
          setShowDropdown(response.data && response.data.length > 0);
        } catch (error) {
          console.error('Error fetching users:', error);
          setShowDropdown(false);
        } finally {
          setIsLoading(false);
        }
      };

      fetchUsers();
    } else {
      setUsers([]);
      setShowDropdown(false);
    }
  }, [searchText, baseUrl, tokens.accessToken, apiKey]);

  // Fetch all sales
  const fetchAllSales = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/sales`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
          'x-api-key': apiKey,
        },
      });
      setSales(response.data);
      setFilteredSales(response.data);
    } catch (error) {
      console.error('Error fetching sales:', error);
      alert('Failed to fetch sales. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  // Select a user from dropdown
  const selectUser = (user) => {
    setSelectedUser(user);
    setShowDropdown(false);
    setSearchText('');
  };

  // Handle registration
  const handleRegister = async () => {
    if (!selectedUser || !saleId) {
      alert('All fields are required.');
      return;
    }

    setIsLoading(true);

    try {
      // Extract only the date part
      const formattedSaleDate = saleDate.split('T')[0];

      const requestBody = {
        userId: selectedUser.fusionauth_user_id,
        saleId,
        sale_date: formattedSaleDate,
      };

      console.log('Register request payload:', requestBody);

      const response = await axios.post(
        `${baseUrl}/sales/sales/register`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
            'x-api-key': apiKey,
          },
        }
      );

      if (response.status === 201) {
        fetchAllSales();
        handleCloseModal();
        // Set the filter by sale date
        setSaleDate(formattedSaleDate);
        alert('User successfully registered for the sale.');
      }
    } catch (error) {
      console.error('Error registering for sale:', error);
      alert('Failed to register for the sale. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle unregistration
  const handleUnregister = async (userId, saleId) => {
    if (!window.confirm('Are you sure you want to unregister this user from the sale?')) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.delete(
        `${baseUrl}/sales/user/${userId}/sales/${saleId}/unregister`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
            'x-api-key': apiKey,
          },
        }
      );

      if (response.status === 200) {
        fetchAllSales();
        alert('User successfully unregistered from the sale.');
      }
    } catch (error) {
      console.error('Error unregistering from sale:', error);
      alert('Failed to unregister from the sale. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Show modal for adding registration
  const handleShowModal = (sale) => {
    setSelectedSale(sale);
    setSaleId(sale.sale_id);
    setSaleDate(sale.sale_date.split('T')[0]); // Extract only the date part
    setShowModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setSearchText('');
  };

  // Select a sale from dropdown
  const handleSelectSale = (sale) => {
    setSaleId(sale.sale_id);
    setSaleDate(sale.sale_date.split('T')[0]);
    setCurrentPage(1);
    setFilteredSales(sales.filter((s) => s.sale_id === sale.sale_id));
  };

  // Clear sale filter
  const handleClearFilter = () => {
    setSaleId('');
    setSaleDate('');
    setCurrentPage(1);
    setFilteredSales(sales);
  };

  // Filter sales by sale date
  useEffect(() => {
    if (saleDate) {
      setFilteredSales(
        sales.filter((s) => s.sale_date.split('T')[0] === saleDate)
      );
    } else {
      setFilteredSales(sales);
    }
  }, [saleDate, sales]);

  // Pagination logic
  const indexOfLastSale = currentPage * salesPerPage;
  const indexOfFirstSale = indexOfLastSale - salesPerPage;
  const currentSales = filteredSales.slice(indexOfFirstSale, indexOfLastSale);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="sale-reg-container">
      {isLoading && <LoadingAnimation />}
      <div className="sale-reg-header">
        <h2>Manage Sale Registrations</h2>
      </div>
      <div className="sale-reg-filters">
        <Dropdown className="sale-reg-dropdown">
          <Dropdown.Toggle variant="primary" id="dropdown-basic" className="sale-reg-dropdown-toggle">
            <FontAwesomeIcon icon={faFilter} className="sale-reg-icon" />{' '}
            {saleDate ? `Sale Date: ${saleDate}` : 'Filter by Sale Date'}
          </Dropdown.Toggle>

          <Dropdown.Menu className="sale-reg-dropdown-menu">
            {sales.map((sale) => (
              <Dropdown.Item
                key={sale.sale_id}
                onClick={() => handleSelectSale(sale)}
              >
                {sale.sale_date.split('T')[0]} - Sale ID: {sale.sale_id}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleClearFilter}>
              <FontAwesomeIcon icon={faTimes} className="sale-reg-icon" /> Clear Filter
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="sale-reg-table-responsive">
        <Table striped bordered hover className="sale-reg-table">
          <thead>
            <tr>
              <th>Sale ID</th>
              <th>Sale Date</th>
              <th>Registrations</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentSales.map((sale) => (
              <tr key={sale.sale_id}>
                <td>{sale.sale_id}</td>
                <td>{sale.sale_date.split('T')[0]}</td>
                <td>
                  {sale.registrations.length > 0 ? (
                    <ul className="sale-reg-user-list">
                      {sale.registrations.map((reg) => (
                        <li key={reg.user_id} className="sale-reg-user-item">
                          <span className="sale-reg-user-email">
                            {reg.email} (Reg. No: {reg.registration_number})
                          </span>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleUnregister(reg.user_id, sale.sale_id)}
                            className="sale-reg-unregister-btn"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} /> Unregister
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span>No Registrations</span>
                  )}
                </td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => handleShowModal(sale)}
                    className="sale-reg-add-btn"
                  >
                    <FontAwesomeIcon icon={faUserPlus} /> Add Registration
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {filteredSales.length > salesPerPage && (
        <Pagination className="sale-reg-pagination">
          {Array.from({ length: Math.ceil(filteredSales.length / salesPerPage) }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => paginate(i + 1)}
              className="sale-reg-pagination-item"
            >
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      )}

      {/* Registration Modal */}
      <SaleRegistrationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        saleId={saleId}
        saleDate={saleDate}
        selectedUser={selectedUser}
        searchText={searchText}
        handleSearchChange={handleSearchChange}
        users={users}
        showDropdown={showDropdown}
        selectUser={selectUser}
        handleRegister={handleRegister}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SaleRegistrationsComponent;
