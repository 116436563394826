import React from "react";
import image1 from "../images/sri-how-we-work-header.png";
import image2 from "../images/sri-government-partners.png";
import image3 from "../images/sri-what-we-value-overlay.png";
import styles from "../css/HowWeWorkPage.module.css";

import { Link } from 'react-router-dom';



function HowWeWork() {
    return (
        <div>
            <div className={styles.headerContainer} style={{backgroundImage: `url(${image1})`}}>
                <h1 className={styles.headerTitle}>How We Work</h1>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.leftSide}>
                    <h4 className={styles.leftSideTitle}>
                        Looking to stay in the loop on upcoming auctions, sales, and manage your favorite properties?
                    </h4>
                </div>
                <div className={styles.rightSide}>
                    <p>
                    Delinquent tax collections, tax lien sales, foreclosures, and auctions all have specific details that must be given adequate attention—otherwise, you can end up with a financial mess on your hands. Whether you’re a government official, investor, or buyer, our method of managing these complex processes is designed to ensure you are comfortable—and set up for success—from start to finish.
                    </p>
                </div>
            </div>

            <div className={styles.governmentContainer} style={{backgroundImage: `url(${image2})`}}>
                <h1 className={styles.whiteText}>Government Partners</h1>
                <h5 className={styles.whiteSubtitle}>LET US TAKE CARE OF THE LOGISTICS</h5>
            </div>

            <div className={styles.valueContainer} style={{backgroundImage: `url(${image3})`}}>
                <div className={styles.valueLeftSide}>
                    <h4>WHAT WE VALUE</h4>
                    <p>
                    Relationships are the heart of our business. Our success is driven by our dedication to our clients’ needs, maintaining steady communication throughout all processes, and building lasting partnerships rooted in trust.
                    </p>
                </div>

                <div className={styles.valueRightSide}>
                    <h2>Learn more about our history and who we are.</h2>
                    <Link to="/company" className={`btn rounded-pill ${styles.btn}`}>
                        MEET THE TEAM
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HowWeWork;