// src/components/AppItem.js

import React from 'react';
import PropTypes from 'prop-types';
import sri_logo from '../images/sri_logo_only.png';
import tsm_logo from '../images/TSM_LOGO.png';
import lap_logo from '../images/LAP_LOGO.png';
import fut_logo from '../images/FUT_LOGO.png';
import noticing_sytem from '../images/noticing_system.png';
import './AppItem.css'; // <--- Unique CSS file

const AppItem = ({ name, url, onAppSelect }) => {
  const getAppLogo = (appName) => {
    switch (appName.toLowerCase()) {
      case 'taxsale management system':
        return tsm_logo;
      case 'lien assist program':
        return lap_logo;
      case 'noticing':
        return noticing_sytem;
      case 'fut':
        return fut_logo;
      default:
        return sri_logo;
    }
  };

  const appLogo = getAppLogo(name);

  const handleClick = (e) => {
    e.preventDefault();
    onAppSelect(url);
  };

  return (
    <div className="my-app-item-container">
      <a href={url} onClick={handleClick} className="my-app-item-link">
        <img
          src={appLogo}
          alt={`${name} Logo`}
          className="my-app-item-logo"
        />
      </a>
    </div>
  );
};

AppItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onAppSelect: PropTypes.func.isRequired,
};

export default AppItem;
