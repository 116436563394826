import React, { useState, useEffect } from 'react';
import { Button, Form, Card, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const PropertyNotes = ({
  propertyId,
  state: propertyState, // Renamed to avoid conflict with React state
  county,
  saleTypeDescription,
  address1,
}) => {
  const userId = useSelector((state) => state.user.user?.fusionauth_user_id);
  const [notes, setNotes] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [noteId, setNoteId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const baseUrl = `${process.env.REACT_APP_SERVER_URL}/sales`;
  const apiKey = process.env.REACT_APP_SERVER_API_KEY;


  // Function to fetch notes for the current property
  const fetchNotes = () => {
    if (userId && propertyId) {
      fetch(`${baseUrl}/notes/fetch`, {
        method: 'POST', // Changed from GET to POST
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify({ propertyId }), // Send propertyId in the request body
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Fetched notes:', data);
          if (Array.isArray(data.notes)) {
            setNotes(data.notes);
          } else {
            setNotes([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching notes:', error);
          setError('Failed to fetch notes');
        });
    }
  };


  

  // Fetch notes when the component mounts or propertyId/userId changes
  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, propertyId]);

  // Save or update the note to the backend
  const saveNote = () => {
    const method = noteId ? 'PUT' : 'POST';
    const url = `${baseUrl}/notes${noteId ? `?noteId=${encodeURIComponent(noteId)}` : ''}`;
  
    // Prepare the request body
    const body = {
      userId,
      noteContent,
      address1,
      county,
      saleTypeDescription,
      propertyState,
    };
  
    // Include propertyId for new note creation
    if (!noteId) {
      body.propertyId = propertyId;
    }
  
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(() => {
        fetchNotes();
        setIsEditing(false);
        setNoteContent('');
        setNoteId(null);
      })
      .catch((error) => {
        console.error('Error saving note:', error);
        setError('Failed to save note');
      });
  };
  

  // Cancel editing the note
  const cancelEditing = () => {
    setIsEditing(false);
    setNoteContent('');
    setNoteId(null);
  };

  // Delete the note from the backend
  const deleteNote = (id) => {
    fetch(`${baseUrl}/notes?noteId=${encodeURIComponent(id)}`, {
      method: 'DELETE',
      headers: {
        'x-api-key': apiKey,
      },
    })
      .then((response) => response.json())
      .then(() => {
        fetchNotes();
      })
      .catch((error) => {
        console.error('Error deleting note:', error);
        setError('Failed to delete note');
      });
  };
  

  // Toggle the collapsible component
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Filter notes by the current userId
  const userNotes = Array.isArray(notes) ? notes.filter((note) => note.user_id === userId) : [];

  // If the user is not logged in, don't render the component
  if (!userId) {
    return null;
  }

  return (
    <Card className="mt-2">
      <Card.Header onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            Notes{' '}
            {userNotes.length > 0 && (
              <span style={{ fontWeight: 'bold', color: 'blue' }}>
                ({userNotes.length})
              </span>
            )}
            {isCollapsed && ' - Click to add/view notes'}
          </span>
          {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
        </div>
      </Card.Header>
      {!isCollapsed && (
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {isEditing ? (
            <>
              <Form.Group controlId="propertyNote">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={noteContent}
                  onChange={(e) => setNoteContent(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={saveNote} className="mr-2">
                Save
              </Button>
              <Button variant="secondary" onClick={cancelEditing} className="mr-2">
                Cancel
              </Button>
              {noteId && (
                <Button variant="danger" onClick={() => deleteNote(noteId)}>
                  Delete
                </Button>
              )}
            </>
          ) : (
            <>
              {userNotes.length > 0 ? (
                userNotes.map((note) => (
                  <div key={note.note_id} className="mb-3">
                    <Card.Text>{note.note_text}</Card.Text>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        setIsEditing(true);
                        setNoteContent(note.note_text);
                        setNoteId(note.note_id);
                      }}
                    >
                      Edit Note
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      className="ml-2"
                      onClick={() => deleteNote(note.note_id)}
                    >
                      Delete
                    </Button>
                  </div>
                ))
              ) : (
                <Card.Text>No notes added for this property.</Card.Text>
              )}

              <Button variant="secondary" size="sm" onClick={() => setIsEditing(true)}>
                Add Note
              </Button>
            </>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default PropertyNotes;
