import React, { useState, useEffect } from 'react';
import { propertiesAPI } from './PropertiesAPI';
import 'bootstrap-icons/font/bootstrap-icons.css';

const DocumentsTable = () => {
    const [documents, setDocuments] = useState([]);
    const [filterCounty, setFilterCounty] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const data = await propertiesAPI.getSB156Docs();
                setDocuments(data);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };

        fetchDocuments();
    }, []);

    const counties = [...new Set(documents.map(doc => doc.county))].sort();

    const filteredDocuments = documents.filter(doc => 
        (filterCounty === '' || doc.county === filterCounty) &&
        (searchTerm === '' || doc.uploadedFileName.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleDownload = async (documentId, uploadedFileName) => {
        await propertiesAPI.downloadDocument(documentId, uploadedFileName);
    };

    return (
        <div className="container mt-3">
            <div className="mb-3">
                <select value={filterCounty} onChange={e => setFilterCounty(e.target.value)} className="form-select me-2" aria-label="Select County">
                    <option value="">Select County</option>
                    {counties.map((county, index) => (
                        <option key={index} value={county}>{county}</option>
                    ))}
                </select>
                <input
                    type="text"
                    placeholder="Search by file name..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="form-control mt-2"
                />
            </div>
            {/* Table layout */}
            <div className="table-responsive d-none d-md-block">
                <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th className="align-middle">Action</th>
                            <th className="align-middle">State</th>
                            <th className="align-middle">County</th>
                            <th className="align-middle">Uploaded File Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDocuments.map((doc, index) => (
                            <tr key={index}>
                                <td className="align-middle">
                                    <button className="btn custom-download-button" onClick={() => handleDownload(doc.documentId, doc.uploadedFileName)}>
                                        <i className="bi bi-download"></i>
                                    </button>
                                </td>
                                <td className="align-middle">{doc.state}</td>
                                <td className="align-middle">{doc.county}</td>
                                <td className="align-middle" title={doc.uploadedFileName} style={{ maxWidth: '150px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                    {doc.uploadedFileName}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* Card layout */}
            <div className="d-block d-md-none">
                {filteredDocuments.map((doc, index) => (
                    <div key={index} className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title" title={doc.uploadedFileName}>{doc.uploadedFileName}</h5>
                            <p className="card-text">State: {doc.state}</p>
                            <p className="card-text">County: {doc.county}</p>
                            <button className="btn custom-download-button" onClick={() => handleDownload(doc.documentId, doc.uploadedFileName)}>
                                <i className="bi bi-download"></i> Download
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DocumentsTable;
