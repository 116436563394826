import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import SaleRegistrationsComponent from './SaleRegistrationsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

const SaleRegistrationsModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="manager-square" onClick={handleShow}>
                <FontAwesomeIcon icon={faClipboardList} className="manager-icon" />
                <div className="manager-text">Manage Registrations</div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Sale Registrations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SaleRegistrationsComponent />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SaleRegistrationsModal;
