import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/**
 * Thunk to initialize authentication.
 *
 * Modified to:
 * - Not clear user state on page reload.
 * - When tokens are present in the URL (e.g. after login), simply update tokens
 *   without dispatching a logout.
 * - If saved tokens exist in localStorage, load them into state (and refresh if expired).
 * - On errors (e.g. API failures) during initialization, simply reject the thunk
 *   without clearing existing state.
 */
export const initializeAuth = createAsyncThunk(
  'user/initializeAuth',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams(window.location.search);

      // Extract tokens from URL if present.
      const urlAccessToken = queryParams.get('accessToken');
      const urlIdToken = queryParams.get('idToken');
      const urlRefreshToken = queryParams.get('refreshToken');

      if (urlAccessToken && urlIdToken && urlRefreshToken) {
        // Instead of logging out, update the tokens.
        dispatch(
          setTokens({
            accessToken: urlAccessToken,
            idToken: urlIdToken,
            refreshToken: urlRefreshToken,
          })
        );
        localStorage.setItem(
          'authTokens',
          JSON.stringify({
            accessToken: urlAccessToken,
            idToken: urlIdToken,
            refreshToken: urlRefreshToken,
          })
        );

        // Remove tokens from URL so they are not visible.
        queryParams.delete('accessToken');
        queryParams.delete('idToken');
        queryParams.delete('refreshToken');
        queryParams.delete('expiresIn');
        const newQueryString = queryParams.toString();
        const newUrl =
          window.location.pathname +
          (newQueryString ? `?${newQueryString}` : '') +
          window.location.hash;
        window.history.replaceState({}, document.title, newUrl);
      }

      // If no tokens in URL, check for saved tokens.
      const savedTokens = JSON.parse(localStorage.getItem('authTokens') || '{}');
      if (!savedTokens.accessToken) {
        // No tokens exist; nothing more to do.
        return;
      }

      // If tokens are not already in Redux state, load them.
      const state = getState();
      if (!state.user.tokens.accessToken) {
        dispatch(setTokens(savedTokens));
      }

      // Validate saved tokens.
      const isTokenExpired = (token) => {
        if (!token) return true;
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp < currentTime;
      };

      if (isTokenExpired(savedTokens.accessToken)) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/auth/refresh`,
          { refreshToken: savedTokens.refreshToken }
        );
        const {
          accessToken: newAccessToken,
          idToken: newIdToken,
          refreshToken: newRefreshToken,
        } = response.data;
        const newTokens = {
          accessToken: newAccessToken,
          idToken: newIdToken,
          refreshToken: newRefreshToken,
        };
        dispatch(setTokens(newTokens));
        localStorage.setItem('authTokens', JSON.stringify(newTokens));
      }

      // Fetch additional user info.
      await dispatch(fetchUserInfo()).unwrap();
      await dispatch(fetchUserRegistrations()).unwrap();

      return;
    } catch (error) {
      // Simply reject with the error message without dispatching a logout.
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to fetch user info.
 *
 * After fetching, compares the access token's "sub" to the fetched user’s ID.
 * In error cases, we simply reject the thunk without clearing the state.
 */
export const fetchUserInfo = createAsyncThunk(
  'user/fetchUserInfo',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    try {
      // Cache-busting parameter.
      const proxyResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/app/proxy/userinfo`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { cb: Date.now() },
        }
      );

      if (!proxyResponse.data) {
        return rejectWithValue('No proxy user info.');
      }

      const fusionAuthUserId = proxyResponse.data.sub;
      const apiKey = process.env.REACT_APP_SERVER_API_KEY;
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/app/me/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-api-key': apiKey,
          },
          params: { fusionAuthUserId, cb: Date.now() },
        }
      );

      if (!response.data) {
        return rejectWithValue('No user info from DB.');
      }

      const { user, organizations } = response.data;
      if (!user.email) {
        return rejectWithValue('User email missing.');
      }

      // Decode the access token and compare its "sub" with the fetched user's ID.
      const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
      if (user.fusionauth_user_id !== tokenPayload.sub) {
        return rejectWithValue('Stale session detected. Please log in again.');
      }

      const appRoles = user.roles.reduce((acc, role, index) => {
        acc[user.applications[index]] = role;
        return acc;
      }, {});

      dispatch(setUser(user));
      dispatch(updateUserAddresses(user.addresses || []));
      dispatch(setOrganizations(organizations));
      dispatch(
        updateOrgAddresses(
          organizations.flatMap((org) => org.addresses || [])
        )
      );
      dispatch(setNotifications(user.notifications || []));
      dispatch(setAppRoles(appRoles));

      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to refresh the authentication token.
 */
export const refreshAuthToken = createAsyncThunk(
  'user/refreshAuthToken',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { refreshToken } = getState().user.tokens;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/refresh`,
        { refreshToken }
      );
      const { accessToken, idToken, refreshToken: newRefreshToken } = response.data;

      dispatch(setTokens({ accessToken, idToken, refreshToken: newRefreshToken }));
      localStorage.setItem(
        'authTokens',
        JSON.stringify({ accessToken, idToken, refreshToken: newRefreshToken })
      );

      return accessToken;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to fetch user registrations.
 */
export const fetchUserRegistrations = createAsyncThunk(
  'user/fetchUserRegistrations',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('No user ID.');
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/sales/user/${userId}/registrations`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data) {
        dispatch(setUserRegistrations(response.data));
        return response.data;
      } else {
        return rejectWithValue('No registrations data.');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to update user preferences.
 */
export const updateUserPreferencesThunk = createAsyncThunk(
  'user/updateUserPreferences',
  async (preferences, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('No user ID.');
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/preferences/user/${userId}/update`,
        preferences,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue('Failed to update preferences.');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to log out the user.
 *
 * When a user explicitly clicks logout, we:
 * - Set a forceLogout flag.
 * - Clear Redux state and storage.
 * - Retrieve the idToken and hit the logout route.
 */
export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      // Set a flag so subsequent page loads ignore any tokens.
      localStorage.setItem('forceLogout', 'true');
      // Clear Redux state.
      dispatch(logout());
      // Remove tokens from localStorage.
      localStorage.removeItem('authTokens');
      // Clear out all session storage.
      sessionStorage.clear();

      // Retrieve the idToken from state (if available).
      const { idToken } = getState().user.tokens;
      // Hit the logout route.
      const logoutUrl = `${process.env.REACT_APP_SERVER_URL}/app/logout?id_token=${encodeURIComponent(
        idToken || ''
      )}`;
      window.location.replace(logoutUrl);
      return;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to register for a sale.
 */
export const registerForSaleThunk = createAsyncThunk(
  'user/registerForSale',
  async ({ saleId, auction }, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('No user ID.');
    }
    try {
      const [year, m, d] = auction.auctionDate.split('T')[0].split('-');
      const reqBody = {
        userId,
        saleId,
        sale_date: `${year}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`,
        state: auction.state,
        county: auction.county,
        property: {
          ...auction,
          auctionDetails: {
            date: auction.auctionDate,
            time: auction.auctionDetail?.time,
            location: auction.auctionDetail?.location,
            auctionStyle: auction.auctionDetail?.type,
            saleStatusDescription: auction.auctionDetail?.status,
          },
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sales/register`,
        reqBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(fetchUserRegistrations());
        return response.data;
      } else {
        return rejectWithValue('Failed to register for sale.');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Thunk to unregister from a sale.
 */
export const unregisterFromSaleThunk = createAsyncThunk(
  'user/unregisterFromSale',
  async (saleId, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('No user ID.');
    }
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/sales/user/${userId}/sales/${saleId}/unregister`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(fetchUserRegistrations());
        return response.data;
      } else {
        return rejectWithValue('Failed to unregister from sale.');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  user: {},
  organizations: [],
  userAddresses: [],
  orgAddresses: [],
  notifications: [],
  notificationsUpdated: false,
  tokens: { accessToken: '', idToken: '', refreshToken: '' },
  profileUpdated: false,
  appRoles: {},
  userRegistrations: [],
  userRoles: [],
  userApplications: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleNotificationsUpdated: (state) => {
      state.notificationsUpdated = !state.notificationsUpdated;
    },
    setUser: (state, action) => {
      state.user = action.payload || {};
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload || [];
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    updateOrganization: (state, action) => {
      const orgIndex = state.organizations.findIndex(
        (org) => org.id === action.payload.id
      );
      if (orgIndex !== -1) {
        state.organizations[orgIndex] = {
          ...state.organizations[orgIndex],
          ...action.payload,
        };
      }
    },
    updateUserAddresses: (state, action) => {
      state.userAddresses = action.payload || [];
    },
    updateOrgAddresses: (state, action) => {
      state.orgAddresses = action.payload || [];
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload || [];
    },
    markAllNotificationsRead: (state) => {
      state.notifications.forEach((notification) => {
        notification.status = 'read';
      });
    },
    setUserRegistrations: (state, action) => {
      state.userRegistrations = action.payload || [];
    },
    addOrganization: (state, action) => {
      state.organizations.push(action.payload);
    },
    deleteOrganization: (state, action) => {
      state.organizations = state.organizations.filter(
        (org) => org.id !== action.payload
      );
    },
    addAddress: (state, action) => {
      state.userAddresses.push(action.payload);
    },
    deleteAddress: (state, action) => {
      state.userAddresses = state.userAddresses.filter(
        (addr) => addr.id !== action.payload
      );
    },
    deleteOrganizationAddress: (state, action) => {
      const org = state.organizations.find((org) => org.id === action.payload.orgId);
      if (org) {
        org.addresses = org.addresses.filter(
          (addr) => addr.id !== action.payload.addrId
        );
      }
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    setProfileUpdated: (state, action) => {
      state.profileUpdated = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload || [];
    },
    setUserApplications: (state, action) => {
      state.userApplications = action.payload || [];
    },
    setAppRoles: (state, action) => {
      state.appRoles = action.payload || {};
    },
    logout: (state) => {
      state.user = {};
      state.organizations = [];
      state.userAddresses = [];
      state.orgAddresses = [];
      state.notifications = [];
      state.tokens = { accessToken: '', idToken: '', refreshToken: '' };
      state.profileUpdated = false;
      state.appRoles = {};
      state.userRegistrations = [];
      state.userRoles = [];
      state.userApplications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeAuth.fulfilled, (state) => {})
      .addCase(initializeAuth.rejected, (state, action) => {})
      .addCase(fetchUserInfo.fulfilled, (state) => {})
      .addCase(fetchUserInfo.rejected, (state, action) => {})
      .addCase(refreshAuthToken.fulfilled, (state) => {})
      .addCase(refreshAuthToken.rejected, (state, action) => {})
      .addCase(fetchUserRegistrations.fulfilled, (state) => {})
      .addCase(fetchUserRegistrations.rejected, (state, action) => {})
      .addCase(updateUserPreferencesThunk.fulfilled, (state) => {})
      .addCase(updateUserPreferencesThunk.rejected, (state, action) => {})
      .addCase(logoutUser.fulfilled, (state) => {})
      .addCase(logoutUser.rejected, (state, action) => {})
      .addCase(registerForSaleThunk.fulfilled, (state) => {})
      .addCase(registerForSaleThunk.rejected, (state, action) => {})
      .addCase(unregisterFromSaleThunk.fulfilled, (state) => {})
      .addCase(unregisterFromSaleThunk.rejected, (state, action) => {});
  },
});

export const {
  toggleNotificationsUpdated,
  setUser,
  setOrganizations,
  updateUser,
  updateOrganization,
  updateUserAddresses,
  updateOrgAddresses,
  setNotifications,
  markAllNotificationsRead,
  setUserRegistrations,
  addOrganization,
  deleteOrganization,
  addAddress,
  deleteAddress,
  deleteOrganizationAddress,
  setTokens,
  setProfileUpdated,
  setUserRoles,
  setUserApplications,
  setAppRoles,
  logout,
} = userSlice.actions;

export const selectUserInfo = (state) => state.user;
export const selectNotifications = (state) => state.user.notifications;
export const selectTokens = (state) => state.user.tokens;
export const selectProfileUpdated = (state) => state.user.profileUpdated;
export const selectUserApplications = (state) => state.user.userApplications;
export const selectUserRoles = (state) => state.user.userRoles;
export const selectAppRoles = (state) => state.user.appRoles;
export const selectNotificationsUpdated = (state) => state.user.notificationsUpdated;

export default userSlice.reducer;


export const setupStorageListener = (dispatch) => {
  const storageListener = (event) => {
    if (event.key === 'authTokens' && event.newValue === null) {
      dispatch(logout());
    }
  };

  window.addEventListener('storage', storageListener);

  return () => {
    window.removeEventListener('storage', storageListener);
  };
};
