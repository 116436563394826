// EmailModal.js

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import EmailComponent from './EmailComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const EmailModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="manager-square" onClick={handleShow}>
                <FontAwesomeIcon icon={faPaperPlane} className="manager-icon" />
                <div className="manager-text">Send Email</div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Email to Users </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmailComponent />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EmailModal;
