import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const MoveDocumentModal = ({ show, onHide, document, onSave, containers }) => {
    const [newContainer, setNewContainer] = useState('');

    const handleMove = async () => {
        try {
            const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
            const API_KEY = process.env.REACT_APP_SERVER_API_KEY;
            await axios.put(`${API_BASE_URL}/blob/container/${document.containerName}/move/${document.name}`, {
                newContainer
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-api-key': API_KEY
                },
            });
            onSave();  // Refresh the parent component after moving
            onHide();  // Close the modal
        } catch (error) {
            console.error('Error moving document:', error);
            // Ideally, handle errors in UI here
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Move Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>New Container</Form.Label>
                        <Form.Control as="select" value={newContainer} onChange={(e) => setNewContainer(e.target.value)}>
                            <option value="">Select Container</option>
                            {containers.map(container => (
                                <option key={container} value={container}>{container}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={handleMove}>Move Document</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoveDocumentModal;

