import axios from 'axios';

const getCoordinates = async (address) => {
    try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`);
        const data = await response.json();

        if (data.status === 'ZERO_RESULTS') {
            throw new Error('No results found for the given address.');
        }

        const location = data.results[0].geometry.location;
        return {
            lat: location.lat,
            lng: location.lng
        };
    } catch (error) {
        console.error("Unable to geocode address:", error);
        return { lat: null, lng: null };
    }
};


export default getCoordinates;
