import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectTokens } from '../redux/userSlice';
import LoadingAnimation from './LoadingAnimation'; // Assuming the file is in the same directory

const SendMessageComponent = () => {
    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [sendToAll, setSendToAll] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const tokens = useSelector(selectTokens);
    const baseUrl = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        if (searchText.length >= 2) {
            fetchUsers(searchText);
        }
    }, [searchText]);

    const fetchUsers = async (search) => {
        try {
            const response = await axios.get(`${baseUrl}/app/messages/users?search=${search}`, {
                headers: { Authorization: `Bearer ${tokens.accessToken}` }
            });
            setUsers(response.data);
            setShowDropdown(true);
        } catch (error) {
            console.error('Error fetching users:', error);
            setShowDropdown(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const selectUser = (user) => {
        setSelectedUsers([...selectedUsers, user]);
        setShowDropdown(false);
        setSearchText('');
    };

    const sendMessage = async () => {
        if (!tokens.accessToken || !message) {
            console.error('Access token or message is not available');
            return;
        }

        setIsLoading(true);

        try {
            if (sendToAll) {
                if (!window.confirm('Are you sure you want to send this message to ALL users?')) {
                    setIsLoading(false);
                    return;
                }

                const response = await axios.post(`${baseUrl}/app/messages/notifications`, { message, sendToAll }, {
                    headers: { Authorization: `Bearer ${tokens.accessToken}` }
                });

                if (response.status === 201) {
                    setMessageSent(true);
                    console.log('Message sent to all users');
                }
            } else {
                const userIds = selectedUsers.map(user => user.fusionauth_user_id);
                const promises = userIds.map(userId =>
                    axios.post(`${baseUrl}/app/messages/notifications`, { message, userId }, {
                        headers: { Authorization: `Bearer ${tokens.accessToken}` }
                    })
                );

                await Promise.all(promises);
                setMessageSent(true);
                console.log('Message sent to selected users');
            }

            setTimeout(() => {
                setMessageSent(false);
                resetForm();
            }, 3000);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setMessage('');
        setSearchText('');
        setSelectedUsers([]);
        setSendToAll(false);
        setUsers([]);
        setShowDropdown(false);
    };

    const removeUser = (userId) => {
        setSelectedUsers(selectedUsers.filter(user => user.fusionauth_user_id !== userId));
    };

    return (
        <div style={{ width: '100%', overflowY: 'auto', border: '5px solid #FF6D40', borderRadius: '10px', boxSizing: 'border-box', position: 'relative' }}>
            {isLoading && <LoadingAnimation />}
            {!isLoading && messageSent && (
                <div style={{ width: '100%', height: '100%', backgroundColor: '#FF6D40', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                    Notification Successfully Sent
                </div>
            )}
            {!isLoading && !messageSent && (
                <>
                    <div style={{ backgroundColor: '#FF6D40', color: 'white', textAlign: 'center', fontWeight: 'bold', padding: '10px 0' }}>
                        Send Notification to Users
                    </div>
                    <Form style={{ padding: '10px', overflowY: 'auto', height: 'calc(50vh - 60px)' }}>
                        {!sendToAll && (
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search users"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                />
                            </InputGroup>
                        )}
                        {!sendToAll && showDropdown && (
                            <Dropdown show={showDropdown}>
                                <Dropdown.Toggle variant="success">
                                    Select User
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {users.map(user => (
                                        <Dropdown.Item key={user.fusionauth_user_id} onClick={() => selectUser(user)}>
                                            {user.first_name} {user.last_name} - {user.email}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        <div style={{ maxHeight: '100px', overflowY: 'scroll', marginTop: '10px' }}>
                            {selectedUsers.map(user => (
                                <Button key={user.fusionauth_user_id} variant="secondary" size="sm" style={{ marginRight: '5px', marginBottom: '5px' }}>
                                    {`${user.first_name} ${user.last_name} (${user.email})`}
                                    <span onClick={() => removeUser(user.fusionauth_user_id)} style={{ marginLeft: '5px', cursor: 'pointer' }}>X</span>
                                </Button>
                            ))}
                        </div>
                        <Form.Check 
                            type="checkbox" 
                            label="Send to All Users" 
                            checked={sendToAll} 
                            onChange={(e) => setSendToAll(e.target.checked)} 
                            style={{ marginTop: '10px' }}
                        />
                        <Form.Group controlId="messageTextArea" style={{ marginTop: '10px' }}>
                            <Form.Label className='label-bg'>Notification</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Enter your message here"
                            />
                        </Form.Group>
                        <Button className='btn-primary' onClick={sendMessage} style={{ marginTop: '10px' }}>
                            Send Notification
                        </Button>
                    </Form>
                </>
            )}
        </div>
    );
};

export default SendMessageComponent;