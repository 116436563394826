import React, { useState, useMemo, useEffect } from 'react';
import { Table, Button, FormCheck, FormControl, InputGroup, Dropdown, DropdownButton, ButtonGroup, Pagination } from 'react-bootstrap';
import axios from 'axios';
import { MdEdit, MdMoveToInbox, MdDownload } from 'react-icons/md';

const DocumentTable = ({ documents, onEdit, onMove, onDelete }) => {
    const [showExpired, setShowExpired] = useState(false);  // Control if expired docs are visible
    const [filterText, setFilterText] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedContainer, setSelectedContainer] = useState('');
    const documentsPerPage = 10;
    const paginationRange = 5;

    const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
    const API_KEY = process.env.REACT_APP_SERVER_API_KEY;

    // Enhanced formatDate function to log and validate dates properly
    const formatDate = (dateString) => {
        console.log("formatDate called with:", dateString);
        if (!dateString || isNaN(Date.parse(dateString))) {
            return 'N/A';
        }
        const normalizedDateString = dateString.includes("T") ? dateString : `${dateString}T00:00:00.000Z`;
        const date = new Date(normalizedDateString);
        if (isNaN(date)) return 'N/A';
        return date.toISOString().split('T')[0];
    };

    const isExpired = (expirationDate) => {
        if (!expirationDate) return false;
        return new Date() > new Date(expirationDate);
    };

    const handleSort = (field) => {
        if (field === 'actions') return;
        const newDirection = (field === sortField && sortDirection === 'asc') ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(newDirection);
    };

    const uniqueContainers = useMemo(() => {
        const containerNames = documents.map(doc => doc.containerName || '');
        return [...new Set(containerNames)];
    }, [documents]);

    const sortedDocuments = useMemo(() => {
        if (!sortField) return documents;
        return [...documents].sort((a, b) => {
            // field might be top-level or in doc.metadata
            const aField = a[sortField] || a.metadata[sortField] || '';
            const bField = b[sortField] || b.metadata[sortField] || '';
            return sortDirection === 'asc'
                ? aField.toString().localeCompare(bField.toString())
                : bField.toString().localeCompare(aField.toString());
        });
    }, [documents, sortField, sortDirection]);

    const filteredDocuments = useMemo(() => {
        return sortedDocuments.filter(doc => {
            const docContainer = (doc.containerName || '').trim().toLowerCase();
            const selected = (selectedContainer || '').trim().toLowerCase();
            const matchesContainer = selected ? docContainer === selected : true;

            const searchText = filterText.toLowerCase();
            const matchesFilterText =
                doc.name.toLowerCase().includes(searchText) ||
                Object.entries(doc.metadata).some(([key, val]) =>
                    key !== 'actions' && val.toString().toLowerCase().includes(searchText)
                );

            // Show expired if toggled on, hide them if toggled off
            const matchesExpiration = showExpired || !isExpired(doc.metadata.expirationdate);

            return matchesContainer && matchesFilterText && matchesExpiration;
        });
    }, [sortedDocuments, showExpired, filterText, selectedContainer]);

    // If any filter changes, reset to page 1
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedContainer, filterText, showExpired]);

    const paginatedDocuments = useMemo(() => {
        const firstIndex = (currentPage - 1) * documentsPerPage;
        const lastIndex = firstIndex + documentsPerPage;
        return filteredDocuments.slice(firstIndex, lastIndex);
    }, [currentPage, filteredDocuments]);

    const pageCount = Math.ceil(filteredDocuments.length / documentsPerPage);

    const handleViewDownload = async (doc) => {
        try {
            const response = await axios.get(
              `${API_BASE_URL}/blob/container/${doc.containerName}/blob/${doc.name}`,
              {
                headers: {
                    'x-api-key': API_KEY
                },
                responseType: 'blob'
              }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc.name);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading/viewing blob:', error);
        }
    };

    const renderPagination = () => {
        if (pageCount <= 1) return null;

        const pages = [];
        const startPage = Math.max(currentPage - Math.floor(paginationRange / 2), 1);
        const endPage = Math.min(startPage + paginationRange - 1, pageCount);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => setCurrentPage(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return (
            <Pagination className="justify-content-center">
                {currentPage > 1 && <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />}
                {pages}
                {currentPage < pageCount && <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />}
            </Pagination>
        );
    };

    return (
        <>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Search documents..."
                    onChange={e => setFilterText(e.target.value)}
                />
                <FormCheck
                    type="switch"
                    id="show-expired-toggle"
                    label="Show Expired"
                    checked={showExpired}
                    onChange={e => setShowExpired(e.target.checked)}
                    className="ms-3"
                />
                <DropdownButton
                    title={selectedContainer || "Filter by Container"}
                    align="end"
                    variant="outline-secondary"
                    className="ms-2"
                    onSelect={(e) => setSelectedContainer(e)}
                >
                    <Dropdown.Item eventKey="">All Containers</Dropdown.Item>
                    {uniqueContainers.map(container => (
                        <Dropdown.Item key={container} eventKey={container}>
                            {container}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
                <DropdownButton
                    title="Sort By"
                    align="end"
                    variant="outline-secondary"
                    className="ms-2"
                >
                    {['name', 'docType', 'propertyId', 'containerName', 'expirationDate', 'state', 'county', 'saleDate', 'saleId'].map(field => (
                        <Dropdown.Item key={field} onClick={() => handleSort(field)}>
                            {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
                <Button
                    variant={sortDirection === 'asc' ? "outline-success" : "outline-danger"}
                    onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
                >
                    Sort: {sortDirection === 'asc' ? 'Ascending' : 'Descending'}
                </Button>
            </InputGroup>

            <div className="scrollable-table">
                <Table striped bordered hover responsive className="no-wrap-cell table-sm small-text">
                    <thead>
                        <tr>
                            <th>Actions</th>
                            {['name', 'docType', 'propertyId', 'containerName', 'expirationDate', 'state', 'county', 'saleDate', 'saleId'].map(header => (
                                <th key={header} onClick={() => handleSort(header)}>
                                    {header.charAt(0).toUpperCase() + header.slice(1).replace(/([A-Z])/g, ' $1')}
                                    {sortField === header ? (sortDirection === 'asc' ? ' ▲' : ' ▼') : ''}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedDocuments.length > 0 ? (
                            paginatedDocuments.map((doc, idx) => (
                                <tr
                                  key={idx}
                                  style={{
                                    backgroundColor: isExpired(doc.metadata.expirationdate)
                                      ? '#f8d7da'
                                      : ''
                                  }}
                                >
                                    <td>
                                        <ButtonGroup>
                                            <Button variant="primary" onClick={() => onEdit(doc)}>
                                                <MdEdit />
                                            </Button>
                                            <Button variant="secondary" onClick={() => onMove(doc)}>
                                                <MdMoveToInbox />
                                            </Button>
                                            <Button variant="info" onClick={() => handleViewDownload(doc)}>
                                                <MdDownload />
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                    <td>{doc.name}</td>
                                    <td>{doc.metadata.doctype || 'N/A'}</td>
                                    <td>{doc.metadata.propertyid || 'N/A'}</td>
                                    <td>{doc.containerName || 'Unknown'}</td>
                                    <td>{formatDate(doc.metadata.expirationdate)}</td>
                                    <td>{doc.metadata.state || 'N/A'}</td>
                                    <td>{doc.metadata.county || 'N/A'}</td>
                                    <td>{formatDate(doc.metadata.saledate)}</td>
                                    <td>{doc.metadata.saleid || 'N/A'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10" className="text-center">
                                    No documents found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {renderPagination()}
        </>
    );
};

export default DocumentTable;
