// src/components/FilterForm.js

import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import './FilterForm.css'; // We'll define some mobile-friendly overrides here

const FilterForm = ({
  states,
  selectedState,
  handleStateChange,
  counties,
  selectedCounty,
  setSelectedCounty,
  saleTypes,
  selectedSaleType,
  setSelectedSaleType,
  timeFrames,
  selectedTimeFrame,
  handleTimeFrameChange,
  resetDropdowns
}) => {
  return (
    <div className="filter-form-container">
      <Form>
        <Row className="filter-form-row">
          {/* State Select */}
          <Col xs={12} sm={6} md={6} lg={3}>
            <Form.Group controlId="stateSelect">
              <Form.Label className="label-bg">Select State</Form.Label>
              <Form.Control
                as="select"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="">Select a State</option>
                {states.map((state, index) => (
                  <option key={index} value={state.id}>{state.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {/* County Select */}
          <Col xs={12} sm={6} md={6} lg={3}>
            <Form.Group controlId="countySelect">
              <Form.Label className="label-bg">Select County</Form.Label>
              <Form.Control
                as="select"
                value={selectedCounty}
                onChange={(e) => setSelectedCounty(e.target.value)}
                disabled={!selectedState}
              >
                <option value="">Select a County</option>
                {counties.map((county, index) => (
                  <option key={index} value={county.id}>{county.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Sale Type Select */}
          <Col xs={12} sm={6} md={6} lg={3}>
            <Form.Group controlId="saleTypeSelect">
              <Form.Label className="label-bg">All Sale Types</Form.Label>
              <Form.Control
                as="select"
                value={selectedSaleType}
                onChange={(e) => setSelectedSaleType(e.target.value)}
              >
                <option value="">Select a Sale Type</option>
                {saleTypes.map((type, index) => (
                  <option key={index} value={type.id}>{type.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          {/* Time Frame Select */}
          <Col xs={12} sm={6} md={6} lg={3}>
            <Form.Group controlId="timeFrameSelect">
              <Form.Label className="label-bg">Select Time Frame</Form.Label>
              <Form.Control
                as="select"
                value={selectedTimeFrame}
                onChange={handleTimeFrameChange}
              >
                {timeFrames.map((timeFrame, index) => (
                  <option key={index} value={timeFrame}>{timeFrame}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        {/* Button row for Reset Filters */}
        <Row className="button-row">
          <Col xs={12} className="text-center">
            <Button
              variant="outline-secondary"
              className="resetBtn"
              onClick={resetDropdowns}
            >
              Reset Filters
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
