import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../images/sri-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, initializeAuth, logoutUser } from '../redux/userSlice';
import { FaBell } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import AuctionList from './AuctionList';
import './Navbar.css';

const Navbar = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const notifications = userInfo.notifications || [];
  const isAuthenticated = userInfo.user && userInfo.user.email ? true : false;

  const [isHoveredLogin, setIsHoveredLogin] = useState(false);
  const [isHoveredLogout, setIsHoveredLogout] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [showAuctionModal, setShowAuctionModal] = useState(false);

  // Initialize authentication on mount.
  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  // Reinitialize auth if profile is updated.
  useEffect(() => {
    if (userInfo.profileUpdated) {
      dispatch(initializeAuth());
    }
  }, [userInfo.profileUpdated, dispatch]);

  const buttonStyle = (isHovered) => ({
    backgroundColor: isHovered ? '#e55b00' : '#FF6D40',
    padding: '8px 16px',
    marginLeft: '1rem',
    marginRight: '1rem',
    borderRadius: '25px',
    color: '#ffffff',
    textDecoration: 'none',
    transition: 'background-color 0.2s',
    cursor: 'pointer',
  });

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const toggleCompanyDropdown = () => setShowCompanyDropdown(!showCompanyDropdown);

  const notificationIconStyle = {
    position: 'relative',
    fontSize: '1.5rem',
    cursor: 'pointer',
  };

  const badgeStyle = {
    position: 'absolute',
    right: '0px',
    top: '0px',
    padding: '0.1em 0.4em',
    borderRadius: '50%',
    fontSize: '0.7rem',
    color: 'white',
    backgroundColor: 'red',
  };

  const pendingNotificationsCount = notifications.filter((n) => !n.is_read).length;

  // Handler for login.
  const handleLogin = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const loginUrl = `${process.env.REACT_APP_SERVER_URL}/app/login?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      window.location.origin
    )}&original_url=${encodeURIComponent(window.location.href)}`;
    window.location.href = loginUrl;
  };

  // Handler for logout.
  const handleLogoutClick = () => {
    dispatch(logoutUser());
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <img
          src={logo}
          alt="Logo"
          style={{ marginLeft: '1rem', marginRight: '10px', height: '3rem', width: 'auto' }}
        />
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <NavLink className="nav-link" to="/">Home</NavLink>

          {/* Dropdown for "About SRI" */}
          <li
            className="nav-item dropdown"
            onMouseEnter={toggleCompanyDropdown}
            onMouseLeave={toggleCompanyDropdown}
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownCompany"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded={showCompanyDropdown}
            >
              About SRI
            </a>
            <div
              className={`dropdown-menu ${showCompanyDropdown ? 'show' : ''}`}
              aria-labelledby="navbarDropdownCompany"
            >
              <NavLink className="dropdown-item" to="/company">Company</NavLink>
              <NavLink className="dropdown-item" to="/work">How We Work</NavLink>
              <NavLink className="dropdown-item" to="/partners">Partners</NavLink>
              <NavLink className="dropdown-item" to="/investing">Investing</NavLink>
            </div>
          </li>

          <NavLink className="nav-link" to="/support">Support</NavLink>
          <NavLink className="nav-link" to="/properties">Properties</NavLink>

          {/* Auction List Modal Trigger */}
          <NavLink
            className="nav-link"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setShowAuctionModal(true);
            }}
          >
            Auction List
          </NavLink>

          {/* Logged-in links */}
          {isAuthenticated && <NavLink className="nav-link" to="/dashboard">Dashboard</NavLink>}
          {isAuthenticated && <NavLink className="nav-link" to="/profile">Profile</NavLink>}

          {isAuthenticated ? (
            <>
              {/* Notifications Dropdown */}
              <li
                className="nav-item dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <a
                  className="nav-link dropdown-toggle position-relative"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={showDropdown}
                >
                  <FaBell style={notificationIconStyle} />
                  {pendingNotificationsCount > 0 && (
                    <span className="notification-badge" style={badgeStyle}>
                      {pendingNotificationsCount}
                    </span>
                  )}
                </a>
                <div
                  className={`dropdown-menu ${showDropdown ? 'show' : ''}`}
                  aria-labelledby="navbarDropdown"
                  style={{ minWidth: '20rem', maxHeight: '400px', overflowY: 'auto' }}
                >
                  {notifications.length === 0 ? (
                    <span className="dropdown-item">No notifications.</span>
                  ) : (
                    notifications.map((n, index) => (
                      <a
                        key={index}
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          // Add notification read/navigate logic here if needed.
                        }}
                      >
                        {n.message}
                      </a>
                    ))
                  )}
                  <div className="dropdown-divider"></div>
                  <NavLink className="dropdown-item" to="/dashboard">
                    View all notifications
                  </NavLink>
                </div>
              </li>

              {/* Display logged-in email */}
              <span className="navbar-text mx-2">
                <b>{userInfo.user.email || 'No Email'}</b>
              </span>

              {/* Logout Button */}
              <a
                className="nav-link"
                style={buttonStyle(isHoveredLogout)}
                onMouseEnter={() => setIsHoveredLogout(true)}
                onMouseLeave={() => setIsHoveredLogout(false)}
                onClick={handleLogoutClick}
                href="#"
              >
                Logout
              </a>
            </>
          ) : (
            // Login / Register Button
            <a
              className="nav-link"
              style={buttonStyle(isHoveredLogin)}
              onMouseEnter={() => setIsHoveredLogin(true)}
              onMouseLeave={() => setIsHoveredLogin(false)}
              onClick={handleLogin}
              href="#"
            >
              Login/Register
            </a>
          )}
        </div>
      </div>

      {/* Auction List Modal */}
      <Modal
        show={showAuctionModal}
        onHide={() => setShowAuctionModal(false)}
        size="xl"
        centered
        dialogClassName="unique-auction-modal"
        aria-labelledby="auction-list-modal"
      >
        <Modal.Header closeButton className="unique-auction-modal-header">
          <Modal.Title id="auction-list-modal">Auction List</Modal.Title>
        </Modal.Header>
        <Modal.Body className="unique-auction-modal-body">
          <AuctionList closeModal={() => setShowAuctionModal(false)} />
        </Modal.Body>
        <Modal.Footer className="unique-auction-modal-footer">
          <Button variant="secondary" onClick={() => setShowAuctionModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
};

export default Navbar;
