// src/components/SaleRegistrationModal.js

import React from 'react';
import { Modal, Button, Form, InputGroup, Spinner, Alert, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import './SaleRegistrationModal.css'; // Import the new CSS file

const SaleRegistrationModal = ({
  isOpen,
  onClose,
  saleId,
  saleDate,
  selectedUser,
  searchText,
  handleSearchChange,
  users,
  showDropdown,
  selectUser,
  handleRegister,
  isLoading,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered
      className="sale-reg-modal"
      size="lg"
    >
      <Modal.Header closeButton className="sale-reg-modal-header">
        <Modal.Title>
          <FontAwesomeIcon icon={faUserPlus} className="sale-reg-modal-icon" /> Add Registration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="sale-reg-modal-body">
        <Form>
          <Form.Group controlId="saleReg-saleId" className="sale-reg-form-group">
            <Form.Label>Sale ID</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faUserPlus} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="text" value={saleId} readOnly />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="saleReg-userSearch" className="sale-reg-form-group">
            <Form.Label>Search Users</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Enter user email or name"
                value={searchText}
                onChange={handleSearchChange}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            {isLoading && (
              <div className="sale-reg-loading-spinner">
                <Spinner animation="border" size="sm" />
              </div>
            )}
            {showDropdown && (
              <Dropdown.Menu show className="sale-reg-user-dropdown">
                {users.map((user) => (
                  <Dropdown.Item
                    key={user.fusionauth_user_id}
                    onClick={() => selectUser(user)}
                    className="sale-reg-user-dropdown-item"
                  >
                    {user.first_name} {user.last_name} - {user.email}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Form.Group>
          {selectedUser && (
            <div className="sale-reg-selected-user">
              <Alert variant="info" className="sale-reg-selected-user-alert">
                <strong>Selected User:</strong> {selectedUser.first_name} {selectedUser.last_name} - {selectedUser.email}
              </Alert>
              <Button
                variant="primary"
                onClick={handleRegister}
                className="sale-reg-confirm-btn"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Registering...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faUserPlus} /> Register for Sale
                  </>
                )}
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="sale-reg-modal-footer">
        <Button variant="secondary" onClick={onClose} className="sale-reg-close-btn">
          <FontAwesomeIcon icon={faTimes} /> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaleRegistrationModal;
