import React, { useState, useEffect } from 'react';
import { Button, Form, FormCheck, InputGroup, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectTokens } from '../redux/userSlice';
import LoadingAnimation from './LoadingAnimation';
import './UserManagementComponent.css';

const UserManagementComponent = () => {
    const [states, setStates] = useState([]);
    const [counties, setCounties] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [testToken, setTestToken] = useState(''); // Add state for test token
    const [selectedUser, setSelectedUser] = useState(null);
    const [userDetails, setUserDetails] = useState({
        user: {
            fusionauth_user_id: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone: '',
            fax: '',
            addresses: [{
                street_address: '',
                street_address2: '',
                city: '',
                state: '',
                zip: '',
            }],
        },
        organizations: [{
            business_name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            phone: '',
            fax: '',
            addresses: [{
                street_address: '',
                street_address2: '',
                city: '',
                state: '',
                zip: '',
            }],
        }]
    });
    const emptyAddress = {
        street_address: '',
        street_address2: '',
        city: '',
        state: '',
        zip: ''
    };
    
    const emptyOrg = {
        business_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        fax: '',
        addresses: [emptyAddress] // Initialize with one empty address
    };
    
    const [users, setUsers] = useState([]);
    const [applications, setApplications] = useState([]);
    const [appAccess, setAppAccess] = useState({});
    const [roles, setRoles] = useState({});
    const [appRoles, setAppRoles] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userAppRoles, setUserAppRoles] = useState([]);
    const tokens = useSelector(selectTokens);
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const apiKey = process.env.REACT_APP_SERVER_API_KEY;
    const [updateStatus, setUpdateStatus] = useState({ success: false, message: '' });
    const [testUserId, setTestUserId] = useState('');
    const [fullScreenMessage, setFullScreenMessage] = useState(null); // Add state for full-screen message

    useEffect(() => {
        fetchStates();
    }, []);

    useEffect(() => {
        if (selectedState) {
            fetchCounties(selectedState.state_id);
        }
    }, [selectedState]);
    
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [appsResponse, rolesResponse] = await Promise.all([
                    axios.get(`${baseUrl}/app/access/applications`, {
                        headers: { Authorization: `Bearer ${tokens.accessToken}`, 'x-api-key': apiKey }
                    }),
                    axios.get(`${baseUrl}/app/access/roles`, {
                        headers: { Authorization: `Bearer ${tokens.accessToken}`, 'x-api-key': apiKey }
                    })
                ]);
    
                setApplications(appsResponse.data);
                const rolesByApp = rolesResponse.data.reduce((acc, role) => {
                    acc[role.app_id] = (acc[role.app_id] || []).concat(role);
                    return acc;
                }, {});
    
                setAppRoles(rolesByApp);
                const accessByApp = appsResponse.data.reduce((acc, app) => {
                    acc[app.app_name] = { enabled: false, role: 'Select Role' };
                    return acc;
                }, {});
    
                setAppAccess(accessByApp);
            } catch (error) {
                console.error('Error loading applications and roles:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [baseUrl, tokens, apiKey]);
    
    useEffect(() => {
        if (selectedUser) {
            fetchUserDetails(selectedUser.fusionauth_user_id);
            fetchUserApplicationsAndRoles(selectedUser.fusionauth_user_id);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (selectedUser) {
            setUserDetails(prevDetails => {
                const updatedDetails = { ...prevDetails };
    
                // Ensures there is always at least one address for the user
                if (updatedDetails.user.addresses.length === 0) {
                    updatedDetails.user.addresses = [{ ...emptyAddress }];
                }
    
                // Ensures there is always at least one organization
                // and each organization has at least one address
                if (updatedDetails.organizations.length === 0) {
                    updatedDetails.organizations = [{ ...emptyOrg }];
                } else {
                    updatedDetails.organizations = updatedDetails.organizations.map(org => {
                        if (org.addresses.length === 0) {
                            org.addresses = [{ ...emptyAddress }];
                        }
                        return org;
                    });
                }
    
                return updatedDetails;
            });
        }
    }, [selectedUser, emptyAddress, emptyOrg]);
    
    
    
    
    
    useEffect(() => {
        if (searchText.length >= 2) {
            const fetchUsers = async () => {
               
                try {
                    const response = await axios.get(`${baseUrl}/app/messages/users?search=${searchText}`, {
                        headers: {
                            Authorization: `Bearer ${tokens.accessToken}`,
                            'x-api-key': apiKey
                        }
                    });
                    setUsers(response.data || []);
                    setShowDropdown(response.data && response.data.length > 0);
                } catch (error) {
                    console.error('Error fetching users:', error);
                    setShowDropdown(false);
                } finally {
                    setIsLoading(false);
                }
            };
    
            fetchUsers();
        } else {
            setUsers([]);
            setShowDropdown(false);
        }
    }, [searchText, baseUrl, tokens.accessToken, apiKey]);


    const fetchCounties = async (stateId) => {
        if (!stateId) {
            setCounties([]);  // Clear counties if no stateId is provided
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/app/access/counties/state/${stateId}`, {
                headers: { Authorization: `Bearer ${tokens.accessToken}` }
            });
            setCounties(response.data);
        } catch (error) {
            console.error('Error fetching counties:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleSearchChange = (e) => setSearchText(e.target.value);

    const selectUser = async (user) => {
        setSelectedUser(user);
        setSearchText(`${user.first_name} ${user.last_name}`);
        setShowDropdown(false);
        setIsLoading(true);
    
        try {
            const userDetailsResponse = await axios.get(`${baseUrl}/app/me/userinfo/${user.fusionauth_user_id}`, {
                headers: { Authorization: `Bearer ${tokens.accessToken}` }
            });
            setUserDetails(userDetailsResponse.data || { user: {}, organizations: [] });
    
            // Set state and county based on user's data
            if (userDetailsResponse.data.user.county_id) {
                const countiesResponse = await axios.get(`${baseUrl}/app/access/counties`);
                const userCounty = countiesResponse.data.find(c => c.county_id === userDetailsResponse.data.user.county_id);
                setSelectedCounty(userCounty);
                
                if (userCounty) {
                    const stateResponse = await axios.get(`${baseUrl}/app/access/states`);
                    const userState = stateResponse.data.find(s => s.state_id === userCounty.state_id);
                    setSelectedState(userState);
                    await fetchCounties(userState.state_id);
                }
            } else {
                // Clear selections if no county or state info is present
                setSelectedCounty(null);
                setSelectedState(null);
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        } finally {
            setIsLoading(false);
        }
    };
        
    const clearCountyStateSelection = () => {
        setSelectedState(null);
        setSelectedCounty(null);
    };

    const fetchStates = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/app/access/states`, {
                headers: { Authorization: `Bearer ${tokens.accessToken}` }
            });
            setStates(response.data);
        } catch (error) {
            console.error('Error fetching states:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const fetchUserApplicationsAndRoles = async (userId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/app/access/user-apps-roles/${userId}`, {
                headers: { Authorization: `Bearer ${tokens.accessToken}`, 'x-api-key': apiKey }
            });
    
            const userApps = response.data;
    
            setAppAccess(applications.reduce((acc, app) => {
                const userApp = userApps.find(ua => ua.app_name === app.app_name);
                acc[app.app_name] = {
                    enabled: !!userApp,
                    role: userApp ? userApp.role_name : 'Select Role'
                };
                return acc;
            }, {}));
        } catch (error) {
            console.error('Error fetching user applications and roles:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    
    const fetchUserDetails = async (userId) => {
        try {
            const response = await axios.get(`${baseUrl}/users/${userId}`, {
                headers: { Authorization: `Bearer ${tokens.accessToken}` }
            });
            setUserDetails(response.data);
            const userState = states.find(s => s.state_id === response.data.user.state_id);
            setSelectedState(userState);
            if (userState) {
                await fetchCounties(userState.state_id);
                const userCounty = counties.find(c => c.county_id === response.data.user.county_id);
                setSelectedCounty(userCounty);
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    const handleAppToggle = (appName) => {
        setAppAccess(prevAppAccess => ({
            ...prevAppAccess,
            [appName]: {
                ...prevAppAccess[appName],
                enabled: !prevAppAccess[appName].enabled
            }
        }));
    };
    

    const handleRoleChange = (appName, role) => {
        setAppAccess(prevAppAccess => ({
            ...prevAppAccess,
            [appName]: {
                ...prevAppAccess[appName],
                role: role
            }
        }));
    };
    

    // Handler for user details, including nested addresses
        const handleUserDetailChange = (event, index, key) => {
            const { name, value } = event.target;
        
            if (key) {
                // This is for handling nested address updates
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    user: {
                        ...prevDetails.user,
                        addresses: prevDetails.user.addresses.map((address, addrIdx) => {
                            if (index === addrIdx) {
                                return { ...address, [key]: value };
                            }
                            return address;
                        })
                    }
                }));
            } else {
                // This is for handling direct property updates like first_name, last_name
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    user: {
                        ...prevDetails.user,
                        [name]: value
                    }
                }));
            }
        };
    

        // Handler for organization details, including nested addresses
        const handleOrganizationDetailChange = (event, orgIndex, addrIndex, key) => {
            const { name, value } = event.target;
            setUserDetails(prevDetails => ({
                ...prevDetails,
                organizations: prevDetails.organizations.map((org, oIdx) => {
                    if (oIdx === orgIndex) {
                        if (key) {
                            // Handle nested address update
                            return {
                                ...org,
                                addresses: org.addresses.map((address, aIdx) => {
                                    if (aIdx === addrIndex) {
                                        return { ...address, [key]: value };
                                    }
                                    return address;
                                })
                            };
                        } else {
                            // Handle direct org property update
                            return { ...org, [name]: value };
                        }
                    }
                    return org;
                })
            }));
        };

   
        const handleStateSelect = (eventKey) => {
            const state = states.find(s => s.state_id.toString() === eventKey);
            setSelectedState(state);
            setSelectedCounty(null); // Reset county selection when state changes
        };
    
        const handleCountySelect = (eventKey) => {
            const county = counties.find(c => c.county_id.toString() === eventKey);
            setSelectedCounty(county);
        };
        

    const handleStateChange = (eventKey) => {
        const state = states.find(s => s.state_id.toString() === eventKey);
        setSelectedState(state);
        setSelectedCounty(null);
        
    };

    const handleCountyChange = (eventKey) => {
        const county = counties.find(c => c.county_id.toString() === eventKey);
        setSelectedCounty(county);
    };
    
    const updateUserDetails = async () => {
        setIsLoading(true);
    
        // Extract user data without addresses and notifications
        const { addresses: userAddresses, notifications, ...userDataWithoutAddressesAndNotifications } = userDetails.user;
    
        // Prepare organization data without addresses and extract org addresses
        const orgData = userDetails.organizations.map(org => {
            const { addresses, ...orgDataWithoutAddresses } = org;
            return orgDataWithoutAddresses;
        });
    
        const orgAddresses = userDetails.organizations.map(org => {
            return org.addresses.length > 0 ? org.addresses[0] : null; // Assuming sending the first address only
        });
    
        // Prepare payload according to your backend requirements
        const payload = {
            userData: { ...userDataWithoutAddressesAndNotifications },
            userAddress: userAddresses.length > 0 ? { ...userAddresses[0] } : null, // Assuming sending the first address only
            orgData: orgData, // Now orgData is an array
            orgAddress: orgAddresses[0], // Assuming the first org address (needs handling if multiple)
            stateId: selectedState ? selectedState.state_id : null,
            countyId: selectedCounty ? selectedCounty.county_id : null
        };
    
        try {
            // Making the PUT request to update user details along with access roles, state, and county
            const response = await axios.put(
                `${baseUrl}/app/me/userinfo2/${selectedUser.fusionauth_user_id}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${tokens.accessToken}`,
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.status === 200) {
                // Clear all existing roles for the user's applications
                for (const app of applications) {
                    await axios.delete(`${baseUrl}/app/access/user-roles/${selectedUser.fusionauth_user_id}/${app.app_id}`, {
                        headers: {
                            Authorization: `Bearer ${tokens.accessToken}`,
                            'x-api-key': apiKey
                        }
                    });
                }
    
                // Set the new roles and access for each application
                for (const [appName, { enabled, role }] of Object.entries(appAccess)) {
                    const app = applications.find(a => a.app_name === appName);
                    if (!app) continue;
    
                    const roleDetails = appRoles[app.app_id]?.find(r => r.role_name === role);
                    if (enabled && roleDetails) {
                        await axios.post(`${baseUrl}/app/access/manage-user-roles`, {
                            userId: selectedUser.fusionauth_user_id,
                            roleId: roleDetails.role_id,
                            appId: app.app_id,
                            enabled
                        }, {
                            headers: {
                                Authorization: `Bearer ${tokens.accessToken}`,
                                'x-api-key': apiKey
                            }
                        });
                    }
                }
    
                setUpdateStatus({ success: true, message: 'User details and roles updated successfully.' });
                setFullScreenMessage({ success: true, message: 'User details and roles updated successfully.' }); // Show full-screen message
            } else {
                throw new Error('Failed to update user details.');
            }
        } catch (error) {
            console.error('Error updating user details:', error);
            const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
            setUpdateStatus({ success: false, message: errorMessage });
            setFullScreenMessage({ success: false, message: errorMessage }); // Show full-screen message
        } finally {
            setIsLoading(false);
        }
    };
    

    const updateUserApplicationsAndRoles = async (userId) => {
        try {
            // First, clear all existing roles for the user's applications
            for (const app of applications) {
                await axios.delete(`${baseUrl}/app/access/user-roles/${userId}/${app.app_id}`, {
                    headers: {
                        Authorization: `Bearer ${tokens.accessToken}`,
                        'x-api-key': apiKey
                    }
                });
            }
    
            // Then, set the new roles and access for each application
            for (const [appName, { enabled, role }] of Object.entries(appAccess)) {
                const app = applications.find(a => a.app_name === appName);
                if (!app) continue;
    
                const roleDetails = appRoles[app.app_id]?.find(r => r.role_name === role);
                if (enabled && roleDetails) {
                    await axios.post(`${baseUrl}/app/access/manage-user-roles`, {
                        userId: userId,
                        roleId: roleDetails.role_id,
                        appId: app.app_id,
                        enabled
                    }, {
                        headers: {
                            Authorization: `Bearer ${tokens.accessToken}`,
                            'x-api-key': apiKey
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error updating user applications and roles:', error);
            throw new Error(`Error in updating user applications and roles: ${error.message}`);
        }
    };
    
    const testRevalidate = async (token) => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${baseUrl}/app/proxy/revalidate?refresh_token=${token}`, null, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'x-api-key': process.env.REACT_APP_SERVER_API_KEY
                },
            });
            console.log('Revalidation response:', response.data);
        } catch (error) {
            console.error('Error during revalidation:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    
 
    
    

    const testFetchUserDetails = async () => {
        if (!testUserId) {
            console.error('No test user ID provided');
            return;
        }
    
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseUrl}/handshake/userinfo/${testUserId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_SERVER_API_KEY
                },
            });
            console.log('Test user details:', response.data);
        } catch (error) {
            console.error('Error testing fetch user details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const clearForm = () => {
        setSelectedState(null);
        setSelectedCounty(null);
        setSearchText('');
        setSelectedUser(null);
        setUserDetails({
            user: { ...userDetails.user, addresses: [{ ...emptyAddress }] },
            organizations: [{ ...emptyOrg }]
        });
        setAppAccess({});
        // Reset other states as needed
    };
    

    useEffect(() => {
        if (selectedUser) {
            setUserDetails(prevDetails => {
                let changesMade = false;
                let newOrganizations = prevDetails.organizations.map(org => {
                    if (org.addresses.length === 0) {
                        changesMade = true;
                        return { ...org, addresses: [{ ...emptyAddress }] };
                    }
                    return org;
                });
    
                if (newOrganizations.length === 0) {
                    changesMade = true;
                    newOrganizations = [{ ...emptyOrg, addresses: [{ ...emptyAddress }] }];
                }
    
                if (changesMade) {
                    return { ...prevDetails, organizations: newOrganizations };
                }
                return prevDetails;
            });
        }
    }, [selectedUser]);

    
    
 
    return (
        <div className="user-management-container" style={{ position: 'relative' }}>
            {isLoading && <LoadingAnimation />}
            {!isLoading && updateStatus.message && (
                <div style={{ color: updateStatus.success ? 'green' : 'red' }}>
                    {updateStatus.message}
                </div>
            )}
            {fullScreenMessage && (
                <div className={`full-component-message ${fullScreenMessage.success ? '' : 'error'}`}>
                    <div className="full-component-message-content">
                        {fullScreenMessage.message}
                    </div>
                </div>
            )}
            <div className="header">User Management</div>
            <InputGroup className="search-input">
                <Form.Control
                    type="text"
                    placeholder="Search users (name, email, phone, etc.)"
                    value={searchText}
                    onChange={handleSearchChange}
                />
                {showDropdown && (
                    <Dropdown show={showDropdown} alignRight>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Search Results
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {users.map((user) => (
                                <Dropdown.Item key={user.fusionauth_user_id} onClick={() => selectUser(user)}>
                                    {`${user.first_name} ${user.last_name} - ${user.email}`}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </InputGroup>
    
            {selectedUser && userDetails.user && (
                <div>
                    <div className="county-state-select" style={{ border: '1px solid orange', borderRadius: '5px', margin: '10px', width: 'auto', padding: '10px' }}>
                        <Dropdown onSelect={handleStateSelect}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {selectedState ? selectedState.state_name : 'Select State'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {states.map(state => (
                                    <Dropdown.Item key={state.state_id} eventKey={state.state_id.toString()}>
                                        {state.state_name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
    
                        {selectedState && (
                            <Dropdown onSelect={handleCountySelect}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {selectedCounty ? selectedCounty.county_name : 'Select County'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {counties.map(county => (
                                        <Dropdown.Item key={county.county_id} eventKey={county.county_id.toString()}>
                                            {county.county_name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
    
                        <Button variant="warning" onClick={clearCountyStateSelection}>
                            Clear User County Association
                        </Button>
                    </div>
    
                    <div
                        className="app-access-section"
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            border: '1px solid orange',
                            borderRadius: '5px',
                            margin: '10px',
                            padding: '10px',
                            gap: '10px', // Adds consistent spacing between items
                        }}
                    >
                        {applications.map((app) => (
                            <Form.Group
                                key={app.app_id}
                                className="d-flex align-items-center"
                                style={{
                                    minWidth: '250px', // Ensures a minimum width for each item
                                    flex: '1 1 250px',  // Allows items to grow and shrink, wrapping as needed
                                    marginBottom: '10px', // Adds space below each item
                                }}
                            >
                                <Form.Check
                                    type="switch"
                                    id={`switch-${app.app_name}`}
                                    label={app.app_name}
                                    checked={appAccess[app.app_name]?.enabled || false}
                                    onChange={() => handleAppToggle(app.app_name)}
                                    className="custom-switch"
                                    style={{ marginRight: '10px' }} // Adds space between the switch and dropdown
                                />
                                <Dropdown
                                    onSelect={(role) => handleRoleChange(app.app_name, role)}
                                    disabled={!appAccess[app.app_name]?.enabled}
                                >
                                    <Dropdown.Toggle
                                        variant="primary"
                                        disabled={!appAccess[app.app_name]?.enabled}
                                        style={{ minWidth: '120px' }} // Ensures the dropdown has a minimum width
                                    >
                                        {appAccess[app.app_name]?.role || 'Select Role'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {appRoles[app.app_id]?.map(role => (
                                            <Dropdown.Item key={role.role_id} eventKey={role.role_name}>
                                                {role.role_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        ))}
                    </div>

    
                    <div className="user-info" style={{ border: '1px solid orange', borderRadius: '5px', margin: '10px', width: 'auto', padding: '10px' }}>
                        <Form className='infobox'>
                            <Form.Group controlId="userFusionAuthId">
                                <Form.Label className='label-bg'>FusionAuth User ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={userDetails.user.fusionauth_user_id || ''}
                                />
                            </Form.Group>
                            <Form.Group controlId="userFirstName">
                                <Form.Label className='label-bg'>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    value={userDetails.user.first_name || ''}
                                    onChange={handleUserDetailChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="userMiddleName">
                                <Form.Label className='label-bg'>Middle Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Middle Name"
                                    name="middle_name"
                                    value={userDetails.user.middle_name || ''}
                                    onChange={handleUserDetailChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="userLastName">
                                <Form.Label className='label-bg'>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    value={userDetails.user.last_name || ''}
                                    onChange={handleUserDetailChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="userEmail">
                                <Form.Label className='label-bg'>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    readOnly
                                    value={userDetails.user.email || ''}
                                />
                            </Form.Group>
                            <Form.Group controlId="userPhone">
                                <Form.Label className='label-bg'>Phone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Phone"
                                    name="phone"
                                    value={userDetails.user.phone || ''}
                                    onChange={handleUserDetailChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="userFax">
                                <Form.Label className='label-bg'>Fax</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Fax"
                                    name="fax"
                                    value={userDetails.user.fax || ''}
                                    onChange={handleUserDetailChange}
                                />
                            </Form.Group>
                            {userDetails.user.addresses.map((address, index) => (
                                <React.Fragment key={index}>
                                    <Form.Group controlId={`userAddressStreet${index}`}>
                                        <Form.Label className='label-bg'>Street Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Street Address"
                                            name="street_address"
                                            value={address.street_address || ''}
                                            onChange={(e) => handleUserDetailChange(e, index, 'street_address')}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId={`userAddressCity${index}`}>
                                        <Form.Label className='label-bg'>City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="City"
                                            name="city"
                                            value={address.city || ''}
                                            onChange={(e) => handleUserDetailChange(e, index, 'city')}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId={`userAddressState${index}`}>
                                        <Form.Label className='label-bg'>State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="State"
                                            name="state"
                                            value={address.state || ''}
                                            onChange={(e) => handleUserDetailChange(e, index, 'state')}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId={`userAddressZip${index}`}>
                                        <Form.Label className='label-bg'>Zip Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Zip Code"
                                            name="zip"
                                            value={address.zip || ''}
                                            onChange={(e) => handleUserDetailChange(e, index, 'zip')}
                                        />
                                    </Form.Group>
                                </React.Fragment>
                            ))}
                        </Form>
                    </div>
    
                    {userDetails.organizations.map((org, orgIndex) => (
                        <div key={orgIndex} className="organization-info" style={{ border: '1px solid orange', borderRadius: '5px', margin: '10px', padding: '10px' }}>
                            <Form className='infobox'>
                                <Form.Group controlId={`orgName${orgIndex}`}>
                                    <Form.Label className='label-bg'>Organization Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="business_name"
                                        value={org.business_name || ''}
                                        onChange={(e) => handleOrganizationDetailChange(e, orgIndex)}
                                    />
                                </Form.Group>
                                <Form.Group controlId={`orgFirstName${orgIndex}`}>
                                    <Form.Label className='label-bg'>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={org.first_name || ''}
                                        onChange={(e) => handleOrganizationDetailChange(e, orgIndex)}
                                    />
                                </Form.Group>
                                <Form.Group controlId={`orgMiddleName${orgIndex}`}>
                                    <Form.Label className='label-bg'>Middle Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="middle_name"
                                        value={org.middle_name || ''}
                                        onChange={(e) => handleOrganizationDetailChange(e, orgIndex)}
                                    />
                                </Form.Group>
                                <Form.Group controlId={`orgLastName${orgIndex}`}>
                                    <Form.Label className='label-bg'>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={org.last_name || ''}
                                        onChange={(e) => handleOrganizationDetailChange(e, orgIndex)}
                                    />
                                </Form.Group>
                                <Form.Group controlId={`orgPhone${orgIndex}`}>
                                    <Form.Label className='label-bg'>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={org.phone || ''}
                                        onChange={(e) => handleOrganizationDetailChange(e, orgIndex)}
                                    />
                                </Form.Group>
                                <Form.Group controlId={`orgFax${orgIndex}`}>
                                    <Form.Label className='label-bg'>Fax</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fax"
                                        value={org.fax || ''}
                                        onChange={(e) => handleOrganizationDetailChange(e, orgIndex)}
                                    />
                                </Form.Group>
    
                                {org.addresses.map((address, addrIndex) => (
                                    <React.Fragment key={addrIndex}>
                                        <Form.Group controlId={`orgStreetAddress${orgIndex}-${addrIndex}`}>
                                            <Form.Label className='label-bg'>Street Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="street_address"
                                                value={address.street_address || ''}
                                                onChange={(e) => handleOrganizationDetailChange(e, orgIndex, addrIndex, 'street_address')}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`orgCity${orgIndex}-${addrIndex}`}>
                                            <Form.Label className='label-bg'>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                value={address.city || ''}
                                                onChange={(e) => handleOrganizationDetailChange(e, orgIndex, addrIndex, 'city')}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`orgState${orgIndex}-${addrIndex}`}>
                                            <Form.Label className='label-bg'>State</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="state"
                                                value={address.state || ''}
                                                onChange={(e) => handleOrganizationDetailChange(e, orgIndex, addrIndex, 'state')}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`orgZip${orgIndex}-${addrIndex}`}>
                                            <Form.Label className='label-bg'>Zip Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="zip"
                                                value={address.zip || ''}
                                                onChange={(e) => handleOrganizationDetailChange(e, orgIndex, addrIndex, 'zip')}
                                            />
                                        </Form.Group>
                                    </React.Fragment>
                                ))}
                            </Form>
                        </div>
                    ))}
                </div>
            )}
            <Button className='btn-primary' onClick={updateUserDetails}>Update User Details</Button>
         
            {/* <Form.Group controlId="testUserId">
                <Form.Label>Test Fetch User Details</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter User ID"
                    value={testUserId}
                    onChange={(e) => setTestUserId(e.target.value)}
                />
                <Button onClick={testFetchUserDetails}>Fetch User Details</Button>
            </Form.Group>
            <Form.Group controlId="testToken">
                <Form.Label>Test Token</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Token"
                    value={testToken}
                    onChange={(e) => setTestToken(e.target.value)}
                />
                <Button onClick={() => testRevalidate(testToken)}>Test Revalidate</Button>  
            </Form.Group> */}
            
        </div>
    );
    
}

export default UserManagementComponent;