// src/components/Dashboard.js

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectTokens, selectAppRoles } from '../redux/userSlice';
import MessageComponent from './MessageComponent';
import AppPortal from './AppPortal';
import './Dashboard.css'; // Import the updated file
import SendMessageModal from './SendMessageModal';
import UserManagementModal from './UserManagementModal';
import UploadDocumentModal from './UploadDocumentModal';
import DocumentManagerModal from './DocumentManagerModal';
import SaleRegistrationsModal from './SaleRegistrationsModal';
import { 
  FaBell, 
  FaPaperPlane, 
  FaUsers, 
  FaFileUpload, 
  FaFolderOpen, 
  FaClipboardList, 
  FaChartLine 
} from 'react-icons/fa';
import EmailModal from './EmailModal';
import UserInsightsModal from './UserInsightsModal';

const Dashboard = () => {
  const tokens = useSelector(selectTokens);
  const appRoles = useSelector(selectAppRoles);
  const navigate = useNavigate();

  // When tokens are cleared (logged out), navigate to the home page.
  useEffect(() => {
    if (!tokens.accessToken) {
      navigate('/');
    }
  }, [tokens.accessToken, navigate]);

  // Updated to handle both uppercase and lowercase role keys.
  const hasPortalAdminAccess = 
    appRoles?.PORTAL === 'Portal Admin' || 
    appRoles?.portal === 'Portal Admin';

  return (
    <div className="my-dashboard-container">
      <div className="my-dashboard-item">
        <MessageComponent />
      </div>
      <div className="my-dashboard-item">
        <AppPortal tokens={tokens} />
      </div>

      {hasPortalAdminAccess && (
        <div className="my-dashboard-control-center">
          <SendMessageModal>
            <FaBell /> Send Notification
          </SendMessageModal>
          <EmailModal>
            <FaPaperPlane /> Send Email
          </EmailModal>
          <DocumentManagerModal>
            <FaFolderOpen /> Manage Documents
          </DocumentManagerModal>
          <UserManagementModal>
            <FaUsers /> Manage Users
          </UserManagementModal>
          <UploadDocumentModal>
            <FaFileUpload /> Upload Document
          </UploadDocumentModal>
          <SaleRegistrationsModal>
            <FaClipboardList /> Manage Registrations
          </SaleRegistrationsModal>
          <UserInsightsModal>
            <FaChartLine /> View Insights
          </UserInsightsModal>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
