// src/components/Properties.js

import React, { useEffect, useRef, useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Button,
  DropdownButton,
  Dropdown,
  Modal,
  ProgressBar,
  Alert,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api';

import { propertiesAPI } from '../pages/PropertiesAPI';
import './properties.css';
import '../pages/AppItem.css';
import LoadingAnimation from './LoadingAnimation';
import statesBounds from './states.json';
import PropertyDetailsModal from './PropertyDetailsModal';
import PropertiesCard from './PropertyCard'; // Ensure correct import path
import FilterForm from './FilterForms';
import 'bootstrap-icons/font/bootstrap-icons.css';
import DocumentsTable from './DocumentsTable';
import getCoordinates from './getCoordinates';

// Utilities (no checkProfileCompletion)
import {
  generateSaleId,
  isPropertyRegistered,
  handleDownload,
  getDateRange,
} from './utils';

// Redux slices
import {
  fetchUserFavorites,
  removeFavorite,
  addFavorite,
} from '../redux/favoritesSlice';

import {
  fetchUserRegistrations,
  registerForSale,
  unregisterFromSale,
} from '../redux/registrationSlice';

import {
  setSelectedState,
  setSelectedCounty,
  setSelectedSaleType,
  setSelectedTimeFrame,
  resetFilters,
} from '../redux/filterSlice';

const Properties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  // Basic user info
  const user = useSelector((state) => state.user.user);
  const userId = user?.fusionauth_user_id || null;

  // Favorites Redux
  const favorites = useSelector((state) => state.favorites.favorites);
  const favoritesLoading = useSelector((state) => state.favorites.loading);
  const favoritesError = useSelector((state) => state.favorites.error);

  // Registrations Redux
  const registrations = useSelector((state) => state.registration.registrations);
  const registrationsLoading = useSelector((state) => state.registration.loading);
  const registrationsError = useSelector((state) => state.registration.error);

  // Filter slice
  const selectedState = useSelector((state) => state.filter.selectedState);
  const selectedCounty = useSelector((state) => state.filter.selectedCounty);
  const selectedSaleType = useSelector((state) => state.filter.selectedSaleType);
  const selectedTimeFrame = useSelector((state) => state.filter.selectedTimeFrame);

  // Local states
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);
  const [saleTypes, setSaleTypes] = useState([]);
  const [propertiesData, setPropertiesData] = useState([]);
  const [showMapToggle, setShowMapToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [directions, setDirections] = useState(null);
  const [showDirections, setShowDirections] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [mapBounds, setMapBounds] = useState(null);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [detailsResponse, setDetailsResponse] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const [propertyDetailsCache, setPropertyDetailsCache] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [progress, setProgress] = useState(0);

  // Favorites pagination
  const [currentFavoritesPage, setCurrentFavoritesPage] = useState(0);
  const [favoritesSortOrder, setFavoritesSortOrder] = useState('asc');
  const [itemsPerPageFavorites, setItemsPerPageFavorites] = useState(9);
  const [showExpiredSales, setShowExpiredSales] = useState(false);

  // Auction + property detail modals
  const [showAuctionModal, setShowAuctionModal] = useState(false);
  const [showPropertyDetailsModal, setShowPropertyDetailsModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showMap, setShowMap] = useState(false);

  // Toast states
  const [showRegistrationToast, setShowRegistrationToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [missingFields, setMissingFields] = useState([]); // if needed for profile fields

  // URL parameters
  const saleIdFromUrl = searchParams.get('saleId');
  const stateFromUrl = searchParams.get('state');
  const countyFromUrl = searchParams.get('county');
  const saleTypeFromUrl = searchParams.get('saleType');
  const timeFrameFromUrl = searchParams.get('timeFrame');

  const mapRef = useRef(null);

  // Available time frames
  const timeFrames = [
    'All Future Sale Dates',
    'Past 4 Weeks',
    'Next 30 Days',
    'Next 60 Days',
    'Next 90 Days',
    'Next 6 Months',
    'Next 1 Year',
  ];

  /* -------------------------
     1) URL Effects
     ------------------------- */
  useEffect(() => {
    if (stateFromUrl && countyFromUrl && saleTypeFromUrl) {
      dispatch(setSelectedState(stateFromUrl || ''));
      dispatch(setSelectedCounty(countyFromUrl || ''));

      const saleTypeMapping = {
        foreclosure: 'F',
        deed: 'D',
        'deed sale': 'D',
        'tax sale': 'A',
        tax: 'A',
        certificate: 'C',
        'certificate sale': 'C', // fix for cert sales dropdown
        blighted: 'B',
        'blighted sale': 'B',
        'tax lien sale': 'O',
        'tax lien': 'O',
        'adjudicated sale': 'J',
        adjudicated: 'J',
        redemption: 'R',
        'redemption sale': 'R',
    };
    

      const normalizedSaleType = saleTypeFromUrl.trim().toLowerCase();
      const matchingSaleTypeId = saleTypeMapping[normalizedSaleType];
      if (matchingSaleTypeId) {
        dispatch(setSelectedSaleType(matchingSaleTypeId));
      } else {
        console.warn(`No matching sale type found for "${saleTypeFromUrl}"`);
      }
      dispatch(setSelectedTimeFrame('All Future Sale Dates'));
      if (!saleIdFromUrl) {
        console.warn('No saleId present in the URL.');
      }
    }
  }, [stateFromUrl, countyFromUrl, saleTypeFromUrl, saleIdFromUrl, dispatch]);

  // handle timeFrameFromUrl
  useEffect(() => {
    if (timeFrameFromUrl) {
      dispatch(setSelectedTimeFrame(timeFrameFromUrl));
    }
  }, [timeFrameFromUrl, dispatch]);

  /* -------------------------
     2) Data Fetches
     ------------------------- */
  const fetchProperties = async () => {
    setIsLoading(true);
    if (!selectedState || !selectedCounty || !selectedSaleType) {
      setPropertiesData([]);
      setIsLoading(false);
      return;
    }
    try {
      const dateRange = getDateRange(selectedTimeFrame);
      const reqPayload = {
        auctionDateRange: dateRange,
        auctionStyle: '',
        county: selectedCounty,
        propertySaleType: selectedSaleType,
        recordCount: 50000,
        saleStatus: '',
        searchText: '',
        startIndex: 0,
        state: selectedState,
      };
      const response = await propertiesAPI.getCardData(reqPayload);
      if (response?.properties) {
        // Deduplicate by propertyId
        const uniqueIds = new Set();
        let uniqueProps = response.properties.filter((p) => {
          if (!uniqueIds.has(p.propertyId)) {
            uniqueIds.add(p.propertyId);
            return true;
          }
          return false;
        });

        // Correctly map originalSaleId from the API response
        uniqueProps = uniqueProps.map((prop) => {
          const parsedOriginalSaleId = prop.originalSaleId != null ? Number(prop.originalSaleId) : null;
          const parsedSaleId = prop.saleId != null ? Number(prop.saleId) : null;

          return {
            ...prop,
            originalSaleId: parsedOriginalSaleId, // Retain originalSaleId even if it's 0
            generatedSaleId: parsedOriginalSaleId != null
              ? String(parsedOriginalSaleId)
              : generateSaleId(prop), // Use generateSaleId only if originalSaleId is invalid
            effectiveSaleId: parsedOriginalSaleId != null
              ? parsedOriginalSaleId // Prefer originalSaleId
              : parsedSaleId != null
              ? parsedSaleId
              : generateSaleId(prop), // Fallback to generateSaleId
          };
        });

        setPropertiesData(uniqueProps);
      } else {
        console.warn('No properties found in API response.');
      }
    } catch (err) {
      console.error('Error fetching properties:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch states + saleTypes at mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const statesData = await propertiesAPI.getStates();
        setStates(statesData);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
      try {
        const saleTypesData = await propertiesAPI.getPropertySaleTypes();
        setSaleTypes(saleTypesData);
      } catch (error) {
        console.error('Error fetching sale types:', error);
      }
    };
    fetchData();
  }, []);

  // If user logs in => fetch favorites & registrations
  useEffect(() => {
    if (userId) {
      dispatch(fetchUserFavorites());
      dispatch(fetchUserRegistrations());
    }
  }, [userId, dispatch]);

  // React to filter changes => fetch properties
  useEffect(() => {
    if (selectedState && selectedCounty && selectedSaleType) {
      fetchProperties();
    }
  }, [selectedState, selectedCounty, selectedSaleType, selectedTimeFrame]);

  // Once we have a selectedState => fetch relevant counties
  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const all = await propertiesAPI.getCounties();
        const filtered = all.filter((c) => !c.name.toLowerCase().startsWith('zzz'));
        setCounties(
          selectedState
            ? filtered.filter((c) => String(c.joinId) === selectedState)
            : filtered
        );        
      } catch (err) {
        console.error('Error fetching counties:', err);
      }
    };
    fetchCounties();
  }, [selectedState]);

  /* -------------------------
     3) Local search
     ------------------------- */
  useEffect(() => {
    if (searchText) {
      const low = searchText.toLowerCase();
      const filtered = propertiesData.filter((p) =>
        (p.address1 && p.address1.toLowerCase().includes(low)) ||
        (p.city && p.city.toLowerCase().includes(low)) ||
        (p.state && p.state.toLowerCase().includes(low)) ||
        (p.zip && p.zip.toLowerCase().includes(low)) ||
        (p.displaySaleId && p.displaySaleId.toLowerCase().includes(low)) ||
        (p.briefLegal && p.briefLegal.toLowerCase().includes(low)) ||
        (p.propertyId && p.propertyId.toString().toLowerCase().includes(low)) ||
        (p.altPropertyId && p.altPropertyId.toString().toLowerCase().includes(low))
      );
      setFilteredProperties(filtered);
    } else {
      setFilteredProperties(propertiesData);
    }
  }, [searchText, propertiesData]);

  /* -------------------------
     4) Favorite/Registration Errors -> Toast
     ------------------------- */
  useEffect(() => {
    if (favoritesError) {
      if (
        typeof favoritesError === 'object' &&
        favoritesError.error === 'INCOMPLETE_PROFILE'
      ) {
        setToastMessage('Your profile is incomplete. Please complete your profile first.');
        // If you have missingFields => setMissingFields(favoritesError.missingFields || []);
        setShowRegistrationToast(true);
      } else if (typeof favoritesError === 'string') {
        setToastMessage(favoritesError);
        setShowRegistrationToast(true);
      }
    }
  }, [favoritesError]);

  useEffect(() => {
    if (registrationsError) {
      if (
        typeof registrationsError === 'object' &&
        registrationsError.error === 'INCOMPLETE_PROFILE'
      ) {
        setToastMessage('Your profile is incomplete. Please complete your profile first.');
        // If missing fields => setMissingFields(registrationsError.missingFields || []);
        setShowRegistrationToast(true);
      } else if (registrationsError === 'ALREADY_REGISTERED') {
        setToastMessage('You are already registered for this sale.');
        setShowRegistrationToast(true);
      } else if (typeof registrationsError === 'string') {
        setToastMessage(registrationsError);
        setShowRegistrationToast(true);
      }
    }
  }, [registrationsError]);

  /* -------------------------
     5) Switch toggles
     ------------------------- */
  useEffect(() => {
    if (showFavoritesOnly && userId) {
      console.log('Favorites only ON');
    }
  }, [showFavoritesOnly, userId]);

  /* -------------------------
     6) Markers / Map
     ------------------------- */
  
  const onMapLoad = (map) => {
    mapRef.current = map;
    if (mapBounds) {
      map.fitBounds(mapBounds);
      const center = mapBounds.getCenter();
      map.setCenter(center);
    }
  };

  useEffect(() => {
    if (mapRef.current && mapBounds) {
      mapRef.current.fitBounds(mapBounds);
      const center = mapBounds.getCenter();
      mapRef.current.setCenter(center);
    }
  }, [mapBounds]);

  const handleMarkerClick = (property) => {
    // Since PropertiesCard handles fetching propertyInfo, we pass it here
    // Assuming property.propertyInfo is already available
    handleDetailsClick(property, property.propertyInfo, null); // Pass propertyInfo if available
  };

  const fetchDirections = (start, end) => {
    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: start,
        destination: end,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
          setShowDirections(true);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      }
    );
  };

  const handleGetDirections = (prop) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const start = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const end = {
            lat: prop.latitude,
            lng: prop.longitude,
          };
          fetchDirections(start, end);
        },
        () => {
          console.error('Error getting user location');
        }
      );
    } else {
      console.error('Geolocation not supported');
    }
  };

  /* -------------------------
     7) Filter + Helpers
     ------------------------- */
  const handleStateChange = (e) => {
    const st = e.target.value;
    dispatch(setSelectedState(st));
    dispatch(setSelectedCounty(''));

    const sb = statesBounds.find((item) => item.fips === st)?.bounds;
    if (sb) {
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(sb[0][1], sb[0][0]),
        new window.google.maps.LatLng(sb[1][1], sb[1][0])
      );
      setMapBounds(bounds);
    } else {
      setMapBounds(null);
    }
  };

  const resetDropdowns = () => {
    dispatch(resetFilters());
  };

  // Expired Sales
  const filterExpiredSales = (arr) => {
    const futureTimeFrames = [
      'All Future Sale Dates',
      'Next 30 Days',
      'Next 60 Days',
      'Next 90 Days',
      'Next 6 Months',
      'Next 1 Year',
    ];
    // Only apply filter for future Time Frames
    if (!futureTimeFrames.includes(selectedTimeFrame)) {
      return arr;
    }
    
    // Take "today" at midnight
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    return arr.filter((p) => {
      // Also set property’s auctionDate to midnight
      const ad = new Date(p.auctionDate);
      ad.setHours(0, 0, 0, 0);
  
      // Now compare date-only
      return showExpiredSales || ad.getTime() >= today.getTime();
    });
  };
  

  // Grouping for rendering
  const groupPropertiesBySaleIdAndDate = (arr) => {
    return arr.reduce((acc, p) => {
      const saleId = p.originalSaleId ?? p.saleId; // Use nullish coalescing
      if (saleId === null || saleId === undefined) return acc;
      const key = `${saleId}-${p.auctionDate}`;
      acc[key] = acc[key] || [];
      acc[key].push(p);
      return acc;
    }, {});
  };

  const getPaginatedProperties = (arr, saleIdDateKey, page, itemsPerPage = 9) => {
    const startIndex = page * itemsPerPage;
    return arr.slice(startIndex, startIndex + itemsPerPage);
  };

  /* -------------------------
     8) handleDetailsClick
      ------------------------- */
  // Updated to accept propertyInfo directly
  const handleDetailsClick = async (property, propertyInfo, e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (!property) return;
  
    try {
      // Fetch the full details response (which includes auctionDetail)
      const fullDetails = await propertiesAPI.getPropertyDetail({
        id: property.id,
        propertyId: property.propertyId,
        saleId: Number(property.originalSaleId ?? property.saleId),
        saleType: property.saleType,
      });
  
      // Merge auctionDetail from the full details into the property object
      const updatedProperty = { ...property, auctionDetail: fullDetails.auctionDetail };
  
      // Set the full details response and propertyInfo (if needed)
      setDetailsResponse(fullDetails);
      setPropertyDetails(fullDetails.propertyInfo);
  
      // Now set the selectedProperty with the auctionDetail merged in
      setSelectedProperty(updatedProperty);
    } catch (error) {
      console.error("Error fetching property details:", error);
      // Optionally, set error state or show a toast here
    }
    
    setShowPropertyDetailsModal(true);
  };
  

  /* -------------------------
     9) Registration + Favorites
     ------------------------- */
  const isPropertyFavoriteFunc = useCallback(
    (property) => {
      if (!property || !favorites) return false;
      return favorites.some((fav) => fav.property_id === property.propertyId);
    },
    [favorites]
  );

  const handleRegisterOrUnregisterClick = async ({ saleId, auction }) => {
    if (!userId) {
      setToastMessage('Please log in to register/unregister.');
      setShowRegistrationToast(true);
      return;
    }
    if (isPropertyRegistered(auction, registrations)) {
      // Unregister
      const action = await dispatch(unregisterFromSale(saleId));
      if (unregisterFromSale.rejected.match(action)) {
        const payload = action.payload;
        if (typeof payload === 'object' && payload.error === 'INCOMPLETE_PROFILE') {
          setToastMessage('Your profile is incomplete. Please complete your profile first.');
        } else if (typeof payload === 'string') {
          setToastMessage(payload);
        }
        setShowRegistrationToast(true);
      }
    } else {
      // Register
      const action = await dispatch(registerForSale({ saleId, auction }));
      if (registerForSale.rejected.match(action)) {
        const payload = action.payload;
        if (typeof payload === 'object' && payload.error === 'INCOMPLETE_PROFILE') {
          setToastMessage('Your profile is incomplete. Please complete your profile first.');
        } else if (typeof payload === 'string') {
          setToastMessage(payload);
        }
        setShowRegistrationToast(true);
      }
    }
  };

  // Add a favorite
  const handleAddFavorite = async (property) => {
    if (!userId) {
      setToastMessage('Please log in to add favorites.');
      setShowRegistrationToast(true);
      return;
    }
    const action = await dispatch(addFavorite(property));
    if (addFavorite.rejected.match(action)) {
      const payload = action.payload;
      if (typeof payload === 'object' && payload.error === 'INCOMPLETE_PROFILE') {
        setToastMessage('Your profile is incomplete. Please complete your profile first.');
      } else if (typeof payload === 'string') {
        setToastMessage(payload);
      }
      setShowRegistrationToast(true);
    }
  };

  // Remove a favorite
  const handleRemoveFavorite = async (favoriteId) => {
    if (!userId) {
      setToastMessage('Please log in to remove favorites.');
      setShowRegistrationToast(true);
      return;
    }
    const action = await dispatch(removeFavorite(favoriteId));
    if (removeFavorite.rejected.match(action)) {
      const payload = action.payload;
      if (typeof payload === 'object' && payload.error === 'INCOMPLETE_PROFILE') {
        setToastMessage('Your profile is incomplete. Please complete your profile first.');
      } else if (typeof payload === 'string') {
        setToastMessage(payload);
      }
      setShowRegistrationToast(true);
    }
  };

  // Favorites rendering
  const handleFavoritesPageChange = (page) => {
    setCurrentFavoritesPage(page);
  };
  const getPaginatedFavorites = (arr) => {
    const startIndex = currentFavoritesPage * itemsPerPageFavorites;
    return arr.slice(startIndex, startIndex + itemsPerPageFavorites);
  };
  const sortFavorites = useCallback(
    (arr) => {
      if (!Array.isArray(arr)) return [];
      return [...arr].sort((a, b) => {
        const dateA = new Date(a.auctionDate);
        const dateB = new Date(b.auctionDate);
        return favoritesSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
    },
    [favoritesSortOrder]
  );

  const renderFavorites = () => {
    if (!favorites || favorites.length === 0) {
      return <div>No favorite properties found.</div>;
    }
    const sorted = sortFavorites(favorites);
    const paginated = getPaginatedFavorites(sorted);

    return (
      <div className="favorites-container">
        <h3>Favorite Properties</h3>
        <div className="d-flex flex-wrap justify-content-center">
          {paginated.map((fav, idx) => {
            const saleId = fav.originalSaleId ?? fav.saleId; // Use nullish coalescing
            const isFav = isPropertyFavoriteFunc(fav);
            const isReg = isPropertyRegistered(fav, registrations);

            return (
              <PropertiesCard
                key={fav.favorite_id || idx}
                property={fav}
                favoriteId={fav.favorite_id}
                originalSaleId={fav.originalSaleId} // Pass originalSaleId
                handleDetailsClick={(property, propertyInfo, e) => handleDetailsClick(property, propertyInfo, e)} // Pass propertyInfo
                handleRegisterOrUnregisterClick={() => {
                  if (saleId === null || saleId === undefined || isNaN(Number(saleId))) {
                    setToastMessage('Invalid sale ID for this favorite.');
                    setShowRegistrationToast(true);
                    return;
                  }
                  handleRegisterOrUnregisterClick({ saleId, auction: fav });
                }}
                isPropertyFavorite={isPropertyFavoriteFunc}
                isRegistered={isReg}
                getFavoriteButtonLabel={() =>
                  isPropertyFavoriteFunc(fav) ? 'Unfavorite' : 'Favorite'
                }
                getRegisterButtonLabel={() =>
                  isPropertyRegistered(fav, registrations) ? 'Unregister' : 'Register'
                }
                removeFavoriteFunc={() => {
                  if (!fav.favorite_id) {
                    setToastMessage('No favorite_id found.');
                    setShowRegistrationToast(true);
                    return;
                  }
                  handleRemoveFavorite(fav.favorite_id);
                }}
                addFavoriteFunc={() => handleAddFavorite(fav)}
                userRegistrations={registrations}
                propIndex={idx} // Pass propIndex
              />
            );
          })}
        </div>
        {favorites.length > itemsPerPageFavorites && (
          <div className="pagination-controls d-flex justify-content-center align-items-center mt-3">
            <Button
              variant="secondary"
              onClick={() => handleFavoritesPageChange(currentFavoritesPage - 1)}
              disabled={currentFavoritesPage === 0}
              className="mx-2"
            >
              Previous
            </Button>
            <span>{`Page ${currentFavoritesPage + 1} of ${Math.ceil(
              favorites.length / itemsPerPageFavorites
            )}`}</span>
            <Button
              variant="secondary"
              onClick={() => handleFavoritesPageChange(currentFavoritesPage + 1)}
              disabled={
                (currentFavoritesPage + 1) * itemsPerPageFavorites >= favorites.length
              }
              className="mx-2"
            >
              Next
            </Button>
          </div>
        )}
      </div>
    );
  };

  // Rendering main property list
  const renderProperties = () => {
    let propsArr = filteredProperties.length > 0 ? filteredProperties : propertiesData;
    if (propsArr.length === 0) {
      return <div>No properties match your search.</div>;
    }

    propsArr = filterExpiredSales(propsArr);
    const grouped = groupPropertiesBySaleIdAndDate(propsArr);

    const sortedGroups = Object.keys(grouped)
      .map((key) => ({
        saleIdDateKey: key,
        auctionDate: key.split('-').slice(1).join('-'),
        properties: grouped[key],
      }))
      .sort((a, b) => new Date(a.auctionDate) - new Date(b.auctionDate));

    return sortedGroups.map((grp) => {
      const pList = grp.properties;
      if (!pList || pList.length === 0) return null;

      const first = pList[0];
      const saleIdDateKey = grp.saleIdDateKey;
      const currentPageForDate = currentPage[saleIdDateKey] || 0;
      const totalPages = Math.ceil(pList.length / 9);
      const paginated = getPaginatedProperties(pList, saleIdDateKey, currentPageForDate, 9);

      return (
        <div className="county-details mb-5" key={saleIdDateKey}>
          <h3>
            {`${first.county}, ${first.state} - ${first.type} ${first.saleTypeDescription} - ${first.auctionDate}`}
          </h3>

          <div className="download-list-button-container text-center mb-3">
            <DropdownButton
              id="dropdown-basic-button"
              className="custom-dropdown-button"
              title="Download List"
              container={document.body}
            >
              <Dropdown.Item
                onClick={() =>
                  handleDownload(
                    first.effectiveSaleId,
                    'excel',
                    first.county,
                    first.saleTypeDescription,
                    new Date().toISOString().split('T')[0],
                    selectedState,
                    selectedTimeFrame
                  )
                }
              >
                Excel
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleDownload(
                    first.effectiveSaleId,
                    'pdf',
                    first.county,
                    first.saleTypeDescription,
                    new Date().toISOString().split('T')[0],
                    selectedState,
                    selectedTimeFrame
                  )
                }
              >
                PDF
              </Dropdown.Item>
            </DropdownButton>
          </div>

          {totalPages > 1 && (
            <div className="pagination-controls d-flex justify-content-center align-items-center mb-3">
              <Button
                variant="secondary"
                onClick={() =>
                  setCurrentPage((prev) => ({
                    ...prev,
                    [saleIdDateKey]: currentPageForDate - 1,
                  }))
                }
                disabled={currentPageForDate === 0}
                className="mx-2"
              >
                Previous
              </Button>
              <span>{`Page ${currentPageForDate + 1} of ${totalPages}`}</span>
              <Button
                variant="secondary"
                onClick={() =>
                  setCurrentPage((prev) => ({
                    ...prev,
                    [saleIdDateKey]: currentPageForDate + 1,
                  }))
                }
                disabled={currentPageForDate + 1 >= totalPages}
                className="mx-2"
              >
                Next
              </Button>
            </div>
          )}

          <div className="property-cards-container d-flex flex-wrap justify-content-center">
            {paginated.map((property, idx) => {
              const favorite = favorites.find((f) => f.property_id === property.propertyId);
              const favoriteId = favorite ? favorite.favorite_id : undefined;
              const saleId = property.originalSaleId ?? property.saleId; // Use nullish coalescing

              return (
                <PropertiesCard
                  key={favoriteId || `${property.propertyId}-${idx}`}
                  property={property}
                  favoriteId={favoriteId}
                  originalSaleId={property.originalSaleId} // Pass originalSaleId
                  handleDetailsClick={(property, propertyInfo, e) => handleDetailsClick(property, propertyInfo, e)} // Pass propertyInfo
                  handleRegisterOrUnregisterClick={() => {
                    if (saleId === null || saleId === undefined || isNaN(Number(saleId))) {
                      setToastMessage('No valid saleId.');
                      setShowRegistrationToast(true);
                      return;
                    }
                    handleRegisterOrUnregisterClick({ saleId, auction: property });
                  }}
                  isPropertyFavorite={isPropertyFavoriteFunc}
                  isRegistered={isPropertyRegistered(property, registrations)}
                  getFavoriteButtonLabel={() =>
                    isPropertyFavoriteFunc(property) ? 'Unfavorite' : 'Favorite'
                  }
                  getRegisterButtonLabel={() =>
                    isPropertyRegistered(property, registrations) ? 'Unregister' : 'Register'
                  }
                  removeFavoriteFunc={() => {
                    if (!favoriteId) {
                      setToastMessage('No favorite_id.');
                      setShowRegistrationToast(true);
                      return;
                    }
                    handleRemoveFavorite(favoriteId);
                  }}
                  addFavoriteFunc={() => handleAddFavorite(property)}
                  userRegistrations={registrations}
                  propIndex={idx} // Pass propIndex
                />
              );
            })}
          </div>

          {totalPages > 1 && (
            <div className="pagination-controls d-flex justify-content-center align-items-center mt-3">
              <Button
                variant="secondary"
                onClick={() =>
                  setCurrentPage((prev) => ({
                    ...prev,
                    [saleIdDateKey]: currentPageForDate - 1,
                  }))
                }
                disabled={currentPageForDate === 0}
                className="mx-2"
              >
                Previous
              </Button>
              <span>{`Page ${currentPageForDate + 1} of ${totalPages}`}</span>
              <Button
                variant="secondary"
                onClick={() =>
                  setCurrentPage((prev) => ({
                    ...prev,
                    [saleIdDateKey]: currentPageForDate + 1,
                  }))
                }
                disabled={currentPageForDate + 1 >= totalPages}
                className="mx-2"
              >
                Next
              </Button>
            </div>
          )}
        </div>
      );
    });
  };

    /* -------------------------
       10) JSX Return
       ------------------------- */
    return (
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
        {/* Toast Container placed outside the scrollable container */}
        <ToastContainer
          className="p-3"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            pointerEvents: 'none', // Allows clicks to pass through
          }}
        >
          <Toast
            onClose={() => setShowRegistrationToast(false)}
            show={showRegistrationToast}
            delay={5000}
            autohide
            bg="warning"
            style={{ pointerEvents: 'auto' }} // Allows interaction with the toast
          >
            <Toast.Header>
              <strong className="me-auto">Notice</strong>
            </Toast.Header>
            <Toast.Body>
              {/* Show the main text message */}
              <div style={{ marginBottom: '6px' }}>{toastMessage}</div>
              {/* If we had missing fields, we could show them like: */}
              {missingFields.length > 0 && (
                <>
                  <div style={{ fontWeight: 'bold' }}>Missing:</div>
                  <ul>
                    {missingFields.map((f) => (
                      <li key={f}>{f}</li>
                    ))}
                  </ul>
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={() => navigate('/profile')}
                  >
                    Complete Profile
                  </Button>
                </>
              )}
            </Toast.Body>
          </Toast>
        </ToastContainer>

        <center>
          <Button
            variant="primary"
            onClick={() => setShowDocumentModal(true)}
            className="custom-download-button my-3"
          >
            <i className="bi bi-download" style={{ marginRight: '5px' }}></i>
            SB 156 Documents
          </Button>
        </center>

        <div className="container">
          {/* Filter Form */}
          <center>
            <FilterForm
              states={states}
              selectedState={selectedState}
              handleStateChange={handleStateChange}
              counties={counties}
              selectedCounty={selectedCounty}
              setSelectedCounty={(c) => dispatch(setSelectedCounty(c))}
              saleTypes={saleTypes}
              selectedSaleType={selectedSaleType}
              setSelectedSaleType={(st) => dispatch(setSelectedSaleType(st))}
              timeFrames={timeFrames}
              selectedTimeFrame={selectedTimeFrame}
              handleTimeFrameChange={(e) => dispatch(setSelectedTimeFrame(e.target.value))}
              resetDropdowns={resetDropdowns}
            />
          </center>

          {/* Warnings if any filter is missing */}
          {!selectedState && !selectedCounty && !selectedSaleType && (
            <Alert variant="warning">
              Please select <b>State</b>, <b>County</b>, and <b>Sale Type</b>.
            </Alert>
          )}
          {selectedState && !selectedCounty && !selectedSaleType && (
            <Alert variant="warning">
              Please select <b>County</b> and <b>Sale Type</b>.
            </Alert>
          )}
          {selectedState && selectedCounty && !selectedSaleType && (
            <Alert variant="warning">
              Please select <b>Sale Type</b>.
            </Alert>
          )}

          {/* If properties exist, show search bar */}
          {propertiesData.length > 0 && (
            <div className="search-container mb-3 d-flex justify-content-center">
              <Form.Control
                type="text"
                placeholder="Search properties..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="mr-2 w-50"
              />
              <Button variant="outline-secondary" onClick={() => setSearchText('')}>
                Clear Search
              </Button>
            </div>
          )}

          {/* Loading bar if needed */}
          {isLoading && <ProgressBar now={progress} label={`${Math.round(progress)}%`} />}

          <Form.Check
            type="switch"
            id="map-view-switch"
            label="Map View"
            checked={showMapToggle}
            onChange={() => setShowMapToggle(!showMapToggle)}
            className="mb-3 d-flex justify-content-center"
          />
          {userId && (
            <Form.Check
              type="switch"
              id="favorites-switch"
              label="Show Favorites Only"
              checked={showFavoritesOnly}
              onChange={() => setShowFavoritesOnly(!showFavoritesOnly)}
              className="mb-3 d-flex justify-content-center"
            />
          )}

          {/* Show loading animations if favorites/registrations are loading */}
          {registrationsLoading && <LoadingAnimation />}
          {favoritesLoading && <LoadingAnimation />}

          {/* Main content => either map or list */}
          {showMapToggle ? (
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '400px' }}
              center={{ lat: 39.5, lng: -98.35 }}
              zoom={4}
              onLoad={onMapLoad}
              bounds={mapBounds}
            >
              {filteredProperties.map((property, index) => {
                const pos =
                  property.latitude && property.longitude
                    ? { lat: property.latitude, lng: property.longitude }
                    : property.coordinates
                    ? { lat: property.coordinates.lat, lng: property.coordinates.lng }
                    : null;
                if (!pos) {
                  console.warn(`Property ${property.propertyId} has invalid coords.`);
                  return null;
                }
                return (
                  <Marker
                    key={`${property.propertyId}-${index}`}
                    position={pos}
                    onClick={() => handleMarkerClick(property)}
                  />
                );
              })}
              {showDirections && <DirectionsRenderer directions={directions} />}
            </GoogleMap>
          ) : showFavoritesOnly ? (
            renderFavorites()
          ) : (
            renderProperties()
          )}

          {/* Property Details Modal */}
          <PropertyDetailsModal
            showModal={showPropertyDetailsModal}
            setShowModal={setShowPropertyDetailsModal}
            selectedProperty={selectedProperty}
            detailsResponse={detailsResponse}
            propertyDetails={propertyDetails}
            propertyDocuments={propertyDocuments}
            setPropertyDocuments={setPropertyDocuments}
            showMap={showMap}
            showDirections={showDirections}
            directions={directions}
            handleGetDirections={handleGetDirections}
            isPropertyRegistered={(prop) => isPropertyRegistered(prop, registrations)}
            getRegisterButtonLabel={(prop) =>
              isPropertyRegistered(prop, registrations) ? 'Unregister' : 'Register'
            }
            handleRegisterOrUnregisterClick={() => {
              if (!selectedProperty || selectedProperty.originalSaleId === null || selectedProperty.originalSaleId === undefined) {
                setToastMessage('No valid saleId to register/unregister');
                setShowRegistrationToast(true);
                return;
              }
              handleRegisterOrUnregisterClick({
                saleId: selectedProperty.originalSaleId, // Use originalSaleId here
                auction: selectedProperty,
              });
            }}
            isPropertyFavorite={isPropertyFavoriteFunc}
            getFavoriteButtonLabel={(prop) =>
              isPropertyFavoriteFunc(prop) ? 'Unfavorite' : 'Favorite'
            }
            handleFavoriteClick={() => {
              if (!selectedProperty) return;
              if (isPropertyFavoriteFunc(selectedProperty)) {
                const fav = favorites.find(
                  (f) => f.property_id === selectedProperty.propertyId
                );
                if (!fav?.favorite_id) {
                  setToastMessage('No favorite_id to remove.');
                  setShowRegistrationToast(true);
                  return;
                }
                handleRemoveFavorite(fav.favorite_id);
              } else {
                handleAddFavorite(selectedProperty);
              }
            }}
          />
        </div>

        {/* SB 156 Documents Modal */}
        <Modal
          show={showDocumentModal}
          onHide={() => setShowDocumentModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>SB 156 Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DocumentsTable />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDocumentModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Auction List Modal */}
        <Modal
          show={showAuctionModal}
          onHide={() => setShowAuctionModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Auction List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* AuctionList or relevant component here */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAuctionModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </LoadScript>
    );

}

export default Properties;
