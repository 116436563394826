import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { FusionAuthProvider } from "@fusionauth/react-sdk";
import Navbar from "./pages/navbar";
import SecondaryNavbar from "./pages/secondaryNavbar";
import LandingPage from "./pages/LandingPage";
import Company from "./pages/Company";
import HowWeWork from "./pages/HowWeWork";
import Partners from "./pages/Partners";
import Investing from "./pages/Investing";
import Support from "./pages/Support";
import Services from "./pages/Services";
import LienAssist from "./pages/lienAssist";
import TMS from "./pages/TMS";
import Zeus from "./pages/Zeus";
import Sheriff from "./pages/Sheriff";
import Links from "./pages/Links";
import Dashboard from "./pages/Dashboard";
import Properties from "./pages/Properties";
import Profile from "./pages/Profile";
import MondayBoards from "./pages/MondayBoards";
import HubSpot from "./pages/HubSpot";
import DocumentsTable from "./pages/DocumentsTable";
import ValidationComponent from "./pages/ValidationComponent";
import InactivityTimer from "./InactivityTimer"; // Import the InactivityTimer component
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store"; // Ensure the path is correct
import styles from "./css/Footer.module.css";
import { setupStorageListener } from "./redux/userSlice";

// Helper component to set up cross-tab synchronization.
const StorageListener = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Set up the storage listener and get the cleanup function.
    const cleanup = setupStorageListener(dispatch);
    // Return the cleanup function on unmount.
    return cleanup;
  }, [dispatch]);
  return null;
};

function App() {
  const [isEnvReady, setIsEnvReady] = useState(false);

  const retryLoadEnvVariable = async (
    envVariableKey,
    maxRetries = 5,
    initialDelay = 700
  ) => {
    let retries = 0;
    let delay = initialDelay;
    while (retries < maxRetries) {
      const value = process.env[envVariableKey];
      if (value) {
        return value;
      } else {
        retries++;
        await new Promise((resolve) => setTimeout(resolve, delay));
        delay *= 2; // Exponential backoff
      }
    }
    throw new Error(
      `Environment variable ${envVariableKey} is undefined after ${maxRetries} retries.`
    );
  };

  useEffect(() => {
    const loadEnvVariables = async () => {
      try {
        // Wait for all critical environment variables to be loaded.
        await retryLoadEnvVariable("REACT_APP_CLIENT_ID");
        await retryLoadEnvVariable("REACT_APP_SERVER_URL");
        await retryLoadEnvVariable("REACT_APP_FRONT_END_REDIRECT_URL");
        await retryLoadEnvVariable("REACT_APP_GOOGLE_MAPS_API");
        await retryLoadEnvVariable("REACT_APP_SERVER_API_KEY");
        await retryLoadEnvVariable("REACT_APP_MONDAY_API_KEY");
        await retryLoadEnvVariable("REACT_APP_SRI_APP_API_KEY");
        await retryLoadEnvVariable("REACT_APP_BLOB_CONNECTION_STRING");
        await retryLoadEnvVariable("REACT_APP_TSM_URL");
        setIsEnvReady(true);
      } catch (error) {
        // Handle error silently.
      }
    };

    loadEnvVariables();
  }, []);

  if (!isEnvReady) {
    return <div>Loading environment variables...</div>;
  }

  const config = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    serverUrl: process.env.REACT_APP_SERVER_URL,
    redirectUri: process.env.REACT_APP_FRONT_END_REDIRECT_URL,
    shouldAutoFetchUserInfo: true,
    shouldAutoRefresh: true,
  };

  return (
    <Provider store={store}>
      <Router>
        <FusionAuthProvider {...config}>
          {/* Set up cross-tab logout synchronization */}
          <StorageListener />
          {/* Wrap the main application with InactivityTimer */}
          <InactivityTimer>
            <SecondaryNavbar />
            <Navbar />
            <div className={styles.appContainer}>
              <div className={styles.mainContent}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/company" element={<Company />} />
                  <Route path="/work" element={<HowWeWork />} />
                  <Route path="/partners" element={<Partners />} />
                  <Route path="/investing" element={<Investing />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/lienAssist" element={<LienAssist />} />
                  <Route path="/tms" element={<TMS />} />
                  <Route path="/zeus" element={<Zeus />} />
                  <Route path="/sheriff" element={<Sheriff />} />
                  <Route path="/links" element={<Links />} />
                  <Route path="/monday" element={<MondayBoards />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/hubspot" element={<HubSpot />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/properties" element={<Properties />} />
                  <Route path="/documents" element={<DocumentsTable />} />
                  {/* Add more routes as needed */}
                </Routes>
              </div>
            </div>
          </InactivityTimer>
        </FusionAuthProvider>
      </Router>
    </Provider>
  );
}

export default App;
