// src/utils.js

import { propertiesAPI } from '../pages/PropertiesAPI';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';


// Mapping from descriptive sale type text to single-letter codes
const saleTypeMapping = {
  "foreclosure": "F",
  "deed": "D",
  "deed sale": "D",
  "tax sale": "A",
  "tax": "A",
  "certificate": "C",
  "certificate sale": "C",  
  "blighted": "B",
  "blighted sale": "B",
  "tax lien sale": "O",
  "tax lien": "O",
  "adjudicated sale": "J",
  "adjudicated": "J",
  "redemption": "R",
  "redemption sale": "R",
};

/**
 * Looks at the auction's saleType text (e.g. "Foreclosure", "Deed Sale", etc.)
 * and returns the matching single-letter code from `saleTypeMapping`.
 * If no match is found, returns "Unknown".
 */
export const getSaleTypeDescription = (saleType) => {
  if (!saleType) return 'Unknown';
  const normalized = saleType.toLowerCase().trim();
  return saleTypeMapping[normalized] || 'Unknown';
};

/**
 * Generates a unique saleId from saleTypeDescription, date, county.
 */
export const generateSaleId = (auction) => {
  console.log("Generating saleId for auction:", auction);

  if (!auction || !auction.auctionDate) {
    console.error("Missing or invalid auctionDate:", auction);
    return null;
  }
  if (!auction.saleTypeDescription) {
    console.error("Missing saleTypeDescription:", auction);
    return null;
  }

  let year, month, day;

  // Check if auctionDate is in ISO format
  if (auction.auctionDate.includes('T')) {
    // AuctionDate is "YYYY-MM-DDTHH:mm:ss"
    const datePart = auction.auctionDate.split('T')[0];
    [year, month, day] = datePart.split('-');
  } else if (auction.auctionDate.includes('/')) {
    // Assume "MM/DD/YYYY"
    [month, day, year] = auction.auctionDate.split('/');
  } else {
    console.error("Unsupported date format for auctionDate:", auction.auctionDate);
    return null;
  }

  if (!year || !month || !day) {
    console.error("Cannot parse date from auctionDate:", auction.auctionDate);
    return null;
  }

  const formatted = `${month.padStart(2, '0')}_${day.padStart(2, '0')}_${year}`;

  // If saleId=0, '0', or null => generate
  if (
    auction.saleId === 0 ||
    auction.saleId === '0' ||
    auction.saleId === null ||
    auction.saleId === undefined
  ) {
    return `${auction.saleTypeDescription.toLowerCase()}-${formatted}-${auction.county}`
      .replace(/\s+/g, '-');
  } else {
    return String(auction.saleId).toLowerCase();
  }
};

/**
 * Checks if a property is registered, based on user registrations.
 */
export const isPropertyRegistered = (property, userRegistrations) => {
  if (!property || !userRegistrations || !property.auctionDate) {
    return false;
  }

  // Generate a consistent saleId
  const saleId = generateSaleId(property);
  if (!saleId) return false;

  // Compare saleId with user registrations
  return userRegistrations.some(reg => String(reg.sale_id) === saleId);
};


/**
 * Basic state name -> abbreviation
 */
export const stateNameToAbbreviation = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY",
};

/**
 * Basic abbreviation -> IANA time zone
 */
export const timeZoneToIana = {
  AL: "America/Chicago",
  AK: "America/Anchorage",
  AZ: "America/Phoenix",
  AR: "America/Chicago",
  CA: "America/Los_Angeles",
  CO: "America/Denver",
  CT: "America/New_York",
  DE: "America/New_York",
  FL: "America/New_York",
  GA: "America/New_York",
  HI: "Pacific/Honolulu",
  ID: "America/Boise",
  IL: "America/Chicago",
  IN: "America/Indiana/Indianapolis",
  IA: "America/Chicago",
  KS: "America/Chicago",
  KY: "America/New_York",
  LA: "America/Chicago",
  ME: "America/New_York",
  MD: "America/New_York",
  MA: "America/New_York",
  MI: "America/Detroit",
  MN: "America/Chicago",
  MS: "America/Chicago",
  MO: "America/Chicago",
  MT: "America/Denver",
  NE: "America/Chicago",
  NV: "America/Los_Angeles",
  NH: "America/New_York",
  NJ: "America/New_York",
  NM: "America/Denver",
  NY: "America/New_York",
  NC: "America/New_York",
  ND: "America/Chicago",
  OH: "America/New_York",
  OK: "America/Chicago",
  OR: "America/Los_Angeles",
  PA: "America/New_York",
  RI: "America/New_York",
  SC: "America/New_York",
  SD: "America/Chicago",
  TN: "America/Chicago",
  TX: "America/Chicago",
  UT: "America/Denver",
  VT: "America/New_York",
  VA: "America/New_York",
  WA: "America/Los_Angeles",
  WV: "America/New_York",
  WI: "America/Chicago",
  WY: "America/Denver",
};

// Indiana counties -> "Central" or "Eastern"
export const indianaCountyToTimeZone = {
  "Central": [
    "Gibson", "Jasper", "Lake", "LaPorte", "Newton", "Porter",
    "Posey", "Spencer", "Vanderburgh", "Warrick"
  ],
  "Eastern": [
    "Adams", "Allen", "Bartholomew", "Benton", "Blackford", "Boone",
    "Brown", "Carroll", "Cass", "Clark", "Clay", "Clinton",
    "Crawford", "Daviess", "Dearborn", "Decatur", "DeKalb",
    "Delaware", "Dubois", "Elkhart", "Fayette", "Floyd", "Fountain",
    "Franklin", "Fulton", "Grant", "Greene", "Hamilton", "Hancock",
    "Harrison", "Hendricks", "Henry", "Howard", "Huntington",
    "Jackson", "Jay", "Jefferson", "Jennings", "Johnson", "Knox",
    "Kosciusko", "Lagrange", "Lawrence", "Madison", "Marion",
    "Marshall", "Martin", "Miami", "Monroe", "Montgomery", "Morgan",
    "Noble", "Ohio", "Orange", "Owen", "Parke", "Perry", "Pike",
    "Pulaski", "Putnam", "Randolph", "Ripley", "Rush", "Scott",
    "Shelby", "St. Joseph", "Starke", "Steuben", "Sullivan",
    "Switzerland", "Tippecanoe", "Tipton", "Union", "Vermillion",
    "Vigo", "Wabash", "Warren", "Washington", "Wayne", "Wells",
    "White", "Whitley"
  ]
};

/**
 * Interprets `auctionDate` (YYYY-MM-DDTHH:mm:ss) as if it’s local time in the county,
 * then returns:
 *  - saleLocalTime (the county’s local time in mm/dd/yyyy, hh:mm AM/PM)
 *  - userLocalTime (the user’s local machine time in mm/dd/yyyy, hh:mm AM/PM)
 */
export const processAuctionDate = (auctionDate, state, county) => {
  if (!auctionDate || !state) {
    console.warn("Missing auctionDate or state:", auctionDate, state);
    return {
      saleLocalTime: 'Invalid Date/Time',
      userLocalTime: 'Invalid Date/Time'
    };
  }

  // Convert full state -> abbr
  const stateAbbr = stateNameToAbbreviation[state] || state.toUpperCase();
  let ianaTimeZone = "UTC";

  // If Indiana, check county
  if (stateAbbr === "IN") {
    if (county) {
      const isCentral = indianaCountyToTimeZone.Central.some(
        c => c.toLowerCase() === county.toLowerCase()
      );
      ianaTimeZone = isCentral ? "America/Chicago" : "America/New_York";
    } else {
      ianaTimeZone = "America/New_York"; 
    }
  } else {
    ianaTimeZone = timeZoneToIana[stateAbbr] || "UTC";
  }

  // Parse the date as local to the user’s machine
  let dateObj;
  try {
    dateObj = new Date(auctionDate);
  } catch (err) {
    console.error("Error parsing dateObj from auctionDate:", err);
    return {
      saleLocalTime: 'Invalid Date/Time',
      userLocalTime: 'Invalid Date/Time'
    };
  }

  // Format for county local time
  let saleLocalTime;
  try {
    saleLocalTime = dateObj.toLocaleString('en-US', {
      timeZone: ianaTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  } catch (err) {
    console.error("Error formatting saleLocalTime:", err);
    saleLocalTime = 'Invalid Date/Time';
  }

  // Format for user local time
  let userLocalTime;
  try {
    userLocalTime = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  } catch (err) {
    console.error("Error formatting userLocalTime:", err);
    userLocalTime = 'Invalid Date/Time';
  }

  return {
    saleLocalTime,
    userLocalTime
  };
};

// -------------------- //
// DOWNLOAD LOGIC BELOW //
// -------------------- //


/**
 * Helper to download property list in Excel/PDF, etc.
 */
export const handleDownload = async (
  saleId,
  format,
  countyName,
  saleTypeDescription,
  todayString,
  selectedState,
  selectedTimeFrame
) => {
  console.log("Selected format for download:", format);

  if (!format) {
    console.error("Download format is undefined");
    return;
  }

  try {
    const today = new Date().toISOString().split('T')[0];
    const dateRange = getDateRange(selectedTimeFrame);
    const payload = {
      auctionDateRange: dateRange,
      auctionStyle: "",
      county: countyName,
      propertySaleType: saleTypeDescription ? getSaleTypeDescription(saleTypeDescription) : "",
      recordCount: 50000,
      saleStatus: "",
      searchText: "",
      startIndex: 0,
      state: selectedState
    };

    const response = await propertiesAPI.getPropertyListDownload(payload);
    const jsonData = response.downloadList;

    if (!jsonData || jsonData.length === 0) {
      console.error('No data available for download');
      return;
    }

    const filteredData = jsonData.filter(item => {
      const saleDate = new Date(item.saleDate).toISOString().split('T')[0];
      return saleDate >= today;
    });

    if (filteredData.length === 0) {
      console.error('No valid data available for download');
      return;
    }

    switch (format) {
      case 'excel':
        downloadAsExcel(filteredData, saleId, countyName, saleTypeDescription, todayString);
        break;
      case 'pdf':
        downloadAsPDF(filteredData, saleId, countyName, saleTypeDescription, todayString);
        break;
      default:
        console.error('Unsupported format:', format);
    }
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

// src/utils.js

/**
 * Formats a Date object to 'MM/DD/YYYY'.
 * @param {Date} date 
 * @returns {string} Formatted date string.
 */
const formatDateMMDDYYYY = (date) => {
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const dd = String(date.getDate()).padStart(2, '0');
  const yyyy = date.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
};

/**
 * Returns an object with `startDate`, `endDate`, and `compareOperator` based on the selected timeframe.
 */
export const getDateRange = (timeFrame) => {
  const today = new Date();
  let startDate = '', endDate = '', compareOperator = '';

  switch(timeFrame) {
    case 'All Future Sale Dates':
      startDate = today.toISOString().split('T')[0]; // 'YYYY-MM-DD'
      compareOperator = '>';
      break;
      
    case 'Past 4 Weeks':
      const pastDate = new Date(today);
      pastDate.setDate(today.getDate() - 28); // 4 weeks ago
      startDate = pastDate.toISOString().split('T')[0]; // 'YYYY-MM-DD'
      endDate = today.toISOString().split('T')[0];         // 'YYYY-MM-DD'
      compareOperator = '>'; // Using '>' to fetch properties after startDate up to endDate
      break;

    case 'Next 30 Days':
      const future30 = new Date(today);
      future30.setDate(today.getDate() + 30);
      startDate = today.toISOString().split('T')[0];       // 'YYYY-MM-DD'
      endDate = future30.toISOString().split('T')[0];      // 'YYYY-MM-DD'
      compareOperator = '<'; // Using '<' to fetch properties before endDate starting from today
      break;

    case 'Next 60 Days':
      const future60 = new Date(today);
      future60.setDate(today.getDate() + 60);
      startDate = today.toISOString().split('T')[0];
      endDate = future60.toISOString().split('T')[0];
      compareOperator = '>';
      break;

    case 'Next 90 Days':
      const future90 = new Date(today);
      future90.setDate(today.getDate() + 90);
      startDate = today.toISOString().split('T')[0];
      endDate = future90.toISOString().split('T')[0];
      compareOperator = '>';
      break;

    case 'Next 6 Months':
      const future6Months = new Date(today);
      future6Months.setMonth(today.getMonth() + 6);
      startDate = today.toISOString().split('T')[0];
      endDate = future6Months.toISOString().split('T')[0];
      compareOperator = '>';
      break;

    case 'Next 1 Year':
      const future1Year = new Date(today);
      future1Year.setFullYear(today.getFullYear() + 1);
      startDate = today.toISOString().split('T')[0];
      endDate = future1Year.toISOString().split('T')[0];
      compareOperator = '>';
      break;

    default:
      console.warn(`Unknown timeFrame: ${timeFrame}`);
      break;
  }

  return { startDate, endDate, compareOperator };
};

// =========== EXCEL/PDF Helper Functions =========== //

function downloadAsExcel(dataArray, saleId, countyName, saleTypeDescription, downloadDate) {
  console.log('Data keys:', Object.keys(dataArray[0] || {}));
  const today = new Date();
  const filteredData = dataArray.filter(item => {
    const saleDate = new Date(item.saleDate);
    return saleDate >= today;
  });

  const headerMap = {
    'saleDate': 'Sale Date',
    'saleTime': 'Sale Time',
    'saleLocation': 'Sale Location',
    'causeNumber': 'Cause Number',
    'saleID': 'Sale ID',
    'defendantName': 'Defendant Name',
    'ownerName': 'Owner Name',
    'propertyStreet': 'Property Street',
    'propertyCity': 'Property City',
    'propertyZip': 'Property Zip',
    'propertyID': 'Property ID',
    'plaintiffName': 'Plaintiff Name',
    'attorneyName': 'Attorney Name',
    'judgmentAmount': 'Judgment Amount',
    'minimumBid': 'Minimum Bid',
    'saleResult': 'Sale Result',
    'legal': 'Legal',
    'sellRedeemTogether': 'Sell Redeem Together',
  };

  let fieldsToInclude = Object.keys(headerMap);
  fieldsToInclude = fieldsToInclude.filter(field => {
    return filteredData.some(item => item[field] != null && item[field].toString().trim() !== '');
  });
  const headers = fieldsToInclude.map(field => headerMap[field]);

  const transformedData = filteredData.map(item => {
    return fieldsToInclude.map(field => {
      let cellValue = item[field] || '';
      if (['judgmentAmount', 'minimumBid'].includes(field)) {
        cellValue = cellValue ? `$${parseFloat(cellValue.replace(/[^0-9.-]+/g, "")).toFixed(2)}` : '';
      }
      return cellValue;
    });
  });

  transformedData.unshift(headers);

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(transformedData);

  worksheet['!cols'] = headers.map(() => ({ wch: 20 }));

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Data');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  });
  const filename = `${countyName}_${saleTypeDescription}_Sale_Properties_Downloaded_${downloadDate}.xlsx`;
  saveAs(excelBlob, filename);
}

function downloadAsPDF(dataArray, saleId, countyName, saleTypeDescription, downloadDate) {
  console.log('Data keys:', Object.keys(dataArray[0] || {}));
  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    console.error("Invalid data array for PDF generation");
    return;
  }

  const today = new Date().toISOString().split('T')[0];
  const headerMap = {
    'saleDate': 'Sale Date',
    'saleTime': 'Sale Time',
    'saleLocation': 'Sale Location',
    'causeNumber': 'Cause Number',
    'saleID': 'Sale ID',
    'defendantName': 'Defendant Name',
    'ownerName': 'Owner Name',
    'propertyStreet': 'Property Street',
    'propertyCity': 'Property City',
    'propertyZip': 'Property Zip',
    'propertyID': 'Property ID',
    'plaintiffName': 'Plaintiff Name',
    'attorneyName': 'Attorney Name',
    'judgmentAmount': 'Judgment Amount',
    'minimumBid': 'Minimum Bid',
    'saleResult': 'Sale Result',
    'legal': 'Legal',
    'sellRedeemTogether': 'Sell Redeem Together',
  };

  let fieldsToInclude = Object.keys(headerMap);
  fieldsToInclude = fieldsToInclude.filter(field => {
    return dataArray.some(item => item[field] != null && item[field].toString().trim() !== '');
  });
  const headers = fieldsToInclude.map(field => headerMap[field]);

  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'letter'
  });
  const pageWidth = pdf.internal.pageSize.width;
  const margin = 40;
  const contentWidth = pageWidth - 2 * margin;
  let yPos = 60;

  function addPageHeaders(pageNum) {
    pdf.setFontSize(10);
    pdf.setTextColor(40);
    pdf.text(`${countyName} County ${saleTypeDescription} Sale`, margin, 30);
    pdf.text(`Date generated: ${today} | Page ${pageNum} of ${dataArray.length}`, pageWidth - margin, 30, { align: 'right' });
  }

  dataArray.forEach((data, index) => {
    if (index > 0) {
      pdf.addPage();
    }
    addPageHeaders(index + 1);
    renderPropertyCard(data, yPos);
  });

  const filename = `${countyName}_${saleTypeDescription}_Sale_Properties_Downloaded_${downloadDate}.pdf`;
  pdf.save(filename);

  function renderPropertyCard(data, yPosStart) {
    let yPos = yPosStart;
    pdf.setFontSize(10);
    fieldsToInclude.forEach(field => {
      const displayName = headerMap[field];
      let displayValue = data[field] || '-';
      if (displayValue == null || displayValue.toString().trim() === '' || displayValue === '-') {
        return;
      }
      let text = `${displayName}: ${displayValue}`;
      if (field.toLowerCase().includes('legal') && displayValue.length > 500) {
        pdf.setFontSize(5);
        text = pdf.splitTextToSize(text, contentWidth);
      } else {
        pdf.setFontSize(10);
        text = pdf.splitTextToSize(text, contentWidth);
      }
      text.forEach(line => {
        pdf.text(line, margin, yPos);
        yPos += 12;
      });
      yPos += 5;
    });
  }
}
