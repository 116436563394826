// src/components/AuctionList.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Card,
  Alert,
  Button,
  DropdownButton,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import LoadingAnimation from './LoadingAnimation';
import { propertiesAPI } from './PropertiesAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRegistered, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './AuctionList.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  generateSaleId,
  processAuctionDate,
} from './utils';

import { selectUserInfo } from '../redux/userSlice';

import {
  fetchUserRegistrations as fetchRegistrations,
  registerForSale,
  unregisterFromSale,
} from '../redux/registrationSlice';

/**
 * Subcomponent representing one auction card.
 * - If "online" or includes "www.zeusauction.com", render a "Register on Zeus" button
 * - Otherwise, show "Register"/"Unregister"
 */
const AuctionCardItem = ({
  auction,
  isRegistered,
  onRegisterToggle,
  onViewSaleClick,
}) => {
  console.log('Rendering AuctionCardItem for:', auction);

  const [showSaleTime, setShowSaleTime] = useState(true);

  // Renders truncated or linked location
  const renderLocation = (location) => {
    if (!location) return 'N/A';
    const zeusUrl = 'www.zeusauction.com';
    const truncated =
      location.length > 20 ? `${location.substring(0, 20)}...` : location;

    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{location}</Tooltip>}>
        <span>
          {location.toLowerCase().includes(zeusUrl) ? (
            <a
              href={`http://${zeusUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="unique-auction-location-link"
            >
              {zeusUrl}
            </a>
          ) : (
            truncated
          )}
        </span>
      </OverlayTrigger>
    );
  };

  // Check if it's an online sale or has 'www.zeusauction.com' in location
  const isOnlineOrZeus = () => {
    const loc = auction.auctionDetail?.location?.toLowerCase() || '';
    const auctionType = auction.auctionDetail?.type?.toLowerCase() || '';
    return auctionType === 'online' || loc.includes('www.zeusauction.com');
  };

  return (
    <Card className="unique-auction-card-item">
      <Card.Header className="unique-auction-card-header">
        {auction.county}, {auction.state} - {auction.saleTypeDescription}
      </Card.Header>

      <Card.Body className="unique-auction-card-body">
        <Card.Text className="unique-auction-card-text">
          <strong>Date:</strong>{' '}
          {new Date(auction.auctionDate).toLocaleDateString()}
          <br />
          <strong>Time:</strong>{' '}
          {showSaleTime ? auction.saleLocalTime : auction.userLocalTime}
          <br />
          <strong>Type:</strong>{' '}
          {auction.auctionDetail?.type || 'N/A'}
          <br />
          <strong>Location:</strong>{' '}
          {auction.auctionDetail?.location
            ? renderLocation(auction.auctionDetail.location)
            : 'N/A'}
        </Card.Text>

        {/* Toggle local vs user time */}
        <Form.Check
          type="switch"
          id={`unique-auction-time-toggle-${auction.id}-${auction.saleId}`}
          className="unique-auction-time-switch"
          label={`Show ${showSaleTime ? "Sale’s Local Time" : "User’s Local Time"}`}
          checked={showSaleTime}
          onChange={() => setShowSaleTime(!showSaleTime)}
        />

        <div className="unique-auction-card-buttons">
          {isOnlineOrZeus() ? (
            // If it's online or location includes "www.zeusauction.com"
            <>
              <Button
                variant="outline-primary"
                href="http://www.zeusauction.com"
                target="_blank"
                rel="noopener noreferrer"
                className="unique-auction-action-button unique-auction-register-zeus-button btn-sm"
              >
                <FontAwesomeIcon icon={faRegistered} style={{ marginRight: '5px' }} />
                Register on Zeus
              </Button>
              <Button
                variant="outline-success"
                onClick={() => onViewSaleClick(auction)}
                className="unique-auction-action-button unique-auction-view-sale-button btn-sm"
              >
                View Sale
              </Button>
            </>
          ) : (
            // Otherwise, normal register/unregister button
            <>
              <Button
                variant={isRegistered ? 'secondary' : 'outline-secondary'}
                onClick={() => onRegisterToggle(auction)}
                className="unique-auction-action-button unique-auction-register-button btn-sm"
              >
                <FontAwesomeIcon icon={faRegistered} style={{ marginRight: '5px' }} />
                {isRegistered ? 'Unregister' : 'Register'}
              </Button>
              <Button
                variant="outline-success"
                onClick={() => onViewSaleClick(auction)}
                className="unique-auction-action-button unique-auction-view-sale-button btn-sm"
              >
                View Sale
              </Button>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

const AuctionList = ({ closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(selectUserInfo);
  const registrations = useSelector((state) => state.registration.registrations);
  const registrationError = useSelector((state) => state.registration.error);

  const isAuthenticated = !!(userInfo.user && userInfo.user.email);
  const userId = userInfo.user?.fusionauth_user_id || null;

  // Auction data
  const [auctions, setAuctions] = useState([]);
  const [filteredAuctions, setFilteredAuctions] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Filters
  const [filteredState, setFilteredState] = useState('All');
  const [filteredType, setFilteredType] = useState('All');
  const [filteredCounty, setFilteredCounty] = useState('All');
  const [sortOrder, setSortOrder] = useState('asc');

  // Pagination state for UI (page numbers)
  const PAGE_SIZE = 25;
  const [currentPage, setCurrentPage] = useState(1);

  // Toast states
  const [showAuctionToast, setShowAuctionToast] = useState(false);
  const [auctionToastMessage, setAuctionToastMessage] = useState('');
  const [missingFields, setMissingFields] = useState([]);

  // On mount: if logged in, fetch registrations
  useEffect(() => {
    if (isAuthenticated && userId) {
      dispatch(fetchRegistrations());
    }
  }, [dispatch, isAuthenticated, userId]);

  // Handle registration errors
  useEffect(() => {
    if (registrationError) {
      if (
        typeof registrationError === 'object' &&
        registrationError.error === 'INCOMPLETE_PROFILE'
      ) {
        setAuctionToastMessage('Your profile is incomplete. Please complete it.');
        setMissingFields(registrationError.missingFields || []);
        setShowAuctionToast(true);
      } else if (registrationError === 'ALREADY_REGISTERED') {
        setAuctionToastMessage('You are already registered for this sale.');
        setMissingFields([]);
        setShowAuctionToast(true);
      } else if (typeof registrationError === 'string') {
        setAuctionToastMessage(registrationError);
        setMissingFields([]);
        setShowAuctionToast(true);
      }
    }
  }, [registrationError]);

  // Fetch auctions with pagination logic (fetch all pages from the API)
  const fetchAllAuctions = async () => {
    try {
      setIsLoading(true);
      let startIndex = 0;
      const recordCount = 25;
      let allAuctions = [];
      let fetchedAuctions = [];

      do {
        const data = await propertiesAPI.getAuctionListAll(startIndex, recordCount);
        if (!Array.isArray(data)) {
          throw new Error('No auction data returned.');
        }
        console.log(`Fetched ${data.length} auctions starting at index ${startIndex}`);
        fetchedAuctions = data;
        allAuctions = [...allAuctions, ...data];
        startIndex += recordCount;
      } while (fetchedAuctions.length === recordCount);

      // Append localTime fields to each auction
      const withTimes = allAuctions.map((a, index) => {
        const saleTypeDescription = a.saleType?.trim() || 'Unknown';
        let saleLocalTime = 'Invalid';
        let userLocalTime = 'Invalid';

        if (a.auctionDate) {
          const { saleLocalTime: sLocal, userLocalTime: uLocal } = processAuctionDate(
            a.auctionDate,
            a.state,
            a.county
          );
          saleLocalTime = sLocal;
          userLocalTime = uLocal;
        }

        return {
          ...a,
          saleTypeDescription,
          saleLocalTime,
          userLocalTime,
          _index: index, // For additional uniqueness if needed
        };
      });

      console.log('Processed Auctions with Times:', withTimes);
      setAuctions(withTimes);
      // Reset to first page when new data is fetched
      setCurrentPage(1);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch auctions.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllAuctions();
  }, []);

  // Filter + sort auctions whenever filters, sortOrder, or auctions change
  useEffect(() => {
    applyFiltersAndSort();
  }, [filteredState, filteredType, filteredCounty, sortOrder, auctions]);

  const applyFiltersAndSort = () => {
    let filtered = [...auctions];

    console.log('Before Filtering:', filtered.length, 'auctions');

    if (filteredState !== 'All') {
      filtered = filtered.filter(
        (a) => a.state?.toLowerCase() === filteredState.toLowerCase()
      );
    }
    if (filteredType !== 'All') {
      filtered = filtered.filter(
        (a) =>
          a.saleTypeDescription?.toLowerCase().trim() === filteredType.toLowerCase().trim()
      );
    }
    if (filteredCounty !== 'All') {
      filtered = filtered.filter(
        (a) => a.county?.toLowerCase().trim() === filteredCounty.toLowerCase().trim()
      );
    }

    console.log('After Filtering:', filtered.length, 'auctions');

    filtered.sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.auctionDate) - new Date(b.auctionDate);
      } else {
        return new Date(b.auctionDate) - new Date(a.auctionDate);
      }
    });

    setFilteredAuctions(filtered);
    // Reset pagination to page 1 whenever filters change
    setCurrentPage(1);
  };

  // Unique dropdown options for filters
  const uniqueStates = useMemo(
    () => Array.from(new Set(auctions.map((a) => a.state))).sort(),
    [auctions]
  );
  const uniqueTypes = useMemo(() => {
    const types = auctions.map((a) => a.saleTypeDescription || '').filter(Boolean);
    return Array.from(new Set(types)).sort();
  }, [auctions]);
  const uniqueCounties = useMemo(() => {
    const auctionsForState =
      filteredState === 'All'
        ? auctions
        : auctions.filter(
            (a) => a.state?.toLowerCase() === filteredState.toLowerCase()
          );
    return Array.from(new Set(auctionsForState.map((a) => a.county))).sort();
  }, [auctions, filteredState]);

  // Check if the auction is registered for the current user
  const isAuctionRegistered = (auction) => {
    const saleId =
      auction.saleId && auction.saleId !== 0
        ? String(auction.saleId)
        : generateSaleId(auction);
    return registrations.some((r) => String(r.sale_id) === saleId);
  };

  // Register/unregister for an auction
  const handleRegisterOrUnregister = async (auction) => {
    if (!isAuthenticated) {
      handleLogin();
      return;
    }
    const saleId =
      auction.saleId && auction.saleId !== 0
        ? String(auction.saleId)
        : generateSaleId(auction);
    if (!saleId) {
      setAuctionToastMessage('No valid sale ID for this auction.');
      setMissingFields([]);
      setShowAuctionToast(true);
      return;
    }

    try {
      if (isAuctionRegistered(auction)) {
        await dispatch(unregisterFromSale(saleId)).unwrap();
        setAuctionToastMessage('Successfully unregistered from the sale.');
        setMissingFields([]);
        setShowAuctionToast(true);
      } else {
        await dispatch(registerForSale({ saleId, auction })).unwrap();
        setAuctionToastMessage('Successfully registered for the sale.');
        setMissingFields([]);
        setShowAuctionToast(true);
      }
    } catch (error) {
      console.error('Register/unregister error:', error);
    }
  };

  // "View Sale" handler
  const handleViewSale = (auction) => {
    const saleUrl =
      `/properties?` +
      `saleId=${auction.saleId || ''}` +
      `&state=${auction.state}` +
      `&county=${auction.county}` +
      `&saleType=${auction.saleType}` +
      `&timeFrame=All Future Sale Dates`;

    navigate(saleUrl);
    if (closeModal) closeModal();
  };

  // Download functions for Excel and PDF
  const handleDownload = (format) => {
    const data = filteredAuctions.map((a) => ({
      Date: new Date(a.auctionDate).toLocaleDateString(),
      Time: a.auctionDetail?.time || 'N/A',
      County: a.county,
      State: a.state,
      Type: a.saleTypeDescription,
      Location: a.auctionDetail?.location || 'N/A',
    }));
    if (!data.length) {
      alert('No data available to download.');
      return;
    }
    const today = new Date().toISOString().split('T')[0];
    const baseFilename = `SRI_AuctionList_Downloaded_${today}`;

    if (format === 'excel') {
      downloadExcel(data, `${baseFilename}.xlsx`);
    } else if (format === 'pdf') {
      downloadPDF(data, `${baseFilename}.pdf`);
    }
  };

  const downloadExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Auction List');
    XLSX.writeFile(wb, filename);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF();
    const headers = Object.keys(data[0]);
    const rows = data.map((row) => Object.values(row));
    doc.autoTable({ head: [headers], body: rows });
    doc.save(filename);
  };

  // Handle filter dropdown changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'state') {
      setFilteredState(value);
      setFilteredCounty('All'); // Reset county filter when state changes
    }
    if (name === 'type') setFilteredType(value);
    if (name === 'county') setFilteredCounty(value);
  };

  // Toggle sort order
  const handleSortOrderToggle = (order) => {
    setSortOrder(order);
  };

  // If not logged in, redirect to login
  const handleLogin = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const loginUrl = `${process.env.REACT_APP_SERVER_URL}/app/login?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      window.location.origin
    )}&original_url=${encodeURIComponent(window.location.href)}`;
    window.location.href = loginUrl;
  };

  // Calculate pagination details for UI
  const totalPages = Math.ceil(filteredAuctions.length / PAGE_SIZE);
  const currentPageAuctions = filteredAuctions.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  return (
    <div className="unique-auction-container">
      {isLoading && <LoadingAnimation />}

      {/* Top Header */}
      <div className="unique-auction-top-header">
        <div className="unique-auction-header-title">Auction List</div>
        <DropdownButton
          id="unique-auction-dropdown-download"
          title="Download List"
          variant="white"
          className="unique-auction-download-button"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown.Item onClick={() => handleDownload('excel')}>
            Download Excel
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDownload('pdf')}>
            Download PDF
          </Dropdown.Item>
        </DropdownButton>
      </div>

      {error && (
        <Alert variant="danger" className="unique-auction-error-alert">
          {error}
        </Alert>
      )}

      {/* Filters + Sort */}
      <div className="unique-auction-filters-wrapper">
        <div className="unique-auction-filters-row">
          <div className="unique-auction-filter-group">
            <Form.Label htmlFor="filterState">Filter by State:</Form.Label>
            <Form.Control
              as="select"
              id="filterState"
              name="state"
              value={filteredState}
              onChange={handleFilterChange}
              className="unique-auction-filter-select"
            >
              <option value="All">All States</option>
              {uniqueStates.map((st) => (
                <option key={st} value={st}>
                  {st}
                </option>
              ))}
            </Form.Control>
          </div>

          <div className="unique-auction-filter-group">
            <Form.Label htmlFor="filterType">Filter by Type:</Form.Label>
            <Form.Control
              as="select"
              id="filterType"
              name="type"
              value={filteredType}
              onChange={handleFilterChange}
              className="unique-auction-filter-select"
            >
              <option value="All">All Types</option>
              {uniqueTypes.map((t) => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </Form.Control>
          </div>

          <div className="unique-auction-filter-group">
            <Form.Label htmlFor="filterCounty">Filter by County:</Form.Label>
            <Form.Control
              as="select"
              id="filterCounty"
              name="county"
              value={filteredCounty}
              onChange={handleFilterChange}
              className="unique-auction-filter-select"
            >
              <option value="All">All Counties</option>
              {uniqueCounties.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Control>
          </div>
        </div>

        <div className="unique-auction-sort-wrapper">
          <Form.Group controlId="sortOrder" className="unique-auction-sort-group">
            <Form.Label>Sort by Date:</Form.Label>
            <ButtonGroup className="unique-auction-sort-button-group">
              <Button
                variant={sortOrder === 'asc' ? 'primary' : 'outline-primary'}
                onClick={() => handleSortOrderToggle('asc')}
                className="unique-auction-sort-button"
              >
                <FontAwesomeIcon icon={faArrowUp} /> Ascending
              </Button>
              <Button
                variant={sortOrder === 'desc' ? 'primary' : 'outline-primary'}
                onClick={() => handleSortOrderToggle('desc')}
                className="unique-auction-sort-button"
              >
                <FontAwesomeIcon icon={faArrowDown} /> Descending
              </Button>
            </ButtonGroup>
          </Form.Group>
        </div>
      </div>

      {/* Auction Cards */}
      <div className="unique-auction-cards-container">
        {isLoading ? (
          <LoadingAnimation />
        ) : currentPageAuctions.length === 0 ? (
          <Alert variant="info" className="unique-auction-no-results-alert">
            No auctions found for the selected filters.
          </Alert>
        ) : (
          currentPageAuctions.map((auction, index) => {
            const isReg = isAuctionRegistered(auction);
            const uniqueKey = `${auction.id}-${auction.saleId}-${auction.auctionDate}-${index}`;
            return (
              <AuctionCardItem
                key={uniqueKey}
                auction={auction}
                isRegistered={isReg}
                onRegisterToggle={handleRegisterOrUnregister}
                onViewSaleClick={handleViewSale}
              />
            );
          })
        )}
      </div>

      {/* Pagination Controls */}
      {filteredAuctions.length > PAGE_SIZE && (
        <div className="unique-auction-pagination" style={{ margin: '20px 0', textAlign: 'center' }}>
          <Button
            variant="outline-primary"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            style={{ marginRight: '10px' }}
          >
            Previous
          </Button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="outline-primary"
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            style={{ marginLeft: '10px' }}
          >
            Next
          </Button>
        </div>
      )}

      {/* Toast Notification */}
      <ToastContainer
        position="top-end"
        className="p-3 unique-auction-toast-container"
      >
        <Toast
          onClose={() => setShowAuctionToast(false)}
          show={showAuctionToast}
          delay={8000}
          autohide
          bg="warning"
        >
          <Toast.Header>
            <strong className="me-auto">Profile / Registration Notice</strong>
          </Toast.Header>
          <Toast.Body>
            <div style={{ marginBottom: '10px' }}>{auctionToastMessage}</div>
            {missingFields.length > 0 && (
              <>
                <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>
                  Missing:
                </div>
                <ul>
                  {missingFields.map((field) => (
                    <li key={field}>{field}</li>
                  ))}
                </ul>
                <Button
                  variant="link"
                  style={{ padding: 0, marginTop: '8px' }}
                  onClick={() => navigate('/profile')}
                >
                  Complete Profile
                </Button>
              </>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default AuctionList;
