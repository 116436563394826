// LienAssist.js

import React from "react";
import image1 from "../images/lap.png";
import { Link } from 'react-router-dom';
import styles from '../css/LienAssistPage.module.css';

function LienAssist() {
    return (
        <div>
            <div className={styles.banner}>
                <h1 className={styles.bannerTitle}>Lien Assist Program</h1>
            </div>

            <div className={styles.contentWrapper}>
                <div className={styles.leftContent}>
                    <h4 className={styles.serviceDescription}>Our brand-new service - <b>Lien Assist</b> - makes the entire lien process quicker and easier, with less back and forth</h4>
                    <div className={styles.listWrapper}>
                        <ul>
                            <li><b>Sewer Liens</b></li>
                            <li><b>Mowing Liens</b></li>
                            <li><b>Demolition Liens</b></li>
                            <li><b>Nuisance Liens</b></li>
                        </ul>
                    </div>
                </div>
                <div className={styles.rightContent}>
                    <ul className="mt-2">
                        <b>Clean, Uniform Documents</b>
                        <li>SRI eliminates uncertainty regarding lien amounts and associated fees.</li>
                        <li>SRI makes all documents uniform in style and content for clarity.</li>
                    </ul>
                    <ul>
                        <b>Noticing & Recordings</b>
                        <li>SRI creates and mails all legally required notices.</li>
                        <li>SRI records and releases all lien documents.</li>
                    </ul>
                    <ul>
                        <b>Experts On Hand</b>
                        <li>SRI has former Auditors and Treasurers on staff to answer any questions.</li>
                        <li>SRi provides in-depth user manuals, training videos, and knowledge base articles.</li>
                    </ul>
                    <ul>
                        <b>One Online hub</b>
                        <li>SRI archives all documents online for quick, 24/7 access.</li>
                        <li>SRI shares information seamlessly between municipalities and the county.</li>
                    </ul>
                    <ul>
                        <b>Much, Much More!</b>
                        <li>Generate reports to track the status of your outstanding liens.</li>
                        <li>View projected settlement distributions for easier balancing of funds</li>
                    </ul>
                    <div className={styles.btnWrapper}>
                        <Link to="/support">
                            <button id="lapBtn" className="btn mb-3 btn-primary">
                                Inquire about Lien Assist
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LienAssist;
