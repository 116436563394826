import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_SRI_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_SRI_APP_API_KEY;
const API_AUTH = `Bearer ${process.env.REACT_APP_SRI_APP_API_AUTH}`;
const API_AUTH_ADMIN = `Bearer ${process.env.REACT_APP_SRI_APP_API_AUTH_ADMIN}`;

const handleRequest = async (method, endpoint, data = null, customHeaders = {}) => {
    try {
        const headers = {
            'APIKey': API_KEY,
            ...customHeaders
        };

        // Adjust URL formation to be more flexible based on endpoint
        let url = `${API_BASE_URL}/${endpoint}`;
        const response = await axios({
            method,
            url,
            headers,
            data: method === 'get' ? null : data,
            params: method === 'get' ? data : null,
        });
        return response.data;
    } catch (error) {
        console.error(`Error during API request to ${endpoint}:`, error);
        throw error;
    }
};

// Function specific for file uploads using Admin Auth
export const uploadDocument = async (file) => {
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // Remove the content type prefix
        reader.onerror = error => reject(error);
    });

    try {
        const base64String = await toBase64(file);
        const payload = {
            category: "General",
            access: "P",
            fileName: file.name,
            fileType: file.type,
            fileByteString: base64String,
            fileBytes: null, 
        };

        const customHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': API_AUTH_ADMIN,  // Use admin token for this operation
            'APIKey': API_KEY  // Ensure API key is included
        };

        return await handleRequest('post', 'Document/Add', payload, customHeaders);
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};

const handleSB156DocsRequest = async () => {
    try {
        const url = `${API_BASE_URL}/Document/SB156Docs`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'Apikey': API_KEY,
            'Authorization': API_AUTH,
        };

        const response = await axios.post(url, {}, { headers });
        return response.data;
    } catch (error) {
        console.error(`Error during SB156Docs request:`, error);
        throw error;
    }
};

export const propertiesAPI = {
    getCardData: (propertyCardDetailRequest) => handleRequest('post', 'Property/CardDetail', propertyCardDetailRequest),
    getPropertyDetail: (uniquePropertyRequest) => handleRequest('post', 'Property/Detail', uniquePropertyRequest),
    getLocations: () => handleRequest('get', 'Property/Locations'),
    getSoldLocations: () => handleRequest('get', 'Property/SoldLocations'),
    getStates: () => handleRequest('get', 'Property/States'),
    getCounties: async () => {
        const counties = await handleRequest('get', 'Property/Counties');
        return counties.filter(county => county.countyName !== 'ZZZ-bad Parish');
    },
    getPropertySaleTypes: () => handleRequest('get', 'Property/SaleTypes'),
    getSaleStatus: () => handleRequest('get', 'Property/SaleStatus'),
    getStateList: () => handleRequest('get', 'Property/StateList'),
    getCountyList: () => handleRequest('get', 'Property/CountyList'),
    getSaleDateList: (selectDateRequest) => handleRequest('post', 'Property/SaleDates', selectDateRequest),
    getDocumentPropertyInfoList: (selectPropertyInfoRequest) => handleRequest('post', 'Document/PropDocs', selectPropertyInfoRequest),
    mapProperties: (mapPropertyRequest) => handleRequest('post', 'Property/Map', mapPropertyRequest),
    getPropertyListDownload: (propertyCardDetailRequest) => handleRequest('post', 'Property/DownloadPropertyList', propertyCardDetailRequest),
    getCountyDetailInformation: (countyDetailRequest) => handleRequest('post', 'Property/CountyDetailInformation', countyDetailRequest),
    getCountySalesInformation: (countyDetailRequest) => handleRequest('post', 'Property/CountySalesInformation', countyDetailRequest),
    getSB156Docs: () => handleSB156DocsRequest(),

    downloadDocument: async (documentId, documentName, storedFileName) => {
        try {
            if (storedFileName && storedFileName.startsWith("http")) {
                const link = document.createElement('a');
                link.href = storedFileName;
                link.download = documentName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                const url = `${API_BASE_URL}/Document/Download/${documentId}`;
                const response = await axios.get(url, {
                    headers: { 'ApiKey': API_KEY },
                    responseType: 'blob',
                });

                const blob = response.data;
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = documentName;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error(`Error downloading document with ID ${documentId}:`, error);
            throw error;
        }
    },
    getWikiContent: async (contentId) => {
        const wikiUrl = `${API_BASE_URL}/wiki/GetContent/${contentId}`;
        try {
            const response = await axios.get(wikiUrl, {
                headers: { 'ApiKey': API_KEY },
            });
            return response.data; // This will return the HTML content
        } catch (error) {
            console.error(`Error fetching wiki content with ID ${contentId}:`, error);
            throw error;
        }
    },

    getProperties: async (propertyCardRequest) => {
        try {
            const response = await handleRequest('post', 'Property/CardDetail', propertyCardRequest);
            return response;
        } catch (error) {
            console.error('Error fetching properties:', error);
            throw error;
        }
    },

    uploadDocument: uploadDocument, // Added to export

    getAuctionListAll: async (startIndex = 0, recordCount = 25) => {
        const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
        const endpoint = 'Auction/ListAll';
        const data = {
            searchText: "",
            state: "",
            county: "",
            propertySaleType: "",
            auctionStyle: "",
            saleStatus: "",
            auctionDateRange: {
                startDate: today,
                endDate: "",
                compareOperator: ">"
            },
            recordCount,  // Use passed-in recordCount
            startIndex   // Use passed-in startIndex
        };
        const customHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        };
    
        return await handleRequest('post', endpoint, data, customHeaders);
    },
    
};
