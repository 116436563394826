import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import DocumentManager from './DocumentManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';


const DocumentManagerModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="manager-square" onClick={handleShow}>
                <FontAwesomeIcon icon={faFileAlt} className="manager-icon" />
                <div className="manager-text">Document Manager</div>
            </div>

            <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Document Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocumentManager />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DocumentManagerModal;
