import React from "react";
import image1 from "../images/sri-investors-header.png";
import image2 from "../images/sri-history-header.png";
import styles from '../css/Investing.module.css';
import { Link } from 'react-router-dom'; 

function Investing() {
    return (
        <div>
            <div className={styles.image1Container} style={{ backgroundImage: `url(${image1})` }}>
                <h1 className={styles.h1Container}>Investing</h1>
            </div>

            <div className={styles.flexContainer}>
                <div className={styles.width40}>
                    <h4 className={styles.marginRight1}>Looking for Property?</h4>
                    <h4 className={styles.blackColor}>You’ve come to the right place.</h4>
                </div>
                <div className={styles.width60}>
                    <p>
                        Whether you’re an avid investor or a first-time buyer, we’ll help you navigate the process of purchasing tax liens, foreclosed properties, or government-owned properties with answers to your questions, website support, and assistance with Zeus, our auction platform.
                    </p>
                    <p>
                        With our easy-to-use search system, you can find properties in your area available for purchase. If you run into any issues or have questions or concerns, reach out to us—we’re here to help.
                    </p>
                </div>
            </div>

            <div className={styles.image2Container} style={{ backgroundImage: `url(${image2})` }}>
                <h2>Find A Property</h2>
                <Link to="/properties"> {/* Wrap the button with Link */}
                    <button className={`btn rounded-pill ${styles.searchButton}`}>SEARCH LISTINGS</button>
                </Link>
            </div>
        </div>
    );
}

export default Investing;
