// src/components/EmailComponent.js

import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Alert, Spinner, Modal, Tab, Nav } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectTokens } from '../redux/userSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import Templates from './Templates'; // Import the Templates component

const EmailComponent = () => {
    const [toEmails, setToEmails] = useState('');
    const [subject, setSubject] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

    // State variables for criteria options
    const [criteriaOptions, setCriteriaOptions] = useState({
        apps: [],
        accessLevels: [],
        counties: [],
        sales: [],
        favoritePropertyCounties: [],
        registrationCounties: [],
        registrationStates: [],
    });

    // State variables for selected criteria (now arrays for multi-select)
    const [selectedCriteria, setSelectedCriteria] = useState({
        appAccess: [],
        accessLevel: [],
        county: [],
        saleId: [],
        favoritePropertiesCountyId: [],
        registrationCounty: [],
        registrationState: [],
    });

    // State variables for user search
    const [searchQuery, setSearchQuery] = useState('');
    const [userResults, setUserResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    // Modal visibility states
    const [showCriteriaModal, setShowCriteriaModal] = useState(false);
    const [showTemplatesModal, setShowTemplatesModal] = useState(false); // New state for Templates modal

    const tokens = useSelector(selectTokens);
    const apiKey = process.env.REACT_APP_SERVER_API_KEY;
    const baseUrl = process.env.REACT_APP_SERVER_URL;

    // Ref for detecting clicks outside the search results
    const searchRef = useRef(null);

    useEffect(() => {
        fetchCriteriaOptions();
    }, []);

    useEffect(() => {
        // Event listener for clicks outside the search results dropdown
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setUserResults([]);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /**
     * Fetches criteria options from the backend.
     */
    const fetchCriteriaOptions = async () => {
        if (!baseUrl || !tokens.accessToken) {
            console.error('Missing configuration or tokens');
            setVariant('danger');
            setMessage('Server configuration is missing.');
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/email/criteria-options`, {
                headers: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                    'x-api-key': apiKey,
                },
            });

            setCriteriaOptions({
                apps: response.data.apps || [],
                accessLevels: response.data.accessLevels || [],
                counties: response.data.counties || [],
                sales: response.data.sales || [],
                favoritePropertyCounties: response.data.favoritePropertyCounties || [],
                registrationCounties: response.data.registrationCounties || [],
                registrationStates: response.data.registrationStates || [],
            });
        } catch (error) {
            console.error('Error fetching criteria options:', error);
            setVariant('danger');
            setMessage('Failed to fetch criteria options.');
        }
    };

    /**
     * Handles file attachments.
     */
    const handleFileChange = async (event) => {
        const files = event.target.files;
        const filesArray = Array.from(files);

        const attachmentsPromises = filesArray.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve({
                        content: base64String,
                        filename: file.name,
                        type: file.type,
                    });
                };

                reader.onerror = error => reject(error);
            });
        });

        try {
            const attachmentsData = await Promise.all(attachmentsPromises);
            setAttachments(prev => [...prev, ...attachmentsData]);
        } catch (error) {
            console.error('Error reading attachment files:', error);
            setVariant('danger');
            setMessage('Failed to read attachment files.');
        }
    };

    /**
     * Handles the email form submission.
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setMessage(null);

        if (!baseUrl || !tokens.accessToken) {
            console.error('Missing configuration or tokens');
            setIsLoading(false);
            setVariant('danger');
            setMessage('Server configuration is missing.');
            return;
        }

        // Prepare criteria object
        const criteria = {};
        Object.keys(selectedCriteria).forEach(key => {
            if (selectedCriteria[key].length > 0) {
                criteria[key] = selectedCriteria[key];
            }
        });

        // Use selected criteria, selected users, or manually entered emails
        const manualEmails = toEmails.split(',').map(email => email.trim()).filter(email => email);
        const selectedUserEmails = selectedUsers.map(user => user.email);
        const recipientEmails = [...manualEmails, ...selectedUserEmails];

        if (recipientEmails.length === 0 && Object.keys(criteria).length === 0) {
            setIsLoading(false);
            setVariant('danger');
            setMessage('Please specify at least one recipient, selected user, or criteria.');
            return;
        }

        const emailData = {
            toEmails: recipientEmails.length > 0 ? recipientEmails : undefined,
            criteria: Object.keys(criteria).length > 0 ? criteria : undefined,
            subject,
            htmlContent,
            attachments,
        };

        try {
            const response = await axios.post(`${baseUrl}/email/send`, emailData, {
                headers: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 202) {
                setVariant('success');
                setMessage('Email sent successfully.');
                setToEmails('');
                setSubject('');
                setHtmlContent('');
                setAttachments([]);
                setSelectedCriteria({
                    appAccess: [],
                    accessLevel: [],
                    county: [],
                    saleId: [],
                    favoritePropertiesCountyId: [],
                    registrationCounty: [],
                    registrationState: [],
                });
                setSelectedUsers([]);
            } else {
                setVariant('danger');
                setMessage('Failed to send email.');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setVariant('danger');
            if (error.response && error.response.data && error.response.data.error) {
                setMessage(`Error: ${error.response.data.error}`);
            } else {
                setMessage('Failed to send email.');
            }
        }

        setIsLoading(false);
    };

    /**
     * Handles changes in multi-select fields.
     */
    const handleMultiSelectChange = (name, selectedOptions) => {
        setSelectedCriteria(prevState => ({
            ...prevState,
            [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
        }));
    };

    /**
     * User search functions.
     */
    const handleUserSearch = async (query) => {
        setSearchQuery(query);

        if (!query) {
            setUserResults([]);
            return;
        }

        if (!baseUrl || !tokens.accessToken) {
            console.error('Missing configuration or tokens');
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/app/messages/users`, {
                params: { search: query },
                headers: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                    'x-api-key': apiKey,
                },
            });

            setUserResults(response.data); // Assume backend returns an array of users [{ fusionauth_user_id, email, first_name, middle_name, last_name, ... }, ...]
        } catch (error) {
            console.error('Error searching users:', error);
            setVariant('danger');
            setMessage('Error searching users.');
        }
    };

    /**
     * Handles selecting a user from search results.
     */
    const handleUserSelect = (user) => {
        if (!selectedUsers.find(u => u.fusionauth_user_id === user.fusionauth_user_id)) {
            setSelectedUsers([...selectedUsers, user]);
        }
        setSearchQuery('');
        setUserResults([]);
    };

    /**
     * Handles removing a selected user.
     */
    const handleUserRemove = (userId) => {
        setSelectedUsers(selectedUsers.filter(u => u.fusionauth_user_id !== userId));
    };

    /**
     * Handles selecting a template from Templates component.
     * @param {Object} template - The selected template.
     */
    const handleSelectTemplate = (template) => {
        setSubject(template.subject);
        setHtmlContent(template.body_html);
        setToEmails(''); // Clear any manually entered emails
        setSelectedUsers([]); // Clear selected users
        setSelectedCriteria({
            appAccess: [],
            accessLevel: [],
            county: [],
            saleId: [],
            favoritePropertiesCountyId: [],
            registrationCounty: [],
            registrationState: [],
        });
        setAttachments([]); // Clear attachments
        setMessage(null);
        setVariant('success');
        setShowTemplatesModal(false); // Close the Templates modal
    };

    /**
     * Handles downloading the user email list as an Excel file.
     */
    const handleDownload = async () => {
        if (!baseUrl || !tokens.accessToken) {
            console.error('Missing configuration or tokens');
            setVariant('danger');
            setMessage('Server configuration is missing.');
            return;
        }

        setIsDownloading(true);
        setMessage(null);

        try {
            const response = await axios.get(`${baseUrl}/email/download-emails`, {
                headers: {
                    Authorization: `Bearer ${tokens.accessToken}`,
                    'x-api-key': apiKey,
                },
                responseType: 'blob', // Important for handling binary data
            });

            // Create a new Blob object using the response data of the file
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);

            // Create a link element and simulate a click to download the file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'user_emails.xlsx'); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            setVariant('success');
            setMessage('User emails downloaded successfully.');
        } catch (error) {
            console.error('Error downloading user emails:', error);
            setVariant('danger');
            if (error.response && error.response.data && error.response.data.error) {
                setMessage(`Error: ${error.response.data.error}`);
            } else {
                setMessage('Failed to download user emails.');
            }
        }

        setIsDownloading(false);
    };

    return (
        <div style={{ position: 'relative', width: '100%', overflow: 'hidden', border: '5px solid #FF6D40', borderRadius: '10px', boxSizing: 'border-box' }}>
            <div style={{ backgroundColor: '#FF6D40', color: 'white', textAlign: 'center', fontWeight: 'bold', padding: '10px 0' }}>
                <span>Email Sender</span>
            </div>
            <div style={{ padding: '10px', overflowY: 'auto', maxHeight: '80vh' }}>
                {message && <Alert variant={variant}>{message}</Alert>}

                {/* Download Button */}
                <div className="d-flex justify-content-end mb-3">
                    <Button
                        variant="success"
                        onClick={handleDownload}
                        disabled={isDownloading}
                    >
                        {isDownloading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{' '}
                                Downloading...
                            </>
                        ) : (
                            'Download User Emails'
                        )}
                    </Button>
                </div>

                <Form onSubmit={handleSubmit}>
                    {/* User Search Section */}
                    <Form.Group controlId="userSearch" ref={searchRef} style={{ position: 'relative' }}>
                        <Form.Label>Search Users</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Type full name or email to search users..."
                            value={searchQuery}
                            onChange={(e) => handleUserSearch(e.target.value)}
                        />
                        {userResults.length > 0 && (
                            <ul style={{
                                listStyleType: 'none',
                                paddingLeft: 0,
                                maxHeight: '150px',
                                overflowY: 'auto',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginTop: '5px',
                                backgroundColor: 'white',
                                position: 'absolute',
                                zIndex: 1000,
                                width: '100%',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                            }}>
                                {userResults.map(user => {
                                    const fullName = `${user.first_name} ${user.middle_name ? user.middle_name + ' ' : ''}${user.last_name}`;
                                    return (
                                        <li
                                            key={user.fusionauth_user_id}
                                            style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                                            onClick={() => handleUserSelect(user)}
                                        >
                                            {fullName} ({user.email})
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </Form.Group>

                    {/* Selected Users Section */}
                    <Form.Group controlId="selectedUsers" className="mt-3">
                        <Form.Label>Selected Users</Form.Label>
                        {selectedUsers.length > 0 ? (
                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                {selectedUsers.map(user => {
                                    const fullName = `${user.first_name} ${user.middle_name ? user.middle_name + ' ' : ''}${user.last_name}`;
                                    return (
                                        <li key={user.fusionauth_user_id} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                            <span>{fullName} ({user.email})</span>
                                            <Button
                                                variant="link"
                                                size="sm"
                                                onClick={() => handleUserRemove(user.fusionauth_user_id)}
                                                style={{ marginLeft: '10px', color: '#FF6D40' }}
                                            >
                                                Remove
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div>No users selected.</div>
                        )}
                    </Form.Group>

                    {/* Button to open modal for selecting recipient groups */}
                    <Form.Group controlId="criteriaSelection" className="mt-3">
                        <Button variant="primary" onClick={() => setShowCriteriaModal(true)}>
                            Select Recipient Groups
                        </Button>
                    </Form.Group>

                    {/* Display selected recipient groups */}
                    <Form.Group controlId="selectedCriteria" className="mt-3">
                        <Form.Label>Selected Recipient Groups</Form.Label>
                        {Object.values(selectedCriteria).some(arr => arr.length > 0) ? (
                            <ul>
                                {Object.keys(selectedCriteria).map(key => (
                                    selectedCriteria[key].length > 0 && (
                                        <li key={key}>
                                            <strong>{key}:</strong> {selectedCriteria[key].join(', ')}
                                        </li>
                                    )
                                ))}
                            </ul>
                        ) : (
                            <div>No recipient groups selected.</div>
                        )}
                    </Form.Group>

                    {/* Modal for selecting recipient groups */}
                    <Modal
                        show={showCriteriaModal}
                        onHide={() => setShowCriteriaModal(false)}
                        size="lg"
                        aria-labelledby="criteria-modal"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="criteria-modal">
                                Select Recipient Groups
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Tab.Container defaultActiveKey="appAccess">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="appAccess">App Access</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="accessLevel">Access Level</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="county">County</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="saleId">Sale ID</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="favoritePropertiesCountyId">Favorites in County</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="registrationCounty">Registration County</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="registrationState">Registration State</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="mt-3">
                                    {/* App Access */}
                                    <Tab.Pane eventKey="appAccess">
                                        <Select
                                            isMulti
                                            name="appAccess"
                                            options={criteriaOptions.apps.map(app => ({ value: app, label: app }))}
                                            value={selectedCriteria.appAccess.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('appAccess', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                    {/* Access Level */}
                                    <Tab.Pane eventKey="accessLevel">
                                        <Select
                                            isMulti
                                            name="accessLevel"
                                            options={criteriaOptions.accessLevels.map(level => ({ value: level, label: level }))}
                                            value={selectedCriteria.accessLevel.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('accessLevel', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                    {/* County */}
                                    <Tab.Pane eventKey="county">
                                        <Select
                                            isMulti
                                            name="county"
                                            options={criteriaOptions.counties.map(county => ({ value: county, label: county }))}
                                            value={selectedCriteria.county.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('county', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                    {/* Sale ID */}
                                    <Tab.Pane eventKey="saleId">
                                        <Select
                                            isMulti
                                            name="saleId"
                                            options={criteriaOptions.sales.map(saleId => ({ value: saleId, label: saleId }))}
                                            value={selectedCriteria.saleId.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('saleId', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                    {/* Favorites in County */}
                                    <Tab.Pane eventKey="favoritePropertiesCountyId">
                                        <Select
                                            isMulti
                                            name="favoritePropertiesCountyId"
                                            options={criteriaOptions.favoritePropertyCounties.map(county => ({ value: county, label: county }))}
                                            value={selectedCriteria.favoritePropertiesCountyId.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('favoritePropertiesCountyId', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                    {/* Registration County */}
                                    <Tab.Pane eventKey="registrationCounty">
                                        <Select
                                            isMulti
                                            name="registrationCounty"
                                            options={criteriaOptions.registrationCounties.map(county => ({ value: county, label: county }))}
                                            value={selectedCriteria.registrationCounty.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('registrationCounty', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                    {/* Registration State */}
                                    <Tab.Pane eventKey="registrationState">
                                        <Select
                                            isMulti
                                            name="registrationState"
                                            options={criteriaOptions.registrationStates.map(state => ({ value: state, label: state }))}
                                            value={selectedCriteria.registrationState.map(value => ({ value, label: value }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange('registrationState', selectedOptions)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowCriteriaModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Button to open Templates management modal */}
                    <Form.Group controlId="manageTemplates" className="mt-3">
                        <Button variant="secondary" onClick={() => setShowTemplatesModal(true)}>
                            Manage Templates
                        </Button>
                    </Form.Group>

                    {/* Modal for Managing Templates */}
                    <Modal
                        show={showTemplatesModal}
                        onHide={() => setShowTemplatesModal(false)}
                        size="xl"
                        aria-labelledby="templates-modal"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="templates-modal">
                                Manage Email Templates
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Templates onSelectTemplate={handleSelectTemplate} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowTemplatesModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Form Fields */}
                    <Form.Group controlId="toEmails" className="mt-3">
                        <Form.Label>Additional Emails</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter recipient email addresses, separated by commas"
                            value={toEmails}
                            onChange={e => setToEmails(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="subject" className="mt-3">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter email subject"
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="htmlContent" className="mt-3">
                        <Form.Label>Content</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={htmlContent}
                            onChange={setHtmlContent}
                            placeholder="Compose your email..."
                            style={{ height: '400px' }}
                            editorStyle={{ height: '350px' }}
                        />
                    </Form.Group>

                    {/* Attachments Section */}
                    <Form.Group controlId="attachments" className="mt-3">
                        <Form.Label>Attachments</Form.Label>
                        <Form.Control
                            type="file"
                            multiple
                            onChange={handleFileChange}
                        />
                        {attachments.length > 0 && (
                            <ul style={{ listStyleType: 'none', paddingLeft: 0, marginTop: '10px' }}>
                                {attachments.map((attachment, index) => (
                                    <li key={index} className="d-flex justify-content-between align-items-center">
                                        <span>{attachment.filename}</span>
                                        <Button variant="outline-danger" size="sm" onClick={() => {
                                            setAttachments(prev => prev.filter((_, i) => i !== index));
                                        }}>
                                            Remove
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Form.Group>

                    {/* Submit Button */}
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="primary" type="submit" disabled={isLoading} style={{ fontSize: '0.85rem' }}>
                            {isLoading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    Sending...
                                </>
                            ) : (
                                'Send Email'
                            )}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );

};

export default EmailComponent;
