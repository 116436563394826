import React from "react";
import image1 from "../images/sri-government-partners.png";
import image2 from "../images/sri-partners-request-a-partner-account.png";
import image3 from "../images/sri-partners-why-sri.png";
import styles from '../css/Partners.module.css';
import { Link } from 'react-router-dom';  

function Partners() {
    return (
        <div>
            <div className={styles.headerSection} style={{ backgroundImage: `url(${image1})` }}>
                <h1 className={styles.headerTitle}>Government Partners</h1>
            </div>
            
            <div className={styles.contentContainer}>
                <div className={styles.leftSide}>
                    <h2 className={styles.leftSideTitle}>We’ll Take the Weight Off Your Shoulders</h2>
                </div>
                <div className={styles.rightSide}>
                    <h4>Delinquent tax collection and auctions can be a lot of work. Let us do the heavy lifting.</h4>
                    <p>As a government official, you have enough to do—and that’s before property auctions are introduced. SRI partners with you to take care of everything from processing to conducting the event so you can focus on taxpayers.</p>
                </div>
            </div>

            <div className={styles.requestSection} style={{ backgroundImage: `url(${image2})` }}>
                <h1 className={styles.headerTitle}>Request a Partner Account</h1>
                <Link to="/support"> 
                    <button className={`btn rounded-pill ${styles.btnStyle}`}>OPEN REQUEST</button>
                </Link>
            </div>
            <div className={styles.BottomContentContainer}>
                <div className={styles.whySRIContainer}>
                    <div className={styles.whySRISide}>
                        <center><h2>Why SRI?</h2></center>
                        <ul><b>Delinquent Taxes</b>
                            <li>Delinquent Lists</li>
                            <li>Redemption</li>
                            <li>Issuance</li>
                        </ul>
                        <ul><b>Sheriff Sales</b></ul>
                        <ul><b>Tax Sale Processing</b></ul>
                        <ul><b>Tax Sale Processing Auctions</b></ul>
                        <ul><b>Mortgage Foreclosure Processing</b>
                            <li>Working with Lawyers</li>
                        </ul>
                        <ul><b>Mortgage Foreclosure Auctions</b>
                            <li>Guiding Buyers</li>
                        </ul>
                    </div>

                    <div className={styles.whySRISide}>
                        <img src={image3} alt="Why SRI Image" className={styles.imgStyle} />
                    </div>
                </div>

                <center className={styles.contactContainer}>
                    <div className={styles.contactSection}>
                        <h2>Interested in finding a partner to simplify your workload?</h2>
                        <Link to="/support">  
                            <button className={`btn rounded-pill ${styles.btnStyleBlack}`}>CONTACT US</button>
                        </Link>
                    </div>
                </center>
            </div>
        </div>
    );
}

export default Partners;
