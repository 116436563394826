import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import UploadDocumentComponent from './UploadDocumentComponent';

const UploadDocumentModal = ({ show, onHide, onUploadSuccess }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/*
          IMPORTANT: Pass onUploadSuccess to UploadDocumentComponent
        */}
        <UploadDocumentComponent onUploadSuccess={onUploadSuccess} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDocumentModal;
