import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import {
  updateUser, updateOrganization, updateUserAddresses, updateOrgAddresses,
  selectUserInfo, setProfileUpdated
} from '../redux/userSlice';
import { selectTokens } from '../redux/userSlice';
import SuccessModal from './SuccessModal';
import US_STATES from './states';
import LoadingAnimation from './LoadingAnimation';

const emptyAddress = { street_address: '', street_address2: '', city: '', state: '', zip: '' };
const emptyOrg = {
  business_name: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  phone: '',
  fax: '',
  addresses: [emptyAddress]
};

const SERVER_API_KEY = process.env.REACT_APP_SERVER_API_KEY;

const Profile = () => {
  const { user = {}, organizations = [] } = useSelector(selectUserInfo) || {};
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [localUserInfo, setLocalUserInfo] = useState(user);
  const [localOrgInfo, setLocalOrgInfo] = useState(organizations[0] || emptyOrg);
  const [localNotifications, setLocalNotifications] = useState(user.notifications || []);
  const tokens = useSelector(selectTokens);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [sendEmailPreference, setSendEmailPreference] = useState(false);

  // State for storing initial values
  const [initialUserInfo, setInitialUserInfo] = useState({});
  const [initialOrgInfo, setInitialOrgInfo] = useState({});

  const stateOptions = US_STATES.map(state => ({
    value: state.code,
    label: state.name
  }));

  const renderDropdown = (name, label, value, onChangeHandler) => (
    <div className="col-md-4">
      <div className="label-bg">
        <label>{label}:</label>
      </div>
      <Select
        className="form-control"
        value={stateOptions.find(option => option.value === value)}
        onChange={(option) => onChangeHandler(name, option.value)}
        options={stateOptions}
        placeholder="Select a State"
      />
    </div>
  );

  useEffect(() => {
    const updatedUser = {
      ...user,
      addresses: user?.addresses?.length > 0 ? user.addresses : [emptyAddress]
    };
    const updatedOrg = organizations?.length > 0 ? {
      ...organizations[0],
      addresses: organizations[0]?.addresses?.length > 0 ? organizations[0].addresses : [emptyAddress]
    } : emptyOrg;

    setLocalUserInfo(updatedUser);
    setLocalOrgInfo(updatedOrg);
    setLocalNotifications(user?.notifications || []);
   

    setInitialUserInfo(updatedUser);
    setInitialOrgInfo(updatedOrg);
  }, [user, organizations]);

  useEffect(() => {
    if (user?.fusionauth_user_id && tokens.accessToken) {
      fetchUserPreferences();
    }
  }, [user?.fusionauth_user_id, tokens.accessToken]);
  

  const fetchUserPreferences = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/preferences/user/${user.fusionauth_user_id}`, {
        headers: {
          'x-api-key': SERVER_API_KEY,
          'Authorization': `Bearer ${tokens.accessToken}`
        }
      });
  
      if (response.status === 200) {
        const preferences = response.data;
        setSendEmailPreference(preferences.sendEmail === 1);
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error);
    }
  };
  
  

  const handleUserInfoChange = (field, value) => {
    setLocalUserInfo({ ...localUserInfo, [field]: value });
  };

  const handleOrgInfoChange = (field, value) => {
    setLocalOrgInfo({ ...localOrgInfo, [field]: value });
  };

  // Function to revert to the initial state
  const handleRevertChanges = () => {
    setLocalUserInfo(initialUserInfo);
    setLocalOrgInfo(initialOrgInfo);
    setLocalNotifications(initialUserInfo.notifications || []);
     };

  const handleUserAddressChange = (field, value) => {
    const updatedAddress = localUserInfo.addresses?.[0]
      ? { ...localUserInfo.addresses[0], [field]: value }
      : { ...emptyAddress, [field]: value };
    setLocalUserInfo({ ...localUserInfo, addresses: [updatedAddress] });
  };

  const handleOrgAddressChange = (field, value) => {
    const updatedAddress = localOrgInfo.addresses?.[0]
      ? { ...localOrgInfo.addresses[0], [field]: value }
      : { ...emptyAddress, [field]: value };
    setLocalOrgInfo({ ...localOrgInfo, addresses: [updatedAddress] });
  };

  // Helper function to check if a value is different from the initial value
  const isValueChanged = (name, value) => {
    return initialUserInfo[name] !== value;
  };

  const renderInputField = (name, label, value, onChangeHandler, isEditable = true) => (
    <div className="col-md-4">
      <div className="label-bg">
        <label>{label}:</label>
      </div>
      <input
        className={`form-control ${isValueChanged(name, value) ? 'glow-yellow' : ''}`}
        type="text"
        value={value || ''}
        onChange={(e) => onChangeHandler(name, e.target.value)}
        disabled={!isEditable} // Disable the field if not editable
      />
    </div>
  );

  const handleToggleSendEmail = async (e) => {
    const newValue = e.target.checked;
    setSendEmailPreference(newValue);
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/preferences/user/${user.fusionauth_user_id}/update`,
        {
          setting_name: 'sendEmail',
          setting_value: newValue ? 1 : 0, // Send as number
          setting_description: 'Receive New Sale Notifications via Email'
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': SERVER_API_KEY,
            'Authorization': `Bearer ${tokens.accessToken}`
          }
        }
      );
  
      if (response.status !== 200) {
        throw new Error('Failed to update preferences');
      }
    } catch (error) {
      console.error('Error updating preferences:', error);
      setSendEmailPreference(!newValue); // Revert the toggle if the update fails
    }
  };
  
  

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
        // Prepare user and organization data
        const userData = { ...localUserInfo };
        const userAddress = localUserInfo.addresses?.[0] || null;
        const orgData = localOrgInfo && Object.keys(localOrgInfo).length > 0 ? { ...localOrgInfo } : null;
        const orgAddress = orgData?.addresses?.[0] || null;

        const updateUserInfoUrl = `${process.env.REACT_APP_SERVER_URL}/app/me/userinfo`;

        // Remove unnecessary fields from userData before sending the request
        delete userData.addresses;
        delete userData.applications;
        delete userData.notifications;
        delete userData.roles;

        const dataToSend = { userData };

        if (userAddress) {
            dataToSend.userAddress = userAddress;
        }
        if (orgData) {
            delete orgData.addresses; // Clean organization data before sending
            dataToSend.orgData = orgData;
        }
        if (orgAddress) {
            dataToSend.orgAddress = orgAddress;
        }

        // Send the request to update user info
        await axios.put(updateUserInfoUrl, dataToSend, {
            headers: {
                Authorization: `Bearer ${tokens.accessToken}`,
                'x-api-key': SERVER_API_KEY,
                'Content-Type': 'application/json'
            }
        });

        // Update the Redux store with the latest user and organization data
        dispatch(updateUser(localUserInfo));
        if (orgData) {
            dispatch(updateOrganization([localOrgInfo]));
        }
        if (userAddress) {
            dispatch(updateUserAddresses([userAddress]));
        }
        if (orgAddress) {
            dispatch(updateOrgAddresses([orgAddress]));
        }
        // Set profile updated flag
        dispatch(setProfileUpdated(true));

        // Show success modal on successful update
        setShowSuccessModal(true);
        console.log('Profile updated successfully');
    } catch (error) {
        console.error('Error in updating profile:', error);
        alert('Error in updating profile. To be replaced with a modal.');
    } finally {
        setIsLoading(false);
    }
};

if (isLoading) {
    return <LoadingAnimation />;
}

return (
    <div className="container mt-4">
        <SuccessModal show={showSuccessModal} onClose={() => setShowSuccessModal(false)} />
      <div className="accordion" id="profileAccordion">
        {/* User Information Section */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="userInformationHeader">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#userInformationCollapse" aria-expanded="true">
              User Information
            </button>
          </h2>
          <div id="userInformationCollapse" className="accordion-collapse collapse show">
            <div className="accordion-body">
              <div className="row">
                {/* Render user info fields */}
                {renderInputField('first_name', 'First Name', localUserInfo.first_name, handleUserInfoChange)}
                {renderInputField('middle_name', 'Middle Name', localUserInfo.middle_name, handleUserInfoChange)}
                {renderInputField('last_name', 'Last Name', localUserInfo.last_name, handleUserInfoChange)}
                {renderInputField('phone', 'Phone Number', localUserInfo.phone, handleUserInfoChange)}
                {renderInputField('email', 'Email', localUserInfo.email, handleUserInfoChange, false)} {/* Email field is now non-editable */}
              </div>
              <div className="row">
                {/* Render user address fields */}
                {renderInputField('street_address', 'Street Address', localUserInfo.addresses?.[0]?.street_address, handleUserAddressChange)}
                {renderInputField('street_address2', 'Street Address 2', localUserInfo.addresses?.[0]?.street_address2, handleUserAddressChange)}
                {renderInputField('city', 'City', localUserInfo.addresses?.[0]?.city, handleUserAddressChange)}
                {renderDropdown('state', 'State', localUserInfo.addresses?.[0]?.state, handleUserAddressChange)}
                {renderInputField('zip', 'Zip Code', localUserInfo.addresses?.[0]?.zip, handleUserAddressChange)}
              </div>
            </div>
          </div>
        </div> 

        {/* Organization Information Section */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="organizationHeader">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#organizationCollapse">
              Organization Information
            </button>
          </h2>
          <div id="organizationCollapse" className="accordion-collapse collapse">
            <div className="accordion-body">
              <div className="row">
                {/* Render organization info fields */}
                {renderInputField('business_name', 'Does Business As', localOrgInfo.business_name, handleOrgInfoChange)}
                {renderInputField('first_name', 'Contact First Name', localOrgInfo.first_name, handleOrgInfoChange)}
                {renderInputField('middle_name', 'Contact Middle Name', localOrgInfo.middle_name, handleOrgInfoChange)}
                {renderInputField('last_name', 'Contact Last Name', localOrgInfo.last_name, handleOrgInfoChange)}
                {renderInputField('phone', 'Phone Number', localOrgInfo.phone, handleOrgInfoChange)}
                {renderInputField('fax', 'Fax Number', localOrgInfo.fax, handleOrgInfoChange)}
              </div>
              <div className="row">
                {/* Render organization address fields */}
                {renderInputField('street_address', 'Street Address', localOrgInfo.addresses?.[0]?.street_address, handleOrgAddressChange)}
                {renderInputField('street_address2', 'Street Address 2', localOrgInfo.addresses?.[0]?.street_address2, handleOrgAddressChange)}
                {renderInputField('city', 'City', localOrgInfo.addresses?.[0]?.city, handleOrgAddressChange)}
                {renderDropdown('state', 'State', localOrgInfo.addresses?.[0]?.state, handleOrgAddressChange)}
                {renderInputField('zip', 'Zip Code', localOrgInfo.addresses?.[0]?.zip, handleOrgAddressChange)}
              </div>
            </div>
          </div>
        </div>

        {/* Notifications Section */}
          <div className="mt-4">
            <center>
            <h6>Notifications Preferences</h6>
            <div
              className="form-group"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '10px',
                marginBottom: '20px',
                padding: '20px',
                border: '1px solid #d3d3d3',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                maxWidth: '400px',
                margin: '0 auto',
                backgroundColor: sendEmailPreference ? '#d4f7dc' : '#ffd6d6'  // Green for ON, Red for OFF
              }}
            >
              <label htmlFor="send-email-switch" style={{ marginBottom: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                Receive New Sale Notifications via Email
              </label>
              <input
                type="checkbox"
                id="send-email-switch"
                checked={sendEmailPreference}
                onChange={handleToggleSendEmail}
                style={{ transform: 'scale(1.5)', marginBottom: '10px' }}
              />
              <div style={{ fontSize: '14px', fontWeight: 'bold', color: sendEmailPreference ? 'green' : 'red' }}>
                {sendEmailPreference ? 'Notifications Enabled' : 'Notifications Disabled'}
              </div>
            </div>
            </center>
          </div>
          



        {/* Save Changes Button */}
        <div className="mt-4">
          <center>
            <button className="btn btn-primary" onClick={handleSubmit}>Save Changes</button>
            <button className="btn btn-secondary" onClick={handleRevertChanges} disabled={isLoading}>Revert Changes</button>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Profile;
