import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MondayBoards.css';
import { Accordion } from 'react-bootstrap';
import LoadingAnimation from './LoadingAnimation';

const MondayBoards = () => {
    const [boards, setBoards] = useState([]);
    const [boardContents, setBoardContents] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchBoards = async () => {
            try {
                const boardsResponse = await axios.post('https://api.monday.com/v2', {
                    query: `query { boards { id name } }`
                }, {
                    headers: {
                        'Authorization': process.env.REACT_APP_MONDAY_API_KEY,
                        'Content-Type': 'application/json'
                    }
                });

                if (boardsResponse.data?.data?.boards) {
                    setBoards(boardsResponse.data.data.boards);
                    await Promise.all(boardsResponse.data.data.boards.map(board => fetchBoardContent(board.id)));
                } else {
                    console.log('No boards data found in response');
                }
            } catch (error) {
                console.error('Error fetching boards:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBoards();
    }, []);

    const fetchBoardContent = async (boardId) => {
        try {
            const response = await axios.post('https://api.monday.com/v2', {
                query: `query { boards(ids: ${boardId}) { items { id name column_values { text value } } } }`
            }, {
                headers: {
                    'Authorization': process.env.REACT_APP_MONDAY_API_KEY,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data?.data?.boards) {
                setBoardContents(prev => ({ ...prev, [boardId]: response.data.data.boards[0] }));
            } else {
                console.log('No board content data found in response for board ID:', boardId);
            }
        } catch (error) {
            console.error('Error fetching board content:', error);
        }
    };

    const isUrl = (string) => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' +
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            '(\\#[-a-z\\d_]*)?$', 'i');
        return !!urlPattern.test(string);
    };

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return (
        <div className="monday-boards-container">
            <Accordion defaultActiveKey="0">
                {boards.map(board => (
                    <Accordion.Item eventKey={board.id.toString()} key={board.id}>
                        <Accordion.Header>
                            {board.name}
                        </Accordion.Header>
                        <Accordion.Body>
                            {boardContents[board.id] && (
                                <Accordion>
                                    {boardContents[board.id].items.map(item => (
                                        <Accordion.Item eventKey={item.id} key={item.id}>
                                            <Accordion.Header>
                                                {item.name}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p><strong>ID:</strong> {item.id}</p>
                                                {item.column_values.map((column, index) => (
                                                    <div key={index}>
                                                        {isUrl(column.text || column.value) ? (
                                                            <a href={column.text || column.value} target="_blank" rel="noopener noreferrer">
                                                                {column.text || column.value}
                                                            </a>
                                                        ) : (
                                                            <span>{column.text || column.value || 'N/A'}</span>
                                                        )}
                                                    </div>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default MondayBoards;
