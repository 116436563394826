// src/InactivityTimer.js

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, selectUserInfo } from './redux/userSlice'; 
import './InactivityTimer.css'; // Import the CSS file

// Timer durations in milliseconds
const WARNING_TIME = 30 * 60 * 1000; // 1 minute before warning
const LOGOUT_TIME = 1 * 60 * 1000; // 1 minute after warning

const InactivityTimer = ({ children }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const isLoggedIn = userInfo.tokens.accessToken && userInfo.user && Object.keys(userInfo.user).length > 0;

  const [isWarning, setIsWarning] = useState(false);
  const [countdown, setCountdown] = useState(LOGOUT_TIME / 1000);
  const warningTimeout = useRef(null);
  const logoutTimeout = useRef(null);
  const countdownInterval = useRef(null);

  // Function to reset the inactivity timer
  const resetTimer = () => {
    console.log('[InactivityTimer] Resetting timer due to user activity.');

    // Clear existing timeouts and intervals
    if (warningTimeout.current) {
      clearTimeout(warningTimeout.current);
      console.log('[InactivityTimer] Cleared warning timeout.');
    }
    if (logoutTimeout.current) {
      clearTimeout(logoutTimeout.current);
      console.log('[InactivityTimer] Cleared logout timeout.');
    }
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current);
      console.log('[InactivityTimer] Cleared countdown interval.');
    }

    // Hide the warning and reset countdown
    setIsWarning(false);
    setCountdown(LOGOUT_TIME / 1000);
    console.log('[InactivityTimer] Warning modal hidden and countdown reset.');

    // Set timeout to show warning after WARNING_TIME
    warningTimeout.current = setTimeout(() => {
      console.log('[InactivityTimer] WARNING_TIME elapsed. Showing warning modal.');
      setIsWarning(true);
      setCountdown(LOGOUT_TIME / 1000);

      // Start countdown interval
      countdownInterval.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            console.log('[InactivityTimer] Countdown reached zero. Initiating logout.');
            clearInterval(countdownInterval.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      console.log('[InactivityTimer] Countdown interval started.');

      // Set timeout to logout after LOGOUT_TIME
      logoutTimeout.current = setTimeout(() => {
        console.log('[InactivityTimer] LOGOUT_TIME elapsed. Logging out user.');
        handleLogout();
      }, LOGOUT_TIME);
      console.log('[InactivityTimer] Logout timeout set.');
    }, WARNING_TIME);
    console.log(`[InactivityTimer] Warning timeout set for ${WARNING_TIME / 1000} seconds.`);
  };

  // Function to handle user activity
  const handleActivity = () => {
    console.log('[InactivityTimer] User activity detected.');
    if (isWarning) {
      console.log('[InactivityTimer] Warning modal is active. Hiding and resetting timer.');
      setIsWarning(false);
      if (countdownInterval.current) {
        clearInterval(countdownInterval.current);
        console.log('[InactivityTimer] Cleared countdown interval due to user activity.');
      }
    }
    resetTimer();
  };

  // Function to handle logout by dispatching the logoutUser thunk
  const handleLogout = () => {
    console.log('[InactivityTimer] Dispatching logoutUser thunk.');
    dispatch(logoutUser());
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'scroll'];
    let throttledHandleActivity = handleActivity;

    // Optional: Implement throttling to improve performance for high-frequency events
    // Uncomment the following lines if you want to use throttling
    // import throttle from 'lodash.throttle';
    // throttledHandleActivity = throttle(handleActivity, 1000); // Throttle to once per second

    if (isLoggedIn) {
      // Add event listeners for user activity
      events.forEach((event) => {
        window.addEventListener(event, throttledHandleActivity);
        console.log(`[InactivityTimer] Added event listener for ${event}.`);
      });

      // Initialize the inactivity timer
      resetTimer();
    }

    // Cleanup on component unmount or when user logs out
    return () => {
      if (isLoggedIn) {
        events.forEach((event) => {
          window.removeEventListener(event, throttledHandleActivity);
          console.log(`[InactivityTimer] Removed event listener for ${event}.`);
        });
        if (warningTimeout.current) clearTimeout(warningTimeout.current);
        if (logoutTimeout.current) clearTimeout(logoutTimeout.current);
        if (countdownInterval.current) clearInterval(countdownInterval.current);
        console.log('[InactivityTimer] Cleaned up all timers and event listeners.');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]); // Dependency on isLoggedIn

  return (
    <>
      {children}
      {isWarning && isLoggedIn && (
        <div className="alert-container" role="alertdialog" aria-modal="true" aria-labelledby="logout-warning-title">
          <div className="alert-box">
            <h2 id="logout-warning-title" className="title">⚠️ Inactivity Warning ⚠️</h2>
            <p className="message">
              You will be logged out due to inactivity in <strong>{countdown}</strong> {countdown === 1 ? 'second' : 'seconds'}.
            </p>
            <button onClick={handleActivity} className="button">
              Stay Logged In
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InactivityTimer;
