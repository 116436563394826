import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import UserManagementComponent from './UserManagementComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';


const UserManagementModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="manager-square" onClick={handleShow}>
                <FontAwesomeIcon icon={faUsersCog} className="manager-icon" />
                <div className="manager-text">User Management</div>
            </div>

            <Modal show={show} onHide={handleClose} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>User Management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserManagementComponent />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserManagementModal;
