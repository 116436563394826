// src/components/MessageComponent.js

import React, { useState, useEffect } from 'react';
import { ListGroup, Button, ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { selectTokens, setNotifications } from '../redux/userSlice';
import LoadingAnimation from './LoadingAnimation';
import './MessageComponent.css';

// ICONS
import { 
  FaEnvelope, 
  FaEnvelopeOpenText, 
  FaEye, 
  FaEyeSlash, 
  FaTrashAlt 
} from 'react-icons/fa';

const MessageComponent = () => {
  const [messages, setMessages] = useState([]);
  const [isSortDescending, setIsSortDescending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('all');

  const tokens = useSelector(selectTokens);
  const userId = useSelector((state) => state.user.user.fusionauth_user_id);
  const dispatch = useDispatch();
  const apiKey = process.env.REACT_APP_SERVER_API_KEY;

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, tokens.accessToken]);

  const fetchMessages = async () => {
    setIsLoading(true);
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    if (!baseUrl || !userId || !tokens.accessToken) {
      console.error('Missing configuration or tokens');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${baseUrl}/app/messages/notifications/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
            'x-api-key': apiKey,
          },
        }
      );
      // Sort oldest to newest by default
      const sortedMessages = response.data.sort(
        (a, b) => new Date(a.date_created) - new Date(b.date_created)
      );
      setMessages(sortedMessages);
      dispatch(setNotifications(response.data));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
    setIsLoading(false);
  };

  const handleAction = async (action, message) => {
    setIsLoading(true);
    if (!tokens.accessToken) {
      console.error('Access token is not available');
      setIsLoading(false);
      return;
    }

    try {
      let response;
      if (action === 'toggleRead') {
        const newReadStatus = message.is_read === 0 ? 1 : 0;
        const readAt =
          newReadStatus === 1
            ? new Date().toISOString().slice(0, 19).replace('T', ' ')
            : null;
        response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/app/messages/notifications/${message.notification_id}`,
          { is_read: newReadStatus, read_at: readAt },
          { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
        );
      } else if (action === 'delete') {
        response = await axios.delete(
          `${process.env.REACT_APP_SERVER_URL}/app/messages/notifications/${message.notification_id}`,
          { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
        );
      }

      if (response && response.status === 200) {
        fetchMessages();
      }
    } catch (error) {
      console.error(`Error performing ${action} action:`, error);
    }
    setIsLoading(false);
  };

  const toggleSortOrder = () => {
    setIsSortDescending(!isSortDescending);
    setMessages((prev) =>
      [...prev].sort((a, b) =>
        isSortDescending
          ? new Date(a.date_created) - new Date(b.date_created)
          : new Date(b.date_created) - new Date(a.date_created)
      )
    );
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  // Filter messages based on read/unread or 'all'
  const filteredMessages = messages.filter((msg) => {
    if (filter === 'read') return msg.is_read === 1;
    if (filter === 'unread') return msg.is_read === 0;
    return true;
  });

  return (
    <div className="my-message-component-container">
      {/* Header Section */}
      <div className="my-message-component-header">
        {/* Title area (left) */}
        <div className="my-message-component-header-left">
          <span className="my-message-component-title">Notifications</span>
        </div>

        {/* Filter Buttons (center) */}
        <div className="my-message-component-header-middle">
          <ButtonGroup aria-label="Filter Messages" className="btn-group-custom">
            <Button
              variant={filter === 'all' ? 'dark' : 'outline-dark'}
              size="sm"
              onClick={() => handleFilterChange('all')}
            >
              All
            </Button>
            <Button
              variant={filter === 'read' ? 'dark' : 'outline-dark'}
              size="sm"
              onClick={() => handleFilterChange('read')}
            >
              Read
            </Button>
            <Button
              variant={filter === 'unread' ? 'dark' : 'outline-dark'}
              size="sm"
              onClick={() => handleFilterChange('unread')}
            >
              Unread
            </Button>
          </ButtonGroup>
        </div>

        {/* Sort Button (right) */}
        <div className="my-message-component-header-right">
          <Button
            variant="light"
            size="sm"
            onClick={toggleSortOrder}
            className="sort-button"
          >
            Sort {isSortDescending ? 'Ascending' : 'Descending'}
          </Button>
        </div>
      </div>

      {/* Content Area */}
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <div className="my-message-component-content">
          {filteredMessages.length > 0 ? (
            <ListGroup className="my-message-list">
              {filteredMessages.map((message) => (
                <ListGroup.Item
                  key={message.notification_id}
                  className={`my-message-component-item ${
                    message.is_read ? 'my-message-read' : 'my-message-unread'
                  }`}
                >
                  {/* Row wrapper: left side (icon + text) and right side (action icons) */}
                  <div className="my-message-row">
                    <div className="my-message-left">
                      {message.is_read ? (
                        <FaEnvelopeOpenText className="icon-read" />
                      ) : (
                        <FaEnvelope className="icon-unread" />
                      )}
                      <div className="my-message-info">
                        <span className="my-message-text">
                          {message.message}
                        </span>
                        <span className="my-message-date">
                          Sent on {new Date(message.date_created).toLocaleString()}
                        </span>
                      </div>
                    </div>

                    {/* Action Buttons: read/unread + delete */}
                    <div className="my-message-right">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handleAction('toggleRead', message)}
                        className="action-button"
                        title={message.is_read ? 'Unmark as Read' : 'Mark as Read'}
                      >
                        {message.is_read ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleAction('delete', message)}
                        className="action-button"
                        title="Delete"
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div className="my-message-no-notifications">
              You have no notifications.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageComponent;
