import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import US_STATES from './states';
import COUNTIES from './Counties';
import axios from 'axios';

const EditDocumentModal = ({ show, onHide, document, onSave }) => {
    const [doc, setDoc] = useState({
        name: '',
        docType: '',
        propertyId: '',
        containerName: '',
        expirationDate: new Date(),
        state: '',
        county: '',
        saleDate: new Date(),
        saleId: '',
    });

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return isNaN(date.getTime()) ? null : date; // Return null if date is invalid
    };
    
    useEffect(() => {
        if (document && document.metadata) {
            setDoc({
                name: document.name || '',
                docType: document.metadata.docType || '',
                propertyId: document.metadata.propertyId || '',
                containerName: document.containerName || 'Unknown',
                expirationDate: formatDate(document.metadata.expirationDate) || undefined,
                state: document.metadata.state || '',
                county: document.metadata.county || '',
                saleDate: formatDate(document.metadata.saleDate) || undefined,
                saleId: document.metadata.saleId || '',
            });
        }
    }, [document]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDoc(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {
        try {
            const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
            const payload = {
                metadata: {
                    docType: doc.docType,
                    propertyId: doc.propertyId,
                    expirationDate: doc.expirationDate.toISOString().split('T')[0],
                    state: doc.state,
                    county: doc.county,
                    saleDate: doc.saleDate.toISOString().split('T')[0],
                    saleId: doc.saleId,
                }
            };

            await axios.put(`${API_BASE_URL}/blob/container/${doc.containerName}/update/${doc.name}`, payload);
            onSave();  // Refresh the parent component
            onHide();  // Close the modal
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    const handleDelete = async () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        try {
            const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
            const API_KEY = process.env.REACT_APP_SERVER_API_KEY;
            await axios.put(`${API_BASE_URL}/blob/container/${doc.containerName}/update/${doc.name}`, {
                metadata: {
                    ...doc,
                    expirationDate: yesterday.toISOString().split('T')[0], // Set expiration date to yesterday
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-api-key': API_KEY
                },
            });
            onSave();  // Refresh after "deletion"
            onHide();  // Close the modal
        } catch (error) {
            console.error('Error "deleting" document by setting expiration:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormGroup className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <FormControl type="text" name="name" value={doc.name} onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Type</Form.Label>
                        <FormControl as="select" name="docType" value={doc.docType} onChange={handleInputChange}>
                            <option value="">Select Document Type</option>
                            <option value="CountyDocs">County Documents</option>
                            <option value="GenDocs">General Documents</option>
                            <option value="Image">Image</option>
                            <option value="PropDocs">Property Documents</option>
                            <option value="SaleDocs">Sale Documents</option>
                            <option value="sb156">SB156</option>
                            <option value="SRIDocs">SRI Documents</option>
                            <option value="StateDocs">State Documents</option>
                            <option value="TaxDocs">Tax Documents</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Property ID</Form.Label>
                        <FormControl type="text" name="propertyId" value={doc.propertyId} onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Container</Form.Label>
                        <FormControl type="text" name="containerName" value={doc.containerName} onChange={handleInputChange} readOnly />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Expiration Date</Form.Label>
                        <DatePicker
                            selected={doc.expirationDate}
                            onChange={date => setDoc({...doc, expirationDate: date})}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            placeholderText="Select expiration date"
                            isClearable
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control as="select" name="state" value={doc.state} onChange={(e) => setDoc({...doc, state: e.target.value, county: ''})}>
                            <option value="">Select State</option>
                            {US_STATES.map(state => (
                                <option key={state.code} value={state.code}>{state.name}</option>
                            ))}
                        </Form.Control>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>County</Form.Label>
                        <Form.Control as="select" name="county" value={doc.county} onChange={(e) => setDoc({...doc, county: e.target.value})}>
                            <option value="">Select County</option>
                            {COUNTIES[doc.state]?.map(county => (
                                <option key={county.code} value={county.code}>{county.name}</option>
                            ))}
                        </Form.Control>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Sale Date</Form.Label>
                        <DatePicker
                            selected={doc.saleDate}
                            onChange={date => setDoc({...doc, saleDate: date})}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            placeholderText="Select sale date"
                            isClearable
/>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label>Sale ID</Form.Label>
                        <FormControl type="text" name="saleId" value={doc.saleId} onChange={handleInputChange} />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
                <Button variant="primary" onClick={handleSave}>Save Changes</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditDocumentModal;
