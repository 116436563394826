// src/components/AppPortal.js

import React from 'react';
import { useSelector } from 'react-redux';
import { selectTokens, selectAppRoles } from '../redux/userSlice';
import AppItem from './AppItem';
import './AppPortal.css'; // <--- Unique CSS file

const AppPortal = () => {
  const tokens = useSelector(selectTokens);
  const appRoles = useSelector(selectAppRoles) || {};

  const applications = {
    TMS: {
      name: 'TaxSale Management System',
      url: process.env.REACT_APP_TSM_URL,
    },
    LAP: {
      name: 'Lien Assist Program',
      url: 'https://lienassist.sriservices.com/dash.php',
    },
    NOTICING: {
      name: 'Noticing',
      url: 'https://noticing.sriservices.com',
    },
    FUT: {
      name: 'FUT',
      url: process.env.REACT_APP_FUT_URL,
    },
    // Add other applications as necessary
  };

  const handleAppSelection = (appUrl) => {
    const urlWithTokens = `${appUrl}?accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}`;
    window.open(urlWithTokens, '_blank'); // Opens the URL in a new tab
  };

  const userSystems = Object.keys(applications)
    .filter((key) => appRoles.hasOwnProperty(key))
    .map((key) => applications[key]);

  return (
    <div className="my-app-portal-container">
      <div className="my-app-portal-header">
        Application Portal
      </div>
      <div className="my-app-portal-content">
        {userSystems.length > 0 ? (
          userSystems.map((app, index) => (
            <AppItem
              key={index}
              name={app.name}
              url={app.url}
              onAppSelect={() => handleAppSelection(app.url)}
            />
          ))
        ) : (
          <div className="my-app-portal-no-apps">
            No apps available.
          </div>
        )}
      </div>
    </div>
  );
};

export default AppPortal;
