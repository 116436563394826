// src/redux/registrationSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/**
 * Returns an array of any missing field labels,
 * or an empty array if the profile is complete.
 */
function getMissingProfileFields(user) {
  if (!user || !user.fusionauth_user_id) {
    return ['No user logged in'];
  }

  // Basic required fields
  const requiredFields = [
    { key: 'first_name', label: 'First Name' },
    { key: 'last_name',  label: 'Last Name' },
    { key: 'phone',      label: 'Phone Number' },
  ];

  // Required address fields
  const requiredAddressFields = [
    { key: 'street_address', label: 'Street Address' },
    { key: 'city',           label: 'City' },
    { key: 'state',          label: 'State' },
    { key: 'zip',            label: 'Zip Code' },
  ];

  let missing = [];

  // Check top-level fields
  requiredFields.forEach(f => {
    if (!user[f.key] || user[f.key].trim() === '') {
      missing.push(f.label);
    }
  });

  // Check address
  if (!Array.isArray(user.addresses) || user.addresses.length === 0) {
    missing = missing.concat(requiredAddressFields.map(f => f.label));
  } else {
    const address = user.addresses[0];
    requiredAddressFields.forEach(f => {
      if (!address[f.key] || address[f.key].trim() === '') {
        missing.push(f.label);
      }
    });
  }

  return missing;
}

// Thunk: fetch user registrations
export const fetchUserRegistrations = createAsyncThunk(
  'registration/fetchUserRegistrations',
  async (_, { getState, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const user = getState().user.user;
    if (!user?.fusionauth_user_id) {
      return rejectWithValue('No user ID available.');
    }
    try {
      const userId = user.fusionauth_user_id;
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/sales/user/${userId}/registrations`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data) {
        return response.data;
      } else {
        return rejectWithValue('No registrations data found.');
      }
    } catch (error) {
      console.error('Error fetching user registrations:', error);
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Failed to fetch registrations.'
      );
    }
  }
);

// Thunk: register for a sale
export const registerForSale = createAsyncThunk(
  'registration/registerForSale',
  async ({ saleId, auction }, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const user = getState().user.user;
    if (!user?.fusionauth_user_id) {
      return rejectWithValue('No user ID available.');
    }

    // Check profile for missing fields
    const missingFields = getMissingProfileFields(user);
    if (missingFields.length > 0) {
      return rejectWithValue({ error: 'INCOMPLETE_PROFILE', missingFields });
    }

    try {
      const auctionDate = new Date(auction.auctionDate);
      if (isNaN(auctionDate)) {
        return rejectWithValue('Invalid auction date.');
      }
      const sale_date = auctionDate.toISOString().split('T')[0]; // YYYY-MM-DD

      const reqBody = {
        userId: user.fusionauth_user_id,
        saleId,
        sale_date,
        state: auction.state,
        county: auction.county,
        property: {
          ...auction,
          auctionDetails: {
            date: auction.auctionDate,
            time: auction.auctionDetail?.time || '',
            location: auction.auctionDetail?.location || '',
            auctionStyle: auction.auctionDetail?.type || '',
            saleStatusDescription: auction.auctionDetail?.status || '',
          },
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/sales/register`,
        reqBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        // Refresh registrations
        await dispatch(fetchUserRegistrations());
        return response.data;
      } else {
        return rejectWithValue('Failed to register for the sale.');
      }
    } catch (error) {
      console.error('Error registering for sale:', error);
      const errorMessage =
        error.response?.data?.message || error.message || 'Failed to register.';
      if (errorMessage === 'User is already registered for this sale') {
        await dispatch(fetchUserRegistrations());
        return rejectWithValue('ALREADY_REGISTERED');
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Thunk: unregister from sale
export const unregisterFromSale = createAsyncThunk(
  'registration/unregisterFromSale',
  async (saleId, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const user = getState().user.user;
    if (!user?.fusionauth_user_id) {
      return rejectWithValue('No user ID available.');
    }
    try {
      const userId = user.fusionauth_user_id;
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/sales/user/${userId}/sales/${saleId}/unregister`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        // Refresh registrations
        await dispatch(fetchUserRegistrations());
        return response.data;
      } else {
        return rejectWithValue('Failed to unregister from the sale.');
      }
    } catch (error) {
      console.error('Error unregistering from sale:', error);
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Failed to unregister.'
      );
    }
  }
);

const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    registrations: [],
    loading: false,
    error: null, // can be string or object
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchUserRegistrations
      .addCase(fetchUserRegistrations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserRegistrations.fulfilled, (state, action) => {
        state.loading = false;
        state.registrations = action.payload;
      })
      .addCase(fetchUserRegistrations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // might be string
      })

      // registerForSale
      .addCase(registerForSale.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerForSale.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(registerForSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // could be string or object
      })

      // unregisterFromSale
      .addCase(unregisterFromSale.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unregisterFromSale.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(unregisterFromSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default registrationSlice.reducer;
