import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { stateNameToAbbreviation } from '../pages/utils';

// Helper function to format date as YYYY-MM-DD
const formatDateToYYYYMMDD = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ''; // Return empty if invalid date
  return date.toISOString().split('T')[0];
};

// Thunk to fetch user favorites
export const fetchUserFavorites = createAsyncThunk(
  'favorites/fetchUserFavorites',
  async (_, { getState, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('Please login or register to favorite properties.');
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/favorites/user/${userId}/favorites`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data) {
        return response.data;
      } else {
        return rejectWithValue('No favorites data found.');
      }
    } catch (error) {
      console.error('Error fetching user favorites:', error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Thunk to add a favorite (ensuring we send 'state' in two-letter form)
export const addFavorite = createAsyncThunk(
  'favorites/addFavorite',
  async (property, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('Please login or register to favorite properties.');
    }
    try {
      // Check what we received
      console.log('property in addFavorite =>', property);

      // Attempt to grab two-letter code from countyDetail first, or fallback to property.state
      let stateAbbr = property.countyDetail?.stateCode || property.state || '';
      if (stateAbbr.length > 2) {
        stateAbbr = stateNameToAbbreviation[stateAbbr] || '';
      }

      // Build the final request payload
      const favoriteData = {
        propertyId: property.propertyId,
        saleId: property.saleId,
        saleType: property.saleType,
        county: property.county,
        sale_date: formatDateToYYYYMMDD(property.auctionDate), // Format date correctly
        state: stateAbbr,
      };

      console.log('favoriteData =>', favoriteData);

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/favorites/user/${userId}/favorites`,
        favoriteData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        await dispatch(fetchUserFavorites());
        return response.data;
      } else {
        return rejectWithValue('Failed to add favorite.');
      }
    } catch (error) {
      console.error('Error adding favorite:', error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Thunk to remove a favorite
export const removeFavorite = createAsyncThunk(
  'favorites/removeFavorite',
  async (favoriteId, { getState, dispatch, rejectWithValue }) => {
    const { accessToken } = getState().user.tokens;
    const userId = getState().user.user.fusionauth_user_id;
    if (!userId) {
      return rejectWithValue('No user ID available.');
    }
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/favorites/user/${userId}/favorites/${favoriteId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_SERVER_API_KEY,
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        await dispatch(fetchUserFavorites());
        return response.data;
      } else {
        return rejectWithValue('Failed to remove favorite.');
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    favorites: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchUserFavorites
      .addCase(fetchUserFavorites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserFavorites.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites = action.payload;
      })
      .addCase(fetchUserFavorites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle addFavorite
      .addCase(addFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addFavorite.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle removeFavorite
      .addCase(removeFavorite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeFavorite.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeFavorite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default favoritesSlice.reducer;
