import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, Form, Toast } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import US_STATES from './states';
import COUNTIES from './Counties';
import JSZip from 'jszip';
import './UploadDocumentComponent.css';

const UploadDocumentComponent = () => {
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadContainerName, setUploadContainerName] = useState('qa-container');
  const [uploadCategory, setUploadCategory] = useState('');
  const [uploadExpirationDate, setUploadExpirationDate] = useState(new Date());
  const [uploadState, setUploadState] = useState('');
  const [uploadCounty, setUploadCounty] = useState('');
  const [uploadSaleDate, setUploadSaleDate] = useState(new Date());
  const [uploadPropertyIds, setUploadPropertyIds] = useState([]);
  const [uploadSaleType, setUploadSaleType] = useState('');
  const [uploadDocType, setUploadDocType] = useState('');
  const [uploadSaleId, setUploadSaleId] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
  const API_KEY = process.env.REACT_APP_SERVER_API_KEY;

  // Environment variables for container names
  const countyContainer = process.env.REACT_APP_COUNTY_CONTAINER;
  const generalContainer = process.env.REACT_APP_GENERAL_CONTAINER;
  const imageContainer = process.env.REACT_APP_IMAGE_CONTAINER;
  const saleContainer = process.env.REACT_APP_SALE_CONTAINER;
  const sb156Container = process.env.REACT_APP_SB156_CONTAINER;
  const sriContainer = process.env.REACT_APP_SRI_CONTAINER;
  const stateContainer = process.env.REACT_APP_STATE_CONTAINER;
  const taxContainer = process.env.REACT_APP_TAX_CONTAINER;
  const personalContainer = process.env.REACT_APP_PERSONAL_CONTAINER;

  useEffect(() => {
    setUploadCounty(''); // Reset county when state changes
  }, [uploadState]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setUploadFile(selectedFile);
  
    // Directly set the property ID for single files using the user input.
    // Assuming 'uploadPropertyIds' contains the correct ID as the first element.
    if (!/\.zip$/i.test(selectedFile.name)) {
      setUploadPropertyIds([uploadPropertyIds[0]]);
    } else {
      processZipFile(selectedFile);
    }
  };

  const processZipFile = async (file) => {
    const zip = new JSZip();
    await zip.loadAsync(file);
  };

  const handleUpload = async () => {
    if (!uploadFile) {
      setToastMessage('Please select a file.');
      setShowToast(true);
      return;
    }
  
    let uploadResponses = [];
    const isZipFile = /\.zip$/i.test(uploadFile.name);
  
    const appendFormData = (fileContent, fileName) => {
      const formData = new FormData();
      formData.append('file', fileContent, fileName);
  
      const propertyId = isZipFile ? findMatchingPropertyId(fileName) : uploadPropertyIds[0];
      formData.append('propertyId', propertyId);
  
      formData.append('category', uploadCategory);
      formData.append('docType', uploadDocType);
      formData.append('expirationDate', uploadExpirationDate.toISOString().split('T')[0]);
      formData.append('state', uploadState);
      formData.append('county', uploadCounty);
      formData.append('saleType', uploadSaleType);
      formData.append('saleId', uploadSaleId);  // Ensure this is not undefined or empty
      formData.append('saleDate', uploadSaleDate.toISOString().split('T')[0]);
  
      return formData;
    };
  
    const uploadFileToServer = async (formData) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/blob/${uploadContainerName}/blob`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-api-key': API_KEY
          },
        });
        uploadResponses.push(`Upload successful for Property ID: ${formData.get('propertyId')}, Sale ID: ${formData.get('saleId')}`); // Include Sale ID in response
      } catch (error) {
        uploadResponses.push(`Upload failed for Property ID: ${formData.get('propertyId')}, Sale ID: ${formData.get('saleId')}`);
        console.error('Error uploading file:', error);
      }
    };
  
    if (isZipFile) {
      const zip = new JSZip();
      const content = await zip.loadAsync(uploadFile);
      for (const fileName in content.files) {
        if (content.files[fileName].dir) continue;
  
        const fileContent = await content.files[fileName].async("blob");
        const formData = appendFormData(fileContent, fileName);
        await uploadFileToServer(formData);
      }
    } else {
      const formData = appendFormData(uploadFile, uploadFile.name);
      await uploadFileToServer(formData);
    }
  
    setUploadStatus(uploadResponses.join(', '));
    setToastMessage(uploadResponses.join(', '));
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
      setUploadStatus(''); // Clear the upload status after showing toast
    }, 3000);
  };

  const findMatchingPropertyId = (fileName) => {
    return uploadPropertyIds.find(id => fileName.includes(id)) || 'Unknown';
  };

  const handleDocTypeChange = (e) => {
    setUploadDocType(e.target.value);
    switch (e.target.value) {
      case 'CountyDocs':
        setUploadContainerName(countyContainer);
        break;
      case 'GenDocs':
        setUploadContainerName(generalContainer);
        break;
      case 'Image':
        setUploadContainerName(imageContainer);
        break;
      case 'PersonalDocs':
        setUploadContainerName(personalContainer);
        break;
      case 'SaleDocs':
        setUploadContainerName(saleContainer);
        break;
      case 'sb156':
        setUploadContainerName(sb156Container);
        break;
      case 'SRIDocs':
        setUploadContainerName(sriContainer);
        break;
      case 'StateDocs':
        setUploadContainerName(stateContainer);
        break;
      case 'TaxDocs':
        setUploadContainerName(taxContainer);
        break;
      default:
        setUploadContainerName(generalContainer);
    }
  };

  return (
    <div style={{ width: '100%', height: '35vh', overflowY: 'auto', border: '5px solid #FF6D40', borderRadius: '10px', boxSizing: 'border-box', position: 'relative' }}>
    <div style={{ backgroundColor: '#FF6D40', color: 'white', textAlign: 'center', fontWeight: 'bold', padding: '10px 0' }}>
      Upload Documents
    </div>
    <div style={{ width: '100%',  overflowY: 'auto'  }}>
      <Form className='uploadBox'>
        <Form.Group className='uploadGroup' controlId="uploadFormFile">
          <Form.Label>File (single or zip of <b>same filetype</b> only)</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} required />
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormCategory">
          <Form.Label>Category (Required)</Form.Label>
          <Form.Control as="select" value={uploadCategory} onChange={(e) => setUploadCategory(e.target.value)} required>
            <option value="">Select a category</option>
            <option value="General">General</option>
            <option value="Property">Property</option>
            <option value="Sale">Sale</option>
            <option value="SB156">SB156</option>
            <option value="SRI">SRI</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormExpirationDate">
          <Form.Label>Expiration Date (Required)</Form.Label>
          <DatePicker selected={uploadExpirationDate} onChange={date => setUploadExpirationDate(date)} dateFormat="yyyy-MM-dd" className="form-control" required />
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormState">
          <Form.Label>State</Form.Label>
          <Form.Control as="select" value={uploadState} onChange={(e) => setUploadState(e.target.value)} required>
            <option value="">Select State</option>
            {US_STATES.map(state => (
              <option key={state.code} value={state.code}>{state.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormCounty">
          <Form.Label>County</Form.Label>
          <Form.Control as="select" value={uploadCounty} onChange={(e) => setUploadCounty(e.target.value)} required>
            <option value="">Select County</option>
            {COUNTIES[uploadState]?.map(county => (
              <option key={county.code} value={county.code}>{county.name}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormSaleDate">
          <Form.Label>Sale Date</Form.Label>
          <DatePicker selected={uploadSaleDate} onChange={date => setUploadSaleDate(date)} dateFormat="yyyy-MM-dd" className="form-control" />
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormPropertyIds">
          <Form.Label>Property IDs</Form.Label>
          <Form.Control as="textarea" placeholder="Enter property IDs, comma-separated" value={Array.isArray(uploadPropertyIds) ? uploadPropertyIds.join(', ') : ''} onChange={(e) => setUploadPropertyIds(e.target.value.split(',').map(id => id.trim()))} />
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormSaleType">
          <Form.Label>Sale Type</Form.Label>
          <Form.Control as="select" value={uploadSaleType} onChange={(e) => setUploadSaleType(e.target.value)} required>
            <option value="">Select Sale Type</option>
            <option value="Foreclosure Sale">Foreclosure Sale</option>
            <option value="Personal Property Sale">Personal Property Sale</option>
            <option value="Tax Sale">Tax Sale</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormSaleId">
          <Form.Label>Sale ID</Form.Label>
          <Form.Control type="text" placeholder="Enter sale ID" value={uploadSaleId} onChange={(e) => setUploadSaleId(e.target.value)} />
        </Form.Group>
        <Form.Group className='uploadGroup' controlId="uploadFormDocType">
          <Form.Label>Doc Type (Required)</Form.Label>
          <Form.Control as="select" value={uploadDocType} onChange={handleDocTypeChange} required>  
            <option value="">Select a document type</option>
            <option value="CountyDocs">County Documents</option>
            <option value="GenDocs">General Documents</option>
            <option value="Image">Image</option>
            <option value="PropDocs">Property Documents</option>
            <option value="SaleDocs">Sale Documents</option>
            <option value="sb156">SB156</option>
            <option value="SRIDocs">SRI Documents</option>
            <option value="StateDocs">State Documents</option>
            <option value="TaxDocs">Tax Documents</option>
          </Form.Control>
        </Form.Group>
      </Form>
    </div>
    <Button variant="primary" onClick={handleUpload} disabled={!uploadDocType}>Upload</Button>


    <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{
      position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999
    }}>
      <Toast.Header>
        <strong className="me-auto">File Upload Status</strong>
      </Toast.Header>
      <Toast.Body>{toastMessage}</Toast.Body>
    </Toast>
  </div>
);
}

export default UploadDocumentComponent;
