// src/redux/filterSlice.js

import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    selectedState: '',
    selectedCounty: '',
    selectedSaleType: '',
    selectedTimeFrame: 'All Future Sale Dates',
  },
  reducers: {
    setSelectedState(state, action) {
      state.selectedState = action.payload;
    },
    setSelectedCounty(state, action) {
      state.selectedCounty = action.payload;
    },
    setSelectedSaleType(state, action) {
      state.selectedSaleType = action.payload;
    },
    setSelectedTimeFrame(state, action) {
      state.selectedTimeFrame = action.payload;
    },
    resetFilters(state) {
      state.selectedState = '';
      state.selectedCounty = '';
      state.selectedSaleType = '';
      state.selectedTimeFrame = 'All Future Sale Dates';
    },
  },
});

export const {
  setSelectedState,
  setSelectedCounty,
  setSelectedSaleType,
  setSelectedTimeFrame,
  resetFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
