import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import DocumentTable from './DocumentTable';
import EditDocumentModal from './EditDocumentModal';
import MoveDocumentModal from './MoveDocumentModal';
import UploadDocumentModal from './UploadDocumentModal'; 
import LoadingAnimation from './LoadingAnimation';

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;
const API_KEY = process.env.REACT_APP_SERVER_API_KEY;

// Environment variable containers
const containers = [
    process.env.REACT_APP_COUNTY_CONTAINER,
    process.env.REACT_APP_GENERAL_CONTAINER,
    process.env.REACT_APP_IMAGE_CONTAINER,
    process.env.REACT_APP_SALE_CONTAINER,
    process.env.REACT_APP_SB156_CONTAINER,
    process.env.REACT_APP_SRI_CONTAINER,
    process.env.REACT_APP_STATE_CONTAINER,
    process.env.REACT_APP_TAX_CONTAINER,
    process.env.REACT_APP_PERSONAL_CONTAINER
];

const DocumentManager = () => {
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sortKey, setSortKey] = useState('name'); // Default sort by name

    useEffect(() => {
        fetchAllDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAllDocuments = async () => {
        setIsLoading(true);
        let allDocuments = [];

        // Fetch from each container
        for (const container of containers) {
            try {
                const response = await axios.get(
                  `${API_BASE_URL}/blob/container/${container}/blobs`,
                  { headers: { 'x-api-key': API_KEY } }
                );
                const docsInContainer = response.data.map(doc => ({
                  ...doc,
                  containerName: container
                }));
                allDocuments = allDocuments.concat(docsInContainer);
            } catch (err) {
                console.error(`Failed to fetch documents from ${container}`, err);
                setError(`Failed to fetch documents from ${container}.`);
            }
        }

        // Sort them by whatever sortKey is set
        setDocuments(sortDocuments(allDocuments, sortKey));
        setIsLoading(false);
    };

    const handleEdit = (document) => {
        setSelectedDocument(document);
        setShowEditModal(true);
    };

    const handleMove = (document) => {
        setSelectedDocument(document);
        setShowMoveModal(true);
    };

    const handleDelete = async (document) => {
        setIsLoading(true);
        try {
            await axios.delete(
              `${API_BASE_URL}/container/${document.containerName}/blob/${document.name}`,
              { headers: { 'x-api-key': API_KEY } }
            );
            // Refresh after delete
            fetchAllDocuments();
        } catch (err) {
            setError('Failed to delete document.');
            console.error(err);
        }
        setIsLoading(false);
    };

    const sortDocuments = (docs, key) => {
        return docs.sort((a, b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        });
    };

    const handleSortChange = (key) => {
        setSortKey(key);
        setDocuments(sortDocuments([...documents], key));
    };

    return (
        <div style={{ width: '100%', border: '5px solid #FF6D40', borderRadius: '10px', boxSizing: 'border-box', position: 'relative' }}>
            {isLoading && <LoadingAnimation />}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#FF6D40',
                color: 'white',
                textAlign: 'center',
                fontWeight: 'bold',
                padding: '10px 0'
            }}>
                <center><div>Document Manager</div></center>
                <div>
                    <Button onClick={() => setShowUploadModal(true)}>
                        Upload Document
                    </Button>
                </div>
            </div>

            <div style={{ padding: '10px', width: '100%' }}>
                {error && <Alert variant="danger">{error}</Alert>}

                {/* The main table listing */}
                <DocumentTable 
                    documents={documents}
                    onEdit={handleEdit}
                    onMove={handleMove}
                    onDelete={handleDelete}
                />

                {/* Edit Modal */}
                <EditDocumentModal 
                    show={showEditModal}
                    onHide={() => {
                        setShowEditModal(false);
                        setSelectedDocument(null);
                    }}
                    document={selectedDocument}
                    onSave={fetchAllDocuments}
                />

                {/* Move Modal */}
                <MoveDocumentModal
                    show={showMoveModal}
                    onHide={() => setShowMoveModal(false)}
                    document={selectedDocument}
                    onSave={fetchAllDocuments}
                    containers={containers}
                />

                {/* Upload Modal (the critical piece) */}
                <UploadDocumentModal
                    show={showUploadModal}
                    onHide={() => setShowUploadModal(false)}
                    onUploadSuccess={fetchAllDocuments} 
                />
            </div>
        </div>
    );
};

export default DocumentManager;
