import React from 'react';
import './SuccessModal.css';

const SuccessModal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }



  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h4 className="modal-title">Success</h4>
          <button type="button" className="close" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <p>Updated successfully!</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;